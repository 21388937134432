import React from 'react';
import { Tooltip } from 'antd'
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import ThemeXModal from 'Src/common/components/themeXModal';
import RegistrationTicketSection from 'Src/alumniEvents/alumni/common/registrationItems/registrationTicketSection';
import RegistrationCartSection from 'Src/alumniEvents/alumni/common/registrationItems/registrationCartSection';
import RegistrationGiftSection from 'Src/alumniEvents/alumni/common/registrationItems/registrationGiftSection';

import './ticketModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faPen, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

function TicketModal({
  closeModal, mandatoryTickets, otherTickets, guestCount, currencyEntity, getEditRegistrationUrl, registrationData,
}) {
  return (
    <ThemeXModal
      title={(
        <p className="arc-H200 arc-color-black">
          <FontAwesomeIcon icon={faTicketAlt} />
          <span className="ml8 arc-text-capitalize">
            <I18nCustomFormatter id="event-ticket-guest-kit-modal-title" />
          </span>
        </p>
      )}
      visible
      handleCancel={closeModal}
      wrapClassName="tickets-guest-kit-modal"
    >
      <If condition={!registrationData.is_receipt_sent && window.isEventsProEnabled}>
        <p className="tickets-guest-kit-modal-sub-title">
          <I18nCustomFormatter id="confirmation-section-tickets-sub-title" />
        </p>
      </If>
      <RegistrationTicketSection
        mandatoryTickets={mandatoryTickets}
        otherTickets={otherTickets}
        guestCount={guestCount}
        currencyEntity={currencyEntity}
        showTaxReceipt={registrationData.is_receipt_sent}
      />
      <RegistrationGiftSection
        gift={registrationData.gift}
        currencyEntity={currencyEntity}
      />
      <RegistrationCartSection
        totalBenefits={registrationData.is_receipt_sent && registrationData.total_benefit_amount}
        currencyEntity={currencyEntity}
        cartSubTotal={registrationData.total_amount}
        discountValue={registrationData.total_amount - registrationData.amount_after_discount}
        paidAmount={registrationData.paid_amount}
        payableAmount={registrationData.payable_amount}
        isRefundAlertHidden
      />
      <Choose>
        <When condition={registrationData.is_receipt_sent}>
          <div className="arc-color-primary arc-p mt8 arc-d-inline-block edit-registration-link cs-dull">
            <FontAwesomeIcon icon={faPen} />
            <span className="ml8">
              <I18nCustomFormatter id="event-edit-registration-label" />
            </span>
            <Tooltip title={`Your registration cannot be modified since the tax receipt for this event has already been issued. Contact ${collegeEmail} for help.`}>
              <FontAwesomeIcon className="ml8" icon={faInfoCircle} />
            </Tooltip>
          </div>
        </When>
        <Otherwise>
          <a href={getEditRegistrationUrl()} target="_blank" className="arc-color-primary arc-p mt8 arc-d-inline-block edit-registration-link">
            <FontAwesomeIcon icon={faPen} />
            <span className="ml8">
              <I18nCustomFormatter id="event-edit-registration-label" />
            </span>
          </a>
        </Otherwise>
      </Choose>
    </ThemeXModal>
  );
}

export default TicketModal;
