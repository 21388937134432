import React, { Fragment } from 'react';
import {
  Alert, Row, Col, Card,
} from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { isAllowedToViewContent } from 'Src/alumniEvents/alumni/common/utils/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './announcements.scss';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons';

function Announcements({ announcements, registration }) {
  /*
        Announcements has two privacy settings
        1) Registered Guests Only : All the registrations with rsvp status as Yes will be able to see it
        2) Approved Guests Only : A;; the registrations with rsvp status as Yes and is_registration_approved will be able to see it
    */

  return (
    <Fragment>
      <Row id="announcements-wrapper">
        <Col span={24}>
          <Card
            bordered={false}
            id="announcements"
            className="announcements-card arc-card-box-shadow arc-card-border-radius fr-view"
            title={(
              <p className="arc-H200">
                <FontAwesomeIcon icon={faBullhorn} />
                <span className="ml8">
                  <I18nCustomFormatter id="annnoncements-label" />
                </span>
              </p>
)}
            headStyle={{
              padding: '0 24px',
            }}
          >
            {
                            announcements.map((announcement) => (
                              <Row key={announcement.id} className="announcement-row">
                                <Col span={24}>
                                  <Row>
                                    <Col span={24}>
                                      <div className="announcement-name arc-H200 arc-color-black">{announcement.name}</div>
                                    </Col>
                                    <Col span={24}>
                                      <Choose>
                                        <When condition={isAllowedToViewContent(announcement.privacy, registration)}>
                                          <div
                                            className="challenge-description arc-p arc-color-B55"
                                            dangerouslySetInnerHTML={{
                                              __html: announcement.description,
                                            }}
                                          />
                                        </When>
                                        <Otherwise>
                                          <Alert
                                            message={<p className="arc-support arc-error-color arc-font-weight-bold"><I18nCustomFormatter id="awaiting-approval-label" /></p>}
                                            description={<p className="arc-support arc-error-color"><I18nCustomFormatter id="awaiting-approval-note" /></p>}
                                            type="error"
                                            className="arc-error-b arc-error-bg arc-error-alert-br"
                                          />
                                        </Otherwise>
                                      </Choose>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))
                        }
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export default Announcements;
