import React, { Component } from 'react';
import { func, string, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Radio, Input, Button, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import ModalHeader from '../ModalHeader';
import { validatePositiveNumber, readableNumber } from '../../../../common/utilities/data_util';

import './style.scss';

const FormItem = Form.Item;

class GiftDonationModal extends Component {
  constructor(props) {
    super(props);
    const gift = props.registrationData.gift || {};
    const splits = gift.splits || [];
    this.state = {
      selectedFund: splits.filter((item) => item.fund.id == props.eventFund.fund.id)[0] || null,
      quickSelectOptions: props.eventFund.quick_select_options.split(','),
      initialSlabAmount: null,
      initialAmount: null,
      isDonated: false,
    };
  }

  componentDidMount() {
    let initialSlabAmount = this.getInitialFundAmount();
    const initialAmount = this.getInitialFundAmount(false);
    const isDonated = initialAmount || initialSlabAmount;
    if (!initialAmount && !initialSlabAmount) {
      initialSlabAmount = this.getDefaultSlab();
    }
    this.setState({
      initialSlabAmount,
      initialAmount,
      isDonated,
    });
  }

  getInitialFundAmount(slab = true) {
    const { selectedFund, quickSelectOptions } = this.state;
    if (selectedFund) {
      const index = quickSelectOptions.indexOf(parseFloat(selectedFund.amount).toString());
      if (index > -1) {
        if (slab) {
          return quickSelectOptions[index];
        }
      } else {
        if (!slab) {
          return selectedFund.amount;
        }
      }
    }
  }

  getDefaultSlab() {
    const { quickSelectOptions } = this.state;
    const defaultSlab = this.props.eventFund.default_selected_amount;
    if (defaultSlab) {
      const index = quickSelectOptions.indexOf(parseFloat(defaultSlab).toString());
      if (index > -1) {
        return quickSelectOptions[index];
      }
    }
  }

  handleSlabAmountChange(amount) {
    this.setState({
      initialAmount: null,
      initialSlabAmount: null,
    });
    this.props.form.resetFields('amount');
  }

  handleAmountChange(amount) {
    this.setState({
      initialAmount: null,
      initialSlabAmount: null,
    });
    this.props.form.resetFields('slab_amount');
  }

  handleUpdate() {
    const { getFieldValue } = this.props.form;
    const amount = getFieldValue('amount') || getFieldValue('slab_amount');
    const minimumDonationAmount = parseFloat(this.props.eventFund.minimum_donation_amount);
    if (parseFloat(amount) < minimumDonationAmount) {
      message.error(`Amount should be greater than ${minimumDonationAmount}`);
    } else {
      this.props.handleAddGift(amount, this.props.eventFund);
    }
  }

  render() {
    const { form, eventFund, currencyEntity } = this.props;
    const { getFieldDecorator } = form;
    const { quickSelectOptions } = this.state;

    return (
      <Modal
        visible={true}
        closable={false}
        maskClosable={true}
        footer={null}
        wrapClassName="ticket-gift-modal"
        width="24rem"
        onCancel={() => this.props.handleCancel()}>
        <div className="gift-modal-body">
          <ModalHeader ticket={eventFund} handleCancel={() => this.props.handleCancel()} />
          <div className="gift-ticket-container" role="group" aria-label="How much would you like to give?">
            <p className="arc-p">How much would you like to give?</p>
            <div className="regular-form">
              <FormItem colon={false}>
                {getFieldDecorator('slab_amount', {
                  initialValue: this.state.initialSlabAmount,
                })(
                  <Radio.Group onChange={(e) => this.handleSlabAmountChange(e.target.value)}>
                    {quickSelectOptions.map((option, index) => {
                      return (
                        <Radio.Button value={option} key={index}>
                          <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                          <span>{readableNumber(option)}</span>
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>,
                )}
              </FormItem>
              <FormItem colon={false}>
                {getFieldDecorator('amount', {
                  initialValue: this.state.initialAmount,
                  rules: [
                    {
                      validator: validatePositiveNumber,
                    },
                  ],
                })(
                  <Input
                    size="large"
                    type="number"
                    aria-label="Enter custom amount"
                    placeholder="or enter custom amount"
                    onChange={(value) => this.handleAmountChange(value)}
                  />,
                )}
              </FormItem>
            </div>
            <If condition={this.state.isDonated}>
              <Button
                type="link"
                className="remove-gift-block link-btn arc-focus-outline"
                onClick={() => this.props.handleGiftRemove(eventFund.fund.id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="mr8" />
                <span>Remove gift</span>
              </Button>
            </If>
          </div>
        </div>
        <div>
          <Button
            size="large"
            type="primary"
            className="arc-modal-footer-full-width-btn"
            onClick={() => this.handleUpdate()}>
            Update Cart
          </Button>
        </div>
      </Modal>
    );
  }
}

GiftDonationModal.propTypes = {
  currencyEntity: string.isRequired,
  eventFund: shape(),
  handleCancel: func.isRequired,
  handleAddGift: func.isRequired,
  handleGiftRemove: func.isRequired,
};

GiftDonationModal.defaultProps = {
  eventFund: {},
};

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
});

export default connect(mapStateToProps, null)(Form.create()(GiftDonationModal));
