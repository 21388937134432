import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Radio, Input, message, Button } from 'antd';
import { connect } from 'react-redux';
import { number, func, string } from 'prop-types';
import { isEmpty } from 'lodash';
// === ACTIONS === //
import { readableNumber } from 'Src/common/utilities/data_util';
import pluralize from 'pluralize';
import { validateDiscountCode } from '../../../actions';

import ThemeXModal from '../../../../common/components/themeXModal';
import './style.scss';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class DiscountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      privateDiscount: {},
    };
  }

  handleDiscountSelection() {
    this.props.form.validateFields((err, values) => {
      if (!this.state.processing) {
        this.props.getDiscount(values.discount_code, this.state.privateDiscount);
      }
    });
  }

  getDiscountInfo(discount) {
    if (isEmpty(discount)) {
      return;
    }
    const discountOn = discount.tickets.length ? 'certain tickets' : 'total cart value';
    const { currencyEntity } = this.props;
    return (
      <span>
        <If condition={discount.discount_type === 'fixed_value'}>
          <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
        </If>
        <span>{readableNumber(discount.value)}</span>
        <If condition={discount.discount_type === 'percentage_value'}>
          <span>%</span>
        </If>
        <span>
          &nbsp;off on&nbsp;
          {discountOn}
        </span>
        {discount.max_discount && (
          <span>
            <span>&nbsp;up to </span>
            <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
            <span>{discount.max_discount}</span>
          </span>
        )}
      </span>
    );
  }

  /**
   * Method to check is discount is eligible or not
   * @param {string} discount
   * If discount has membership, user must need to have that membership to use code
   * If discount has affiliations, , user must need to have that affiliation to use code
   * If discount has tickets, , user must need to buy that tickets to use code
   * If discount has min_cart_value, , user must need to have cart value more than that
   */
  isDiscountEligible(discount) {
    let isEligible = true;
    const user = this.props.contextData.userData;
    const { registrationData } = this.props;
    if (discount.min_cart_value) {
      isEligible = this.props.cartSubTotal >= discount.min_cart_value;
    }
    if (discount.mapped_memberships.length) {
      isEligible =
        (isEligible &&
          user &&
          user.membership &&
          discount.mapped_memberships.findIndex((m) => m.name === user.membership) > -1) ||
        false;
    }
    if (discount.mapped_affiliations.length) {
      let affiliations = registrationData?.affiliations;

      if (isEmpty(affiliations)) {
        const guest = registrationData?.guests?.find((g) => g.is_primary);
        affiliations = guest?.affiliations;
      }

      isEligible =
        (isEligible &&
          !isEmpty(
            affiliations?.filter(
              (item1) => discount.mapped_affiliations.findIndex((item2) => item1.id == item2.id) >= 0,
            ),
          )) ||
        false;
    }
    if (discount.tickets.length) {
      isEligible =
        isEligible &&
        discount.tickets.some((d) => registrationData.tickets.findIndex((t) => t.ticket.id === d.id) > -1);
    }
    return isEligible;
  }

  validateDiscount() {
    const code = document.getElementById('private_discount_code').value;
    if (!this.state.processing) {
      if (code) {
        this.setState({ processing: true });
        validateDiscountCode(this.props.eventData.id, code)
          .then((response) => {
            response = response.data;
            let discount = {};
            if (response.is_valid && this.isDiscountEligible(response.data)) {
              discount = response.data;
            } else {
              message.error('Invalid discount code');
            }
            this.setState({
              privateDiscount: discount,
              processing: false,
            });
          })
          .catch((error) => {
            this.setState({ processing: false });
          });
      } else {
        this.setState({ privateDiscount: {}, processing: false });
      }
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { privateDiscount } = this.state;
    const { discounts } = this.props.eventData;
    const { appliedCode, currencyEntity } = this.props;

    return (
      <ThemeXModal
        modalTitle="Discount"
        visible
        wrapClassName="discount-modal"
        handleCancel={() => this.props.handleClose()}
        Footer={
          <Button
            size="large"
            type="primary"
            className="arc-modal-footer-full-width-btn arc-focus-outline"
            onClick={() => this.handleDiscountSelection()}>
            Apply Discount
          </Button>
        }>
        <If condition={!isEmpty(registrationData.discount_code)}>
          <div className="edit-registration-action-modal-top-alert m16">
            <Alert
              message={`The '${window.registrationData.discount_code.code}' has been applied on your previous transaction.Changing to another discount will remove any previous discount applied.`}
              type="warning"
              showIcon
            />
          </div>
        </If>
        <FormItem colon={false}>
          {getFieldDecorator('discount_code', {
            initialValue: !discounts.length ? 'custom_user_input' : appliedCode,
          })(
            <RadioGroup>
              {discounts.map((discount, index) => {
                if (!discount.is_active || !discount.is_public) {
                  return;
                }
                return (
                  <Radio
                    value={discount.code}
                    key={index}
                    disabled={!this.isDiscountEligible(discount)}
                    aria-label="Discount code">
                    <span>
                      <span className="discount-code-title arc-H150">{discount.code}</span>
                      <span className="discount-code-info-block">
                        <span className="arc-p">{this.getDiscountInfo(discount)}</span>
                        <If condition={discount.tickets.length}>
                          <span>&nbsp; ({discount.tickets.map((ticket) => ticket.name).join(', ')})</span>
                        </If>
                        <If condition={discount.min_cart_value}>
                          <span className="discount-eligibility-text">
                            Requires cart value greater than or equal to&nbsp;
                            <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                            {discount.min_cart_value}
                          </span>
                        </If>
                      </span>
                      <If condition={discount.mapped_memberships.length}>
                        <span className="discount-eligibility-text">
                          {`Requires ${discount.mapped_memberships.map((membership) => membership.name).join(' or ')}`}
                        </span>
                      </If>
                      <If condition={discount.mapped_affiliations.length}>
                        <span className="discount-eligibility-text">
                          {`Discount is valid only for those with the ${pluralize(
                            'affiliation',
                            discount.mapped_affiliations.length,
                          )} ${discount.mapped_affiliations.map((affiliation) => `'${affiliation.name}'`).join(', ')}`}
                        </span>
                      </If>
                    </span>
                  </Radio>
                );
              })}
              <Radio value="custom_user_input" aria-label="Custom discount code">
                <div id="discount-input-wrapper">
                  <span className="discount-code-title arc-H150">Add code</span>
                  <Input
                    id="private_discount_code"
                    aria-label="Discount code"
                    addonAfter={
                      this.state.processing ? (
                        <LoadingOutlined />
                      ) : (
                        <Button
                          type="link"
                          className="link-btn arc-focus-outline"
                          onClick={() => this.validateDiscount()}>
                          Apply
                        </Button>
                      )
                    }
                    placeholder="Enter discount code"
                    onPressEnter={() => this.validateDiscount()}
                  />
                  <If condition={!isEmpty(privateDiscount)}>
                    <p className="custom-discount-text">
                      <span>Code applied: </span>
                      {this.getDiscountInfo(privateDiscount)}
                    </p>
                  </If>
                </div>
              </Radio>
            </RadioGroup>,
          )}
        </FormItem>
      </ThemeXModal>
    );
  }
}

DiscountModal.propTypes = {
  cartSubTotal: number.isRequired,
  getDiscount: func.isRequired,
  currencyEntity: string.isRequired,
};

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
});

export default connect(mapStateToProps, null)(Form.create()(DiscountModal));
