import React from 'react'
import { Button, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import { isEmpty } from 'lodash'
import './style.scss'

export const ConfirmModal = ({ handleCancel, handleConfirm, dependentTickets, ticket }) => {
  return (
    <Modal
      visible
      closable={false}
      maskClosable
      footer={null}
      wrapClassName="confirm-modal"
      width="24rem"
      onCancel={handleCancel}
    >
      <div className='confirm-modal-header arc-H200'>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        <div>Remove associated tickets?</div>
      </div>
      <div className='confirm-modal-description arc-color-B55'>
        Removing {ticket.name} from your registration will remove the following tickets too.
        <If condition={!isEmpty(dependentTickets)}>
          <div class="confirm-modal-description-item-wrapper">
            {
              dependentTickets.map(data => <div className='confirm-modal-description-item'>
                {`${data.name} x ${data.quantity}`}
              </div>)
            }
          </div>
        </If>
      </div>
      <div className='confirm-modal-action-button'>
        <Button className='arc-btn-subtle' onClick={handleCancel}>Cancel</Button>
        <Button type='primary' onClick={handleConfirm}>Confirm</Button>
      </div>
    </Modal>
  )
}
