/* eslint-disable */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Card, Row, Col, Avatar, Divider, Button } from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import { getKeyValue } from 'Src/common/utilities/data_util';
import { getFormattedDateWithTimeZone, isNowBeforeDate } from 'Src/common/utilities/moment_util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleQuestion,
  faCircleXmark,
  faTriangleExclamation,
  faCircleCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { faBell, faTimesCircle, faUsers, faCreditCard, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';

import RemindToRegisterModal from 'Src/common/components/RemindToRegisterModal';
import { INVITE_ONLY_EVENT } from 'Src/adminEvents/constants';
import { REGISTRATION_STATUSES } from '../../../constants';

import EventDateBlock from '../../common/eventDateBlock';

import './style.scss';

const ButtonGroup = Button.Group;

function DetailBar(props) {
  const { eventData, registrationData, contextData } = props;
  const { rsvpStatuses, registrationStatuses, isAdminEditingGuest, isAdminAddingGuest } = contextData;
  const { labels } = eventData;

  const registrationRsvpStatusDisplay = registrationData.rsvp_status_display;
  const isRsvpYes = registrationData.rsvp_status_display === rsvpStatuses.yes;
  const registrationRsvpStatus = registrationData.rsvp_status;
  const registrationStatus = registrationData.registration_status_display;
  const isRegistrationOpen = eventData.is_registration_open;
  const isGuestLimitExceeded = eventData.is_guest_limit_exceeded;
  const isEventEnded = eventData.is_past;
  const shouldShowReminderButton =
    getKeyValue(registrationData, 'registration_status') !== REGISTRATION_STATUSES.STATUS_SUCCESSFUL &&
    isNowBeforeDate(eventData.start_datetime, 'hour');
  const guestCount = eventData.count_guests;
  const nonAnonymousGuestCount = eventData.attending_guest_profiles.filter(
    (profile) => profile.should_show_on_attendee_list,
  ).length;
  const calendarDateTimeFormat = 'ddd, MMM D, YYYY hh:mm a';
  const [calendarModalVisible, setCalendarModalVisible] = useState(false);
  const calendarStartDateTime = getFormattedDateWithTimeZone(
    eventData.start_datetime,
    eventData.timezone,
    calendarDateTimeFormat,
  );
  const calendarEndDateTime = getFormattedDateWithTimeZone(
    eventData.end_datetime,
    eventData.timezone,
    `${calendarDateTimeFormat} z`,
  );
  // props for the remindToRegister modal.
  const reminderData = {
    name: eventData.name,
    title: 'When would you like to be reminded?',
    helpText: 'You will still need to register for the event to confirm that you are attending.',
    remindBefore: eventData.start_datetime,
    text: 'If you’re not prepared to register now, you can set a reminder so that you don’t forget to register as soon as you’re ready.\n\n Some events have limited seats, so be sure to register soon!',
  };
  const calendarData = {
    title: `Reminder to register: ${eventData.name}`,
    description: `${eventData.name} is happening at ${calendarStartDateTime} - ${calendarEndDateTime}.\n\nIf you haven’t registered yet, please register now at ${window.location.href}.\n\n Some events have limited seats, so be sure to register soon!`,
  };
  const showInvitationRequired =
    eventData.type === INVITE_ONLY_EVENT &&
    !guestData &&
    !window.location.href.includes('edit_registration=true') &&
    registrationRsvpStatus !== 'yes';

  function handleButtonMenuClick(e) {
    props.handleRegisterButton(e.key);
  }

  function handleModalClose() {
    setCalendarModalVisible(false);
  }

  const getDisableState = () => {
    if (isEventEnded && !isAdminEditingGuest) {
      return true;
    }
    if (registrationData.is_receipt_sent) {
      return !isSiteAdmin;
    }
    if (registrationData && registrationData.rsvp_status_display) {
      return false;
    }
    return (!isRegistrationOpen && !isAdminAddingGuest && !isAdminEditingGuest) || isGuestLimitExceeded;
  };

  const getDisabledStateForBtn = () => {
    if (isEventEnded && !isAdminAddingGuest) {
      return true;
    }
    if (registrationData.is_receipt_sent) {
      return !isSiteAdmin;
    }
    if (registrationData && registrationData.rsvp_status_display) {
      return false;
    }
    return (!isRegistrationOpen && !isAdminAddingGuest && !isAdminEditingGuest) || isGuestLimitExceeded;
  };

  const shouldShowNotAttendingBtn =
    (!registrationData || !isRsvpYes) &&
    isRegistrationOpen &&
    !eventData.external_registration_url &&
    !getDisableState() &&
    registrationRsvpStatusDisplay !== rsvpStatuses.no &&
    registrationRsvpStatusDisplay !== rsvpStatuses.maybe;

  const selectedLanguage = 'en';

  const shouldShowRegistrationBtn = !!registrationData && !getDisableState();
  const registerButton = (
    <Button
      type="primary"
      loading={props.buttonLoading}
      disabled={getDisabledStateForBtn()}
      id="register-proceed-btn"
      className="arc-focus-outline"
      onClick={() => {
        if (!registrationRsvpStatusDisplay) props.handleRegisterButton('yes');
      }}>
      <Choose>
        <When condition={isEventEnded}>
          <span>{labels[selectedLanguage]['register-btn-label-after-event']}</span>
        </When>
        <When
          condition={
            registrationRsvpStatusDisplay === rsvpStatuses.no &&
            registrationStatus == registrationStatuses.registration_successful
          }>
          <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#CF1322' }} />
          <I18nCustomFormatter id="event-rsvp-no" />
        </When>
        <When
          condition={
            registrationRsvpStatusDisplay === rsvpStatuses.no &&
            registrationStatus == registrationStatuses.registration_cancelled
          }>
          <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#CF1322' }} />
          <I18nCustomFormatter id="event-registration-cancelled" />
        </When>
        <When
          condition={
            registrationStatus == registrationStatuses.payment_initiated ||
            registrationStatus == registrationStatuses.pending_payment
          }>
          <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#FA8C16' }} />
          <I18nCustomFormatter id="event-pending-payment" />
        </When>
        <When condition={registrationStatus == registrationStatuses.payment_failed}>
          <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#FA8C16' }} />
          <I18nCustomFormatter id="event-payment-failed" />
        </When>
        <When condition={registrationRsvpStatusDisplay === rsvpStatuses.maybe}>
          <FontAwesomeIcon icon={faCircleQuestion} style={{ color: '#FA8C16' }} />
          <I18nCustomFormatter id="event-rsvp-maybe" />
        </When>
        <When
          condition={
            registrationRsvpStatusDisplay === rsvpStatuses.yes &&
            registrationStatus == registrationStatuses.registration_successful
          }>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#36B37E' }} />
          <I18nCustomFormatter id="event-edit-registration" />
        </When>
        <When condition={isGuestLimitExceeded}>
          <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#FA8C16' }} />
          <I18nCustomFormatter id="event-registration-full" />
        </When>
        <When condition={!isRegistrationOpen}>
          <span>{labels[selectedLanguage]['register-btn-label-before-event']}</span>
        </When>
        <Otherwise>
          <span>{labels[selectedLanguage]['register-btn-label-during-event']}</span>
        </Otherwise>
      </Choose>
    </Button>
  );

  const peopleAttendingEvent = (
    <Choose>
      <When condition={eventData.is_guest_count_shown_to_users && guestCount}>
        <div id="event-latest-attendee-block">
          <div id="event-latest-attendees">
            {eventData.attending_guest_profiles.map((attendee, index) => (
              <Avatar
                src={attendee.matched_profile && attendee.matched_profile.present_picture}
                icon={<UserOutlined aria-hidden="true" role="none" />}
                key={index}
                className="attendee-avatar"
              />
            ))}
          </div>
          <span className="arc-p attendee-count-wrapper">
            <Choose>
              <When condition={guestCount <= 2 && nonAnonymousGuestCount === guestCount}>
                <I18nCustomFormatter
                  id="event-guest-is-attending"
                  values={{
                    full_name: eventData.attending_guest_profiles[0].full_name,
                    second_full_name:
                      nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].full_name : null,
                    non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                  }}
                />
              </When>
              <When condition={nonAnonymousGuestCount === 0}>
                <I18nCustomFormatter
                  id="guest-counts-attending-numbers"
                  values={{
                    guests: guestCount,
                  }}
                />
              </When>
              <Otherwise>
                <Button
                  type="link"
                  className="guest-list-trigger link-btn arc-focus-outline mr4"
                  onClick={() => props.handleAttendingGuestClick()}>
                  <I18nCustomFormatter
                    id="event-guests-more-than-one-prefix"
                    values={{
                      full_name: eventData.attending_guest_profiles[0].first_name,
                      second_full_name:
                        nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].first_name : null,
                      non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                    }}
                  />
                  <span className="ml4"></span>
                  <I18nCustomFormatter
                    id="guest-counts-attending-link"
                    values={{
                      guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                    }}
                  />{' '}
                </Button>
                <I18nCustomFormatter
                  id="guest-counts-attending-text"
                  values={{
                    guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                  }}
                />
              </Otherwise>
            </Choose>
          </span>
        </div>
      </When>
      <Otherwise>
        <div id="event-latest-attendee-block">
          <span className="arc-p attendee-count-wrapper">
            <FontAwesomeIcon icon={faUsers} className="mr8" />
            <I18nCustomFormatter
              id="guest-counts-attending-numbers"
              values={{
                guests: guestCount,
              }}
            />
          </span>
        </div>
      </Otherwise>
    </Choose>
  );

  const editRegistrationStatus =
    registrationStatus === registrationStatuses.registration_successful &&
    registrationRsvpStatusDisplay === rsvpStatuses.yes;
  const updateRegistrationStatus =
    registrationStatus === registrationStatuses.registration_cancelled &&
    registrationRsvpStatusDisplay === rsvpStatuses.no;
  const registrationChangeStatus =
    registrationStatus === registrationStatuses.registration_successful &&
    (registrationRsvpStatusDisplay === rsvpStatuses.no || registrationRsvpStatusDisplay === rsvpStatuses.maybe);
  const payNowStatus =
    registrationStatus === registrationStatuses.payment_initiated ||
    registrationStatus === registrationStatuses.pending_payment ||
    registrationStatus === registrationStatuses.payment_failed;

  return (
    <div id="event-detail-bar">
      <If condition={!props.loading}>
        <Row type="flex" justify="space-between">
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <If condition={(props.heroBanner && props.heroBanner.title) || props.eventName}>
              <h1 className="event-detail-title">{(props.heroBanner && props.heroBanner.title) || props.eventName}</h1>
            </If>
            <Choose>
              <When condition={eventData.start_datetime || eventData.end_datetime}>
                <EventDateBlock
                  startDateTime={eventData.start_datetime}
                  endDateTime={eventData.end_datetime}
                  timezone={eventData.timezone}
                  shortTimezone={eventData.short_timezone}
                />
                <If condition={shouldShowReminderButton}>
                  <If condition={calendarModalVisible}>
                    <RemindToRegisterModal
                      visible={calendarModalVisible}
                      calendarData={calendarData}
                      reminderData={reminderData}
                      handleCancel={handleModalClose}
                    />
                  </If>
                </If>
              </When>
              <Otherwise>
                <span className="arc-p arc-color-B45" id="to-be-declared-text">
                  <I18nCustomFormatter id="to-be-declared-text" />
                </span>
              </Otherwise>
            </Choose>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            gutter={12}
            className="arc-text-align-r"
            id="event-detail-bar-action">
            <If condition={showInvitationRequired}>
              <div className="disabled-nudge arc-H250">Invitation required</div>
            </If>
            <If condition={!showInvitationRequired}>
              <div>
                <ButtonGroup
                  className={`${!isEventEnded && registrationRsvpStatus}-rsvp-status register-button-container`}>
                  {registerButton}
                </ButtonGroup>
              </div>
            </If>

            <If
              condition={
                (guestCount > 0 ||
                  (shouldShowReminderButton && !shouldShowRegistrationBtn) ||
                  shouldShowNotAttendingBtn ||
                  (shouldShowRegistrationBtn &&
                    (editRegistrationStatus ||
                      updateRegistrationStatus ||
                      payNowStatus ||
                      registrationChangeStatus))) &&
                !showInvitationRequired
              }>
              <Card className="register-actions-card">
                <If condition={!!guestCount}>
                  {peopleAttendingEvent}
                  <If
                    condition={
                      guestCount > 0 &&
                      (shouldShowReminderButton || shouldShowNotAttendingBtn || shouldShowRegistrationBtn)
                    }>
                    <Divider className="attendees-and-buttons-divider" />
                  </If>
                </If>
                <If condition={shouldShowReminderButton || shouldShowNotAttendingBtn || shouldShowRegistrationBtn}>
                  <div className="register-buttons">
                    <If condition={shouldShowReminderButton && !shouldShowRegistrationBtn}>
                      <Button
                        type="link"
                        className="arc-color-primary-imp set-a-reminder-button"
                        onClick={() => setCalendarModalVisible(true)}
                        icon={<FontAwesomeIcon icon={faBell} className="mr6" />}>
                        Set a reminder
                      </Button>
                    </If>
                    <If condition={shouldShowNotAttendingBtn}>
                      <Button
                        type="link"
                        className="arc-color-primary-imp not-attending-button arc-focus-outline"
                        onClick={() => props.handleRegisterButton('no')}
                        icon={<FontAwesomeIcon icon={faTimesCircle} className="mr6" />}>
                        I&apos;m not attending
                      </Button>
                    </If>
                    <If condition={shouldShowRegistrationBtn}>
                      <Choose>
                        <When condition={editRegistrationStatus}>
                          <Button
                            type="link"
                            className="arc-color-primary-imp pay-now-button"
                            onClick={() => props.handleRegisterButton('yes', false, true)}
                            icon={<FontAwesomeIcon icon={faPenToSquare} className="mr6" />}>
                            {' '}
                            Edit registration
                          </Button>
                        </When>
                        <When condition={updateRegistrationStatus}>
                          <Button
                            type="link"
                            className="arc-color-primary-imp pay-now-button"
                            onClick={() => props.handleRegisterButton('yes', false, true)}
                            icon={<FontAwesomeIcon icon={faPenToSquare} className="mr6" />}>
                            Update registration
                          </Button>
                        </When>
                        <When condition={registrationChangeStatus}>
                          <Button
                            type="link"
                            className="arc-color-primary-imp pay-now-button"
                            onClick={() => props.handleRegisterButton('yes', false, true)}
                            icon={<FontAwesomeIcon icon={faPenToSquare} className="mr6" />}>
                            {' '}
                            Change
                          </Button>
                        </When>
                        <When condition={payNowStatus}>
                          <Button
                            type="link"
                            className="arc-color-primary-imp pay-now-button"
                            onClick={() => props.handleRegisterButton('yes', false, true)}
                            icon={<FontAwesomeIcon icon={faCreditCard} className="mr6" />}>
                            {' '}
                            Pay now
                          </Button>
                        </When>
                      </Choose>
                    </If>
                  </div>
                </If>
              </Card>
            </If>
          </Col>
        </Row>
      </If>
    </div>
  );
}

DetailBar.propTypes = {
  loading: bool,
  buttonLoading: bool,
  handleRegisterButton: func.isRequired,
};

DetailBar.defaultProps = {
  loading: true,
  buttonLoading: false,
};

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
  eventData: state.eventDataReducer.data || {},
  contextData: state.contextDataReducer || {},
  eventName: (state.eventDataReducer.data && state.eventDataReducer.data.name) || '',
  heroBanner: state.heroBannerDataReducer.data || {},
});

export default connect(mapStateToProps, null)(DetailBar);
