import React, { useState, Fragment } from 'react';
import { object, func, bool, string, number } from 'prop-types';
import { Row, Col } from 'antd';

import instance from './../../../utilities/axios_util';
import { REACTIONS_POST_API_PATH } from './../../../endpoints';
import { GET_STREAM_REACTION_TYPES, GET_STREAM_REACTION_KIND } from './../../../constants';

import ReactionsList from './reactionsList';

import './style.scss';

let apiTime = null;
const animationDuration = 600;

function ActivityReactions({
    moduleName,
    objectId,
    activity,
    isLoggedIn,
    toggleFunc,
    hasWritePermission,
    handleNonLoggedIn,
    handleNoPermission
}) {

    const reactionCounts = activity.reaction_counts;
    const ownReactions = activity.own_reactions;
    const latestReactions = activity.latest_reactions;
    const latestLikeReactions = latestReactions ? latestReactions.like : [];
    const ownLikeReactions = ownReactions ? ownReactions.like : [];
    const isLike = !!ownLikeReactions && !!ownLikeReactions.length;
    const likeCount = reactionCounts.like || 0;
    const [processing, setProcessing] = useState(false);
    const [reactionListVisible, setReactionListVisible] = useState(false);

    function toggleLike() {
        if (isLoggedIn && hasWritePermission) {
            if (!processing) {
                const value = !isLike;
                const reactionForeignId = value ? null : ownLikeReactions[ownLikeReactions.length - 1].data.foreign_id;
                apiTime = new Date();
                setProcessing(true);
                postLike(value, reactionForeignId);
            }
        }
        else if(!isLoggedIn) {
            handleNonLoggedIn();
        }
        else {
            handleNoPermission();
        }
    }

    function postLike(isLike, reactionId = null) {
        const method = isLike ? 'post' : 'delete';
        let path = REACTIONS_POST_API_PATH.replace('{reaction_on}', GET_STREAM_REACTION_TYPES.POST);
        path = path.replace('{foreign_id}', activity.foreign_id);
        if (!isLike) {
            path = path.concat(reactionId, '/');
        }

        instance[method](path,
            { kind: GET_STREAM_REACTION_KIND.LIKE },
            { hideNotification: true }
        ).then(response => {
            const duration = new Date() - apiTime;
            if (isLike) {
                window.setTimeout(() => {
                    processLikeResponse(response)
                }, animationDuration - duration)
            }
            else {
                processLikeResponse(response)
            }
        }).catch(err => {
            const duration = new Date() - apiTime;
            window.setTimeout(() => {
                setProcessing(false);
            }, animationDuration - duration)
        })
    }

    function processLikeResponse(response) {
        setProcessing(false);
        toggleFunc(GET_STREAM_REACTION_KIND.LIKE, activity, response.data);
    }

    function getReactionCountString() {
        let countString = '';
        let count = likeCount;
        if (count > 0) {
            const otherUser = latestLikeReactions[0].user ? latestLikeReactions[0].user.data.first_name : 'Unknown';
            if (count === 1) {
                countString = `${isLike ? 'You like' : `${otherUser} likes`} this`;
            }
            else {
                if (isLike) {
                    count = count - 1;
                    const stringAdj = count === 1 ? 'other person likes' : 'others like';
                    if (count === 1) {
                        countString = `You and <span class="highlight">another person</span> likes this`
                    }
                    else {
                        countString = `You and <span class="highlight">${count} ${stringAdj}</span> this`
                    }
                }
                else {
                    count = count - 1;
                    const stringAdj = count === 1 ? 'other person likes' : 'others like';
                    countString = `${otherUser} and <span class="highlight">${count} ${stringAdj}</span> this`
                }
            }
        }
        return countString;
    }

    function handleReactionCountTrigger() {
        if (likeCount > 1) {
            if (isLoggedIn) {
                setReactionListVisible(true)
            }
            else {
                handleNonLoggedIn();
            }
        }
        else {
            return false;
        }
    }

    return (
        <Fragment>
            <Row
                className="feed-activity-reaction"
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="feed-activity-reactions-wrapper">
                    <span
                        className={`heart${isLike ? ' filled' : ''}${processing ? ' is-animating' : ''}`}
                        onClick={() => toggleLike()}
                        tabIndex="-1"
                        aria-label={`${isLike ? 'Dislike this post': 'Like this post'}`}
                        role="button"
                        style={{ backgroundImage: `url(${staticPath}img/react-modules/alma_like.png)` }}
                    />
                    <span
                        className="feed-activity-count"
                        onClick={() => handleReactionCountTrigger()}
                    ><span dangerouslySetInnerHTML={{ __html: getReactionCountString() }} /></span>
                </Col>
            </Row>
            <If condition={likeCount > 1}>
                <ReactionsList
                    visible={reactionListVisible}
                    doCloseModal={() => setReactionListVisible(false)}
                    moduleName={moduleName}
                    objectId={objectId}
                    activityId={activity.id}
                    totalLikes={likeCount}
                />
            </If>
        </Fragment>
    )
}

ActivityReactions.propTypes = {
    moduleName: string.isRequired,
    objectId: number.isRequired,
    activity: object.isRequired,
    toggleFunc: func.isRequired,
    isLoggedIn: bool.isRequired,
    hasWritePermission: bool.isRequired,
    handleNonLoggedIn: func.isRequired,
    handleNoPermission: func.isRequired,
}

export default ActivityReactions;