import React from 'react';
import { object, number, func, bool } from 'prop-types';
import { Row, Col, Avatar, Menu, Dropdown, message, Alert } from 'antd';
import { CommentItem } from 'react-activity-feed';

import instance from './../../../utilities/axios_util';
import { REACTIONS_POST_API_PATH, COMMENT_POST_API_PATH } from './../../../endpoints';
import { GET_STREAM_REACTION_TYPES, GET_STREAM_REACTION_KIND } from './../../../constants';

import './style.scss';

import { EllipsisOutlined } from '@ant-design/icons';

function ActivityCommentItem({
    comment,
    userId,
    activity,
    isLoggedIn,
    isAdmin,
    hasWritePermission,
    doReactionAddRequest,
    doReactionRemoveRequest
}) {
    const foreignId = activity.foreign_id;
    const { user, data } = comment;
    const userData = user && user.data || {};
    const hasUserFlagged = !!comment.children_counts.flag;

    const activityCommentActionMenu = (
        <Menu>
            <If condition={userId === userData.user_id || isAdmin}>
                <Menu.Item key="delete" onClick={() => deleteComment()}>
                    Delete
                </Menu.Item>
            </If>
            <If condition={!hasUserFlagged}>
                <Menu.Item key="flag" onClick={() => flagComment()}>
                    Report Abuse
                </Menu.Item>
            </If>
        </Menu>
    );

    function flagComment() {
        let path = REACTIONS_POST_API_PATH.replace('{reaction_on}', GET_STREAM_REACTION_TYPES.COMMENT);
        path = path.replace('{foreign_id}', data.foreign_id);

        instance.post(
            path,
            { kind: GET_STREAM_REACTION_KIND.FLAG },
            { hideNotification: true }
        ).then(response => {
            message.open({
                content: <Alert
                    message="Comment is flagged"
                    type="warning"
                    className="feed-message-alert"
                />,
                duration: 1
            });
            doReactionAddRequest(GET_STREAM_REACTION_KIND.FLAG, comment, response.data)
        })
    }

    function deleteComment() {
        let path = COMMENT_POST_API_PATH.replace('{module_name}', GET_STREAM_REACTION_TYPES.POST);
        path = path.replace('{object_id}', foreignId)
        path = path.concat(data.foreign_id, '/');
        instance.delete(path, { hideNotification: true }).then(response => {
            doReactionRemoveRequest('comment', activity, comment.id)
        })
    }

    return (
        <Row
            className={`feed-activity-comment-item`}
            type="flex"
            align="top"
        >
            <Col className="comment-item-avatar-wrapper">
                <Avatar src={userData.present_picture} />
            </Col>
            <Col className="feed-activity-flex-col">
                <Row
                    type="flex"
                    gutter={12}
                    align="top"
                    justify="space-between"
                >
                    <Col className="comment-item-detail-flex">
                        <a href={userData.profile_url} target="_blank" className="comment-item-profile-link">
                            <p className="comment-item-user arc-H150 arc-color-B65">{userData && userData.full_name}</p>
                        </a>
                        <CommentItem comment={comment} />
                        <If condition={(activity.highlighted_comment_ids || []).indexOf(comment.id) > -1}>
                            <span className="highlighted arc-support">Highlighted comment</span>
                        </If>
                    </Col>
                    <Col>
                        <If condition={isLoggedIn && hasWritePermission}>
                            <Dropdown overlay={activityCommentActionMenu} trigger={['click']}>
                                <EllipsisOutlined rotate={90} className="comment-more-actions"/>
                            </Dropdown>
                        </If>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

ActivityCommentItem.propTypes = {
    userId: number,
    activity: object.isRequired,
    comment: object.isRequired,
    isLoggedIn: bool.isRequired,
    isAdmin: bool.isRequired,
    hasWritePermission: bool.isRequired,
    doReactionAddRequest: func.isRequired,
    doReactionRemoveRequest: func.isRequired
}

export default ActivityCommentItem;