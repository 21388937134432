import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { initSentry } from 'Src/common/utilities/sentry_util';
import { isEmpty } from 'lodash';
import store from '../store';

import {
  CONFIRMATION_VIEW_URL, ALUMNI_BASE_URL, ITINERARY_VIEW_URL, GUEST_KIT_VIEW_URL, RECEIPT_VIEW_URL,
} from '../routes';

import ContextWrapper from '../contextWrapper';
import EventActionRoute from './eventActionRoute';
import Confirmation from '../alumni/confirmation';
import Itinerary from '../alumni/itinerary';
import GuestKit from '../alumni/guestKit';
import Receipt from '../alumni/receipt';

let i18Messages = require('Data/en-US/event.json');
const commonI18Messages = require('Data/en-US/common.json');

i18Messages = { ...commonI18Messages, ...i18Messages };

if (!isEmpty(window.i18nMetadata) && !isEmpty(window.i18nMetadata.common)) {
  i18Messages = { ...i18Messages, ...window.i18nMetadata.common };
}

if (!isEmpty(window.i18nMetadata) && !isEmpty(window.i18nMetadata.event)) {
  i18Messages = { ...i18Messages, ...window.i18nMetadata.event };
}

// This will init sentry and log errors for the
// This will only log errors for production environment
if (window.environment === 'prod') {
  initSentry();
}

function Root() {
  return (
    <IntlProvider locale="en" messages={i18Messages}>
      <Provider store={store}>
        <ContextWrapper>
          <Router>
            <Switch>
              <Route
                exact
                path={CONFIRMATION_VIEW_URL}
                component={Confirmation}
              />
              <Route
                exact
                path={RECEIPT_VIEW_URL}
                component={Receipt}
              />
              <Route
                exact
                path={GUEST_KIT_VIEW_URL}
                component={GuestKit}
              />
              <Route
                exact
                path={ITINERARY_VIEW_URL}
                component={Itinerary}
              />
              <Route
                path={ALUMNI_BASE_URL}
                component={EventActionRoute}
              />
            </Switch>
          </Router>
        </ContextWrapper>
      </Provider>
    </IntlProvider>
  );
}

export default Root;
