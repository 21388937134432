/* eslint-disable react/no-unescaped-entities */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compact, find, isEmpty, sortBy } from 'lodash';
import { CRITERIA_AFFILIATION, CRITERIA_CUSTOM, MANDATORY_TICKET } from 'Src/alumniEvents/constants';
import { getAgendaItems } from '../../../utils/app_moment_utils';
import AgendaItem from '../AgendaItem';
import { getBoughtTicketQuantity } from '../../../utils';
import './style.scss';
import { getFormattedDate } from '../../../../common/utilities/moment_util';

function AddOnsModal({
  selectedTickets,
  currencyEntity,
  eventData,
  handleCancel,
  registrationData,
  dependentTicketId,
  handleAgendaItemClick,
  handleTicketItemClick,
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [agendaItems, setAgendaItems] = useState([]);
  const [tickets, setTickets] = useState([]);

  function shouldShowAgenda(agenda) {
    const FORM_FIELD_TYPE_DROPDOWN = 6;
    const FORM_FIELD_TYPE_DROPDOWN_RANGE = 16;
    const FORM_FIELD_TYPE_LOCATION = 18;
    if (isEmpty(agenda.depends_on)) {
      return true;
    }
    if (isEmpty(agenda.depends_on.allowed_values)) {
      return true;
    }
    if (agenda.depends_on.criteria === CRITERIA_AFFILIATION) {
      if (
        isEmpty(registrationData.affiliations) ||
        isEmpty(
          registrationData.affiliations.filter((affiliation) =>
            agenda.depends_on.allowed_values?.includes(affiliation.id),
          ),
        )
      ) {
        return false;
      }
      return true;
    }
    if (agenda.depends_on.criteria === CRITERIA_CUSTOM) {
      // eslint-disable-next-line camelcase
      const { pre_registration_form } = eventData;
      if (!isEmpty(pre_registration_form) && !isEmpty(pre_registration_form.fields)) {
        const fieldId = pre_registration_form.fields[0].id;
        if ([FORM_FIELD_TYPE_DROPDOWN_RANGE].indexOf(agenda.depends_on.field_type) !== -1) {
          if (registrationData[fieldId]) {
            const d = parseInt(registrationData[fieldId].trim(), 10);
            return !Number.isNaN(d) && agenda.depends_on.allowed_values.indexOf(d) !== -1;
          }
          return false;
        }
        if ([FORM_FIELD_TYPE_DROPDOWN].indexOf(agenda.depends_on.field_type) !== -1) {
          return agenda.depends_on.allowed_values.indexOf(registrationData[fieldId]) !== -1;
        }
        if ([FORM_FIELD_TYPE_LOCATION].indexOf(agenda.depends_on.field_type) !== -1) {
          if (!isEmpty(registrationData[`google_place_${fieldId}`])) {
            const d = registrationData[`google_place_${fieldId}`].gpid;
            return agenda.depends_on.allowed_values.map((val) => val.gpid).indexOf(d) !== -1;
          }
        }
      }
    }

    return true;
  }

  const shouldShowThisTicket = (ticketData, dependentTicketIdL) => {
    const associatedAgendas = eventData.agendas.filter(
      (val) => val.tickets.map((v) => v.id).indexOf(ticketData.id) !== -1,
    );

    // we are checking if the ticket has any agenda associated with it and if it has , see if those agendas are visible to user
    if (
      !isEmpty(associatedAgendas) &&
      isEmpty(compact(associatedAgendas.map((associatedAgenda) => shouldShowAgenda(associatedAgenda))))
    ) {
      return false;
    }

    const { is_active: isActive, depends_on: dependsOn } = ticketData;
    if (isActive) {
      if (dependsOn && dependsOn === dependentTicketIdL) {
        return true;
      }
    }
    return false;
  };

  const getData = (dependentTicketIdL) => {
    const agendaItemsL = [];

    let allTickets = [...getActiveAndNonMandatoryTickets(eventData.tickets || [])];

    allTickets = allTickets.filter((ticket) => shouldShowThisTicket(ticket, dependentTicketIdL));

    setIsVisible(!isEmpty(allTickets) || !isEmpty(agendaItemsL));
    setTickets([...allTickets]);
    setAgendaItems([...agendaItemsL]);
  };

  useEffect(() => {
    if (dependentTicketId) getData(dependentTicketId);
    else setIsVisible(false);
  }, [dependentTicketId]);

  function getActiveAndNonMandatoryTickets(ticketsL) {
    return ticketsL.filter((ticket) => ticket.ticket_type !== MANDATORY_TICKET && ticket.is_active);
  }

  const getTicketName = (id) => {
    if (!id) return '';
    return find(eventData.tickets, (ticket) => id === ticket.id)?.name || '';
  };

  const getRequiredDateString = (date) => getFormattedDate(date, 'MMM D, YYYY');

  return (
    <Modal
      visible={isVisible}
      closable={false}
      maskClosable
      footer={null}
      wrapClassName="addons-modal"
      width="24rem"
      onCancel={handleCancel}>
      <div className="addons-modal-header">
        <div className="addons-modal-skip-button" onClick={handleCancel}>
          <FontAwesomeIcon icon={faTimes} />
          Skip
        </div>
        <div className="addons-modal-skip-title arc-H300 arc-color-black">Add-ons</div>
        <div className="addons-modal-skip-description arc-color-B65">
          With '{getTicketName(dependentTicketId)}
          ', you can purchase any of the following tickets as well.
        </div>
      </div>
      <div className="addons-modal-content">
        {!isEmpty(agendaItems) &&
          getAgendaItems(agendaItems).map((agenda) =>
            agenda.data.map(
              (item) =>
                !isEmpty(item.tickets) && (
                  <AgendaItem
                    // eslint-disable-next-line no-underscore-dangle
                    dateString={getRequiredDateString(agenda._d)}
                    inModal
                    currencyEntity={currencyEntity}
                    data={item}
                    key={item.id}
                    handleAgendaBookAction={() => handleAgendaItemClick(item.id)}
                    boughtQuantity={
                      item.tickets.length === 1 ? getBoughtTicketQuantity(selectedTickets, item.tickets[0].id) : 0
                    }
                  />
                ),
            ),
          )}
        <If condition={!isEmpty(tickets)}>
          {sortBy(tickets, 'display_order', 'asc').map((ticket) => (
            <AgendaItem
              inModal
              currencyEntity={currencyEntity}
              data={ticket}
              type="ticket"
              key={ticket.id}
              boughtQuantity={getBoughtTicketQuantity(selectedTickets, ticket.id)}
              handleTicketBookAction={() => handleTicketItemClick(ticket.id)}
            />
          ))}
        </If>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
});

export default connect(mapStateToProps, {})(withRouter(AddOnsModal));
