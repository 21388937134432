import React, { Component } from 'react';
import { array } from 'prop-types';
import { Card, Modal } from 'antd';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/pro-light-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

const infoModal = Modal.info;
const IMAGES_OFFSET = 10;

class GalleryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxImageCount: IMAGES_OFFSET,
      images: props.images,
    };
  }

  openImage(imgUrl) {
    infoModal({
      content: <img src={imgUrl} alt={this.props.eventName} />,
      maskClosable: true,
    });
  }

  handleViewMore() {
    const count = this.state.maxImageCount + IMAGES_OFFSET;
    this.setState({
      maxImageCount: count,
    });
  }

  render() {
    const { maxImageCount, images } = this.state;
    return (
      <Card bordered={false} className="event-card" id="event-gallery-card">
        <h3 className="arc-H200 arc-color-B85">
          <I18nCustomFormatter id="event-gallery-card-title" />
        </h3>
        <div id="gallery-items">
          {images.slice(0, maxImageCount).map((image) => (
            <img
              src={image.image}
              key={image.id}
              alt={this.props.eventName}
              onClick={() => this.openImage(image.image)}
            />
          ))}
        </div>
        <If condition={images.length > maxImageCount}>
          <p className="arc-anchor mt8 arc-d-inline-block arc-cursor-p pl2" onClick={() => this.handleViewMore()}>
            <FontAwesomeIcon icon={faImages} className="mr8" />
            <span>View More</span>
          </p>
        </If>
      </Card>
    );
  }
}

GalleryCard.propTypes = {
  images: array.isRequired,
};

export default GalleryCard;
