import React, { Component } from 'react';
import { Route, matchPath, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';

import * as route from '../routes';

import EventXLayout from '../alumni';
import Home from '../alumni/home';
import TicketsPage from '../alumni/tickets';
import GuestDetails from '../alumni/guestDetails';
import BookingForm from '../alumni/bookingForm';
import AlmasightsProvider from 'Src/common/providers/almasights';

class EventActionRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFooter: false,
      currentStep: 1,
      requiredFormValidation: false,
    };
  }

  componentWillMount() {
    this.getCurrentRoute(this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.getCurrentRoute(nextProps.location.pathname);
    }
  }

  getCurrentRoute(pathName) {
    let matchedRoute = null;
    route.ROUTES.forEach((r) => {
      const matchedPath = matchPath(pathName, {
        path: r.path,
      });
      if (matchedPath) {
        matchedRoute = r;
      }
    });
    if (matchedRoute) {
      this.setState({
        showFooter: matchedRoute.showFooter,
        currentStep: matchedRoute.step,
        requiredFormValidation: matchedRoute.requiredFormValidation,
      });
    }
  }

  isInvalidRoute() {
    const { registrationData, eventData } = this.props;
    if (this.state.currentStep !== 1) {
      if (
        (!eventData.is_registration_open || eventData.is_past || isEmpty(registrationData)) &&
        !window.isSiteAdmin &&
        !window.isEventAdmin
      ) {
        return true;
      }
    }
    return false;
  }

  render() {
    if (this.isInvalidRoute()) {
      return <Redirect to={route.ALUMNI_BASE_URL.replace(':eventSlug', this.props.eventData.slug)} />;
    }
    return (
      <AlmasightsProvider
        parentObject={{
          type: 'eventx',
          id: this.props.eventData.id,
          name: this.props.eventData.name,
        }}>
        <EventXLayout {...this.state}>
          <Route exact path={this.props.match.path} component={Home} />
          <Route path={route.TICKETS_VIEW_URL} component={TicketsPage} />
          <Route path={route.GUEST_VIEW_URL} component={GuestDetails} />
          <Route path={route.BOOKING_FORM_VIEW_URL} component={BookingForm} />
        </EventXLayout>
      </AlmasightsProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
  eventData: state.eventDataReducer.data || {},
});

export default connect(mapStateToProps, null)(Sentry.withProfiler(EventActionRoute));
