export const animateMountingBlock = (element, callback) => {
    if (element) {
        window.setTimeout(() => {
            element.classList.remove('forward-page-change-un-mount');
            if (callback) {
                callback();
            }
            element.classList.add('forward-page-change-mount');
        }, 200)
    }
}

export const animateUnMountingBlock = (elementId, callback) => {
    if (elementId) {
        elementId.classList.remove('forward-page-change-mount');
        elementId.classList.add('forward-page-change-un-mount');
    }
}