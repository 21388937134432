import { REDUX_STATUSES } from './../../common/constants';

const initialState = {
    data: {},
    status: REDUX_STATUSES.FETCHING
}

const SET_EVENT_DATA = 'EVENT/SET_EVENT_DATA'

export const setEventData = (data) => {
    return {
        type: SET_EVENT_DATA,
        payload: data
    }
}

const eventDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EVENT_DATA:
            return {
                ...state,
                data: action.payload,
                status: REDUX_STATUSES.FETCHED
            };
        default:
            return state;
    }
}

export default eventDataReducer;