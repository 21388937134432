import store from '../store';


const initialState = {
    currencyEntity: '',
    mandatoryTickets: [],
    isAdminEditingGuest: false,
    isAdminAddingGuest: false,
    rsvpStatuses: {},
    registrationStatuses: {},
    registrationHash: '',
    isTicketedEvent: false,
    isEditMode: false,
    isEventAdmin: false,
    userData: {},
    initialRegistrationData: {},
    affiliations: []
}

const SET_CONTEXT_DATA = 'EVENT/SET_CONTEXT_DATA'

export const setContextData = (data) => {
    return {
        type: SET_CONTEXT_DATA,
        payload: { ...store.getState().contextDataReducer, ...data }
    }
}

const contextDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTEXT_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default contextDataReducer;