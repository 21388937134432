import React from 'react';
import { Col, Row, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import './style.scss';

function Header({ hideGoBack, goBack }) {
  return (
    <div className="registrant-header">
      <Row type="flex" align="middle" justify="space-between">
        <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
          <If condition={!hideGoBack}>
            <Button
              type="link"
              className="go-back-wrapper mr16 arc-color-primary-imp link-btn arc-focus-outline"
              aria-label="Go back"
              onClick={goBack}>
              <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '18px' }} />
            </Button>
          </If>
          <div className={`arc-d-inline-block ${hideGoBack ? 'ml16' : ''}`}>
            <p className="arc-H200 arc-color-black arc-text-uppercase" role="heading" aria-level="2">
              <span>Register</span>
            </p>
            <p className="arc-support arc-text-capitalize mt-2">
              <span>{window.eventData?.name}</span>
            </p>
          </div>
        </Col>
        <Col span={5} className="arc-text-align-r">
          <img className="college-logo" src={window.customerLogo} alt={window.collegeName} />
        </Col>
      </Row>
    </div>
  );
}

export default Header;
