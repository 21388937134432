import React from 'react';
import { string, func, shape } from 'prop-types';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { readableNumber } from 'Src/common/utilities/data_util';

import { SEATED_TICKET } from '../../../constants';

import './style.scss';

function ModalHeader({ ticket, agenda, currencyEntity, handleCancel }) {
  return (
    <div id="ticket-modal-header">
      <Button type="link" className="link-btn arc-focus-outline" id="ticket-modal-back" onClick={() => handleCancel()}>
        <FontAwesomeIcon icon={faArrowLeft} className="mr8" />
        {agenda && Object.keys(agenda).length ? agenda.name : 'Go back'}
      </Button>
      <div className="arc-d-flex">
        <If condition={ticket.image}>
          <img src={ticket.image} className="event-ticket-image" alt="" />
        </If>
        <div className="ticket-details">
          <p className="ticket-name" role="heading" aria-level="2">
            {ticket.name}
          </p>
          <If condition={parseFloat(ticket.price)}>
            <p className="ticket-price">
              <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
              {readableNumber(ticket.price)}
            </p>
          </If>
          <If condition={ticket.ticket_type === SEATED_TICKET}>
            <If condition={!parseFloat(ticket.price)}>
              <span className="ticket-quantity-info">Free ticket</span>
              <span className="ticket-quantity-info-separator">&#x25cf;</span>
            </If>
            <span className="ticket-quantity-info">Seats {ticket.number_of_guest_allowed}</span>
          </If>
        </div>
      </div>
    </div>
  );
}

ModalHeader.propTypes = {
  ticket: shape().isRequired,
  agenda: shape(),
  currencyEntity: string,
  handleCancel: func.isRequired,
};

ModalHeader.defaultProps = {
  agenda: {},
  currencyEntity: '',
};

export default ModalHeader;
