import React from 'react';
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import AgendaItems from './agendaItems';

import './addItineraryToCalendar.scss';

function AddItineraryToCalendar({ agendas, onClose, getContactEmail, eventData, registrationData }) {
  return (
    <Modal
      wrapClassName="add-itinerary-modal"
      closable={false}
      title={
        <div>
          <div className="title arc-d-flex arc arc-flex-vertical-align">
            <FontAwesomeIcon icon={faArrowLeft} className="arc-color-primary arc-cursor-p" onClick={onClose} />
            <p className="arc-H200 ml8">{eventData.name}</p>
          </div>
          <If condition={eventData.start_datetime || eventData.end_datetime}>
            <p className="arc-support arc-color-B65 ml24 arc-font-weight-normal">
              {moment(eventData.start_datetime).format('MMMM Do YYYY, h:mm a')} to{' '}
              {moment(eventData.end_datetime).format('MMMM Do YYYY, h:mm a')}
            </p>
          </If>
        </div>
      }
      footer={[
        <Button type="primary" block onClick={onClose}>
          Done
        </Button>,
      ]}
      visible>
      <AgendaItems
        agendas={agendas}
        getContactEmail={getContactEmail}
        eventData={eventData}
        registrationData={registrationData}
      />
    </Modal>
  );
}

export default AddItineraryToCalendar;
