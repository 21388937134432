import React from "react";
import { array, bool } from 'prop-types'
import L from 'leaflet';
import { Map as LMap, TileLayer, Marker } from 'react-leaflet';

const markerIcon = new L.Icon({
    iconUrl: `${window.staticPath}img/pointer.png`,
    iconAnchor: new L.Point(16, 16),
});

let initialLoad = true;

const LeafletMap = ({ position, triggerResize = false }) => {

    /**
    * This is quick hack to solve the leaflet render issue
    * its happen when the dom is not rendered completely and component is mounted
    * like when you have animation effect to load the dom.
    */
    if (triggerResize) {
        (function triggerResize() {
            window.setTimeout(() => {
                if (initialLoad) {
                    window.dispatchEvent(new Event('resize'));
                    initialLoad = false;
                }
            }, 3000)
        }())
    }

    return (
        <div>
            <LMap
                center={position}
                zoom={14}
                attributionControl={false}
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                dragging={false}
                animate={true}
            >
                <TileLayer
                    url='https://api.mapbox.com/styles/v1/kanhai/cjn1fj39k5nxz2srvam87wlae/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia2FuaGFpIiwiYSI6ImNqbTBzdmlnMzAwbDYzcG11d2Q1MjMwdHIifQ.2MIXutoReevybSa30tWURQ'
                />
                <Marker position={position} icon={markerIcon}>
                </Marker>
            </LMap>
        </div>
    );
}


LeafletMap.propTypes = {
    position: array.isRequired,
    triggerResize: bool
}

export default LeafletMap;
