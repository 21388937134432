import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message, Checkbox } from 'antd';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { times, keys, findIndex } from 'lodash';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
// === REDUX === //
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { faTrashAlt, faInfoCircle, faUsers } from '@fortawesome/pro-light-svg-icons';
import { setRegistrationData } from '../../../reducers/registrationDataReducer';
// === APP UTILS AND CONSTANTS === //
import { canGuestDeleteAndGetTickets, deleteGuestAndCount, getFullName } from '../../../utils';
import { MAX_GUEST_ALLOWED } from '../../../constants';
// === UTILS AND CONSTANTS === //
import { getKeyValue, getFirstAndLastName } from '../../../../common/utilities/data_util';

import ThemeXModal from '../../../../common/components/themeXModal';
import './style.scss';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

const COUNTER_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

class GuestCountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guestCount: getKeyValue(this.props.registrationData, 'guests', []).length || 1,
      maxGuestAllowed: props.eventData.max_guests_allowed || MAX_GUEST_ALLOWED,
      shouldShowOnAttendeeList: getKeyValue(this.props.registrationData, 'should_show_on_attendee_list', true),
    };
  }

  /**
   * Checks if events has ant sponsorship ticket
   */
  hasSponsorshipTicket() {
    const { eventData } = this.props;
    const index = findIndex(eventData.tickets, (val) => val.ticket_type === 'sponsored');
    return index > -1;
  }

  handleGuestCounter(counterAction, existingGuestsCount) {
    let { guestCount, maxGuestAllowed } = this.state;
    if (counterAction === COUNTER_ACTIONS.ADD) {
      if (guestCount < maxGuestAllowed) {
        guestCount += 1;
        this.setState({ guestCount });
      } else {
        message.error(`You can not add more than ${maxGuestAllowed} guests`);
      }
    } else if (guestCount != 1) {
      if (this.props.isEditMode && guestCount <= existingGuestsCount) {
        this.deleteGuest(guestCount - 1);
      } else {
        guestCount -= 1;
        this.setState({ guestCount });
      }
    } else {
      message.error('You need at least one guest to register');
    }
  }

  deleteGuest(guestIndex) {
    const { registrationData } = this.props;
    const { canDelete, guestTickets } = canGuestDeleteAndGetTickets(
      this.props.eventData.tickets,
      registrationData.guests,
      guestIndex,
    );
    if (canDelete) {
      const { data, count } = deleteGuestAndCount(registrationData, guestTickets, guestIndex);
      data.guests.map((guest, index) => (guest.tmp_id = index));
      this.props.setRegistrationData(data);
      this.setState({ guestCount: count });
    } else {
      message.error('To delete this guest, please go back and remove any associated tickets.');
    }
  }

  validateGuests(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const guests = keys(values).map((value) => ({
          ...getFirstAndLastName(values[value]),
          should_show_on_attendee_list: this.state.shouldShowOnAttendeeList,
        }));
        this.props.handleGuests(guests);
      }
    });
  }

  getInitialValue(index) {
    if (getKeyValue(this.props.registrationData, 'guests', []).length) {
      const guest = this.props.registrationData.guests[index];
      if (guest) {
        return getFullName(guest);
      }
    }
    return null;
  }

  getFormLabel(index) {
    return (
      <span>
        <span className="arc-color-error">*</span>
        {` Guest ${index + 1}`}
      </span>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isGuestRegistrationsEnabled, eventData } = this.props;
    const existingGuestsCount = getKeyValue(this.props.registrationData, 'guests', []).length;
    const guestCount = eventData.count_guests;
    const nonAnonymousGuestCount = eventData.attending_guest_profiles.filter(
      (profile) => profile.should_show_on_attendee_list,
    ).length;
    return (
      <ThemeXModal
        title={isGuestRegistrationsEnabled && 'How many people will be attending?'}
        visible
        closable={false}
        wrapClassName="guest-count-modal"
        maskClosable
        handleCancel={this.props.handleCancel}>
        <If condition={isGuestRegistrationsEnabled}>
          <div id="guest-counter">
            <Button
              aria-label="Decrease"
              className="link-btn arc-focus-outline"
              onClick={() => this.handleGuestCounter(COUNTER_ACTIONS.REMOVE, existingGuestsCount)}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <FormItem>
              <Input readOnly disabled value={this.state.guestCount} tabIndex={-1} aria-label="Guest count" />
            </FormItem>
            <Button
              aria-label="Increase"
              className="link-btn arc-focus-outline"
              onClick={() => this.handleGuestCounter(COUNTER_ACTIONS.ADD, existingGuestsCount)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </If>
        <If condition={this.hasSponsorshipTicket()}>
          <p className="arc-support mt16 mb16 p24 arc-color-B55">
            <FontAwesomeIcon icon={faInfoCircle} className="mr8" />
            <span>
              Some tickets may require you to enter your guests. If you do not know yet, you can skip this step and
              proceed with the registration.
            </span>
          </p>
        </If>
        <Form onSubmit={(e) => this.validateGuests(e)} className="inline-form" id="guest-count-form">
          <div>
            {times(this.state.guestCount).map((index) => (
              <FormItem {...formItemLayout} label={this.getFormLabel(index)} colon={false} key={`guest-${index}`}>
                {getFieldDecorator(`guest_${index + 1}`, {
                  initialValue: this.getInitialValue(index),
                  rules: [
                    {
                      required: true,
                      message: 'Please enter name',
                    },
                  ],
                })(
                  <Input
                    placeholder="Full Name"
                    aria-required="true"
                    addonAfter={
                      this.props.isEditMode && index !== 0 && index <= existingGuestsCount - 1 ? (
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.deleteGuest(index)} />
                      ) : (
                        ''
                      )
                    }
                    disabled={this.props.isEditMode && index === 0}
                  />,
                )}
              </FormItem>
            ))}
          </div>
          <If condition={eventData.is_guest_count_shown_to_users}>
            <div
              className="ml24 mr24 mt20 mb8 p16"
              id="guest-privacy-container"
              role="group"
              aria-label="Guest Privacy">
              <p className="arc-p arc-font-weight-bold arc-color-gray8">GUEST PRIVACY</p>
              <Checkbox
                className="arc-p mt8 mb8 arc-font-weight-medium arc-focus-outline"
                onChange={(e) => this.setState({ shouldShowOnAttendeeList: e.target.checked })}
                checked={this.state.shouldShowOnAttendeeList}>
                Let others know I will be attending
              </Checkbox>
              <span id="guest-privacy-description" className="arc-color-B55 arc-font-weight-400">
                Encourage others to join in by adding your name. If you'd prefer to keep your participation private,
                we're happy to list you as 'anonymous'
              </span>
            </div>
            <If condition={eventData.is_guest_count_shown_to_users && guestCount}>
              <div
                className="mb48 ml48 mr48 arc-color-B55 arc-font-weight-400 arc-text-align-c"
                id="guest-attendee-list-description">
                <FontAwesomeIcon icon={faUsers} className="mr8" />
                <span>
                  <Choose>
                    <When condition={guestCount <= 2 && nonAnonymousGuestCount === guestCount}>
                      <I18nCustomFormatter
                        id="event-guest-is-attending"
                        values={{
                          full_name: eventData.attending_guest_profiles[0].full_name,
                          second_full_name:
                            nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].full_name : null,
                          non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                        }}
                      />
                    </When>
                    <When condition={nonAnonymousGuestCount === 0}>
                      <I18nCustomFormatter
                        id="guest-counts-attending-numbers"
                        values={{
                          guests: guestCount,
                        }}
                      />
                    </When>
                    <Otherwise>
                      <span className="guest-list-trigger">
                        <I18nCustomFormatter
                          id="event-guests-more-than-one-prefix"
                          values={{
                            full_name: eventData.attending_guest_profiles[0].first_name,
                            second_full_name:
                              nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].first_name : null,
                            non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                          }}
                        />{' '}
                        <I18nCustomFormatter
                          id="guest-counts-attending-link"
                          values={{
                            guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                          }}
                        />{' '}
                      </span>
                      <I18nCustomFormatter
                        id="guest-counts-attending-text"
                        values={{
                          guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                        }}
                      />
                    </Otherwise>
                  </Choose>
                </span>
              </div>
            </If>
          </If>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="arc-modal-footer-full-width-btn arc-focus-outline">
            Proceed
          </Button>
        </Form>
      </ThemeXModal>
    );
  }
}

GuestCountModal.propTypes = {
  isEditMode: bool,
  isGuestRegistrationsEnabled: bool,
  isAdminAddingGuest: bool,
  isAdminEditingGuest: bool,
  handleGuests: func.isRequired,
  handleCancel: func.isRequired,
};

GuestCountModal.defaultProps = {
  isEditMode: false,
  isGuestRegistrationsEnabled: false,
};

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(GuestCountModal));
