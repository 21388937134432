import React from 'react';
import './style.scss';

function NoContentState() {

    const imgPath = `${staticPath}img/react-modules/feeds/`;

    return (
        <div className="no-content-state">
            <div
                style={{ backgroundImage: `url(${imgPath}empty_state_bg.png)` }}
                className="graphic-block"
            >
                <img src={`${imgPath}conversation.png`} />
            </div>
            <div>
                <p className="arc-p">
                    Ask a question or share a story to spark up a conversation.
                </p>
            </div>
        </div>
    )
}

export default NoContentState;