import React from 'react';
import { LOGO_IMAGE } from 'Src/adminEvents/constants';
import { BREAKPOINT, ORIENTATION, COVER_IMAGE } from 'Src/common/constants';

import './style.scss';

function DetailsPageEmptyShell({ eventData }) {
  const width = document.documentElement.clientWidth || 0;

  function getFeaturedImage() {
    const orientation = width < BREAKPOINT ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE;
    const eventImages = eventData.images.filter((image) => image.image_type !== LOGO_IMAGE);
    const featuredImage = eventImages.filter(
      (image) => image.image_type === COVER_IMAGE && image.image_orientation === orientation,
    );
    if (featuredImage.length) {
      return featuredImage[0].image;
    }
    if (eventImages.length) {
      return eventImages[0].image;
    }

    return window.defaultBannerImage;
  }

  return (
    <div className="empty-shell-container">
      <div className="hero-banner-wrapper">
        <div className="full-overlay" style={{ backgroundImage: `url(${getFeaturedImage()})` }} />
      </div>
    </div>
  );
}

export default DetailsPageEmptyShell;
