import React from 'react';
import { isEmpty } from 'lodash';
import ThemeXModal from 'Src/common/components/themeXModal';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import './guestDetails.scss';
import { getValueByType } from 'Src/common/utilities/form_utils';

function GuestDetailsModal({ guest, closeModal }) {
  /* Shows the guest details as well as form entries for a guest */
  return (
    <ThemeXModal visible title="Form Submissions" handleCancel={closeModal} wrapClassName="guest-details-modal">
      <div className="confirmation-form-item">
        <p className="arc-H150 arc-color-B85">
          <I18nCustomFormatter id="confirmation-form-first-name" />
        </p>
        <p className="arc-p arc-color-B55">{guest.first_name}</p>
      </div>
      <If condition={guest.last_name}>
        <div className="confirmation-form-item mt16">
          <p className="arc-H150 arc-color-B85">
            <I18nCustomFormatter id="confirmation-form-last-name" />
          </p>
          <p className="arc-p arc-color-B55">{guest.last_name}</p>
        </div>
      </If>
      <If condition={guest.email}>
        <div className="confirmation-form-item mt16">
          <p className="arc-H150 arc-color-B85">
            <I18nCustomFormatter id="confirmation-form-email" />
          </p>
          <p className="arc-p arc-color-B55">{guest.email}</p>
        </div>
      </If>
      <If condition={guest.affiliations}>
        <div className="confirmation-form-item mt16">
          <p className="arc-H150 arc-color-B85">
            <I18nCustomFormatter id="confirmation-form-affiliation" />
          </p>
          <p className="arc-p arc-color-B55">{guest.affiliations.map((affiliation) => affiliation.name).join(', ')}</p>
        </div>
      </If>
      <If
        condition={
          guest.is_parent_registration &&
          !isEmpty(registrationData.form_entry) &&
          !isEmpty(registrationData.form_entry.field_entries)
        }>
        <p className="arc-H200 arc-color-B85 mt32 mb8">
          <I18nCustomFormatter id="confirmation-registration-form-title" />
        </p>
        {registrationData.form_entry.field_entries.map((entry) => (
          <div className="confirmation-form-item mt16" key={entry.slug}>
            <p className="arc-H150 arc-color-B85">{entry.label}</p>
            <p className="arc-p arc-color-B55">{getValueByType(entry.field_type, entry.value, entry.display_value)}</p>
          </div>
        ))}
      </If>
      <If condition={!isEmpty(guest.form_entry) && !isEmpty(guest.form_entry.field_entries)}>
        <p className="arc-H200 arc-color-B85 mt32 mb8">
          <I18nCustomFormatter id="confirmation-guest-form-title" />
        </p>
        {guest.form_entry.field_entries.map((entry) => (
          <div className="confirmation-form-item mt16" key={entry.slug}>
            <p className="arc-H150 arc-color-B85">{entry.label}</p>
            <p className="arc-p arc-color-B55">{entry.value || '-'}</p>
          </div>
        ))}
      </If>
    </ThemeXModal>
  );
}

export default GuestDetailsModal;
