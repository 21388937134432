import React from 'react';
import { shape } from 'prop-types';
import { Tabs } from 'antd';
import { isEmpty } from 'lodash';

import ScheduleTab from './ScheduleTab';
import './style.scss';

const { TabPane } = Tabs;

function DetailTabs({ eventData }) {
  const { description, agendas } = eventData;

  const nonConditionalAgendas = agendas.filter((val) => isEmpty(val.depends_on));

  return (
    <div id="event-detail-tabs">
      <Choose>
        <When condition={description && nonConditionalAgendas.length}>
          <Tabs defaultActiveKey="details_tab">
            <TabPane tab="Details" key="details_tab">
              <div className="tab-content-body fr-view" id="detail-tab-content">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </TabPane>
            <TabPane tab="Schedule" key="schedule_tab">
              <ScheduleTab agendas={nonConditionalAgendas} />
            </TabPane>
          </Tabs>
        </When>
        <Otherwise>
          <Choose>
            <When condition={description}>
              <div className="tab-content-body fr-view" id="detail-tab-content">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            </When>
            <Otherwise>
              <ScheduleTab agendas={nonConditionalAgendas} />
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </div>
  );
}

DetailTabs.propTypes = {
  eventData: shape(),
};

DetailTabs.defaultProps = {
  eventData: {},
};

export default DetailTabs;
