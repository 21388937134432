import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/';

const enhancers = [applyMiddleware(thunk)];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(...enhancers);

const store = createStore(rootReducer, enhancer);

export default store;