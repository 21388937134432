import React from 'react';
import { string, object } from 'prop-types';
import { readableNumber } from 'Src/common/utilities/data_util';

import RegistrationItemSection from './../index';

import './style.scss';

const RegistrationGiftSection = ({ gift, currencyEntity }) => {
  return (
    <div id="registration-fund-section">
      <If condition={gift && Object.keys(gift).length}>
        <RegistrationItemSection title="Gifts">
          {gift.splits.map((split) => {
            const { fund } = split;
            return (
              <div className="fund-item" key={split.id}>
                <div>
                  <p className="arc-H150 arc-color-B85">{split.name}</p>
                </div>
                <div className="fund-price">
                  <p className="arc-H150 arc-color-B55">
                    <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                    <span>{readableNumber(split.amount)}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </RegistrationItemSection>
      </If>
    </div>
  );
};

RegistrationGiftSection.propTypes = {
  currencyEntity: string.isRequired,
  gift: object,
};

RegistrationGiftSection.defaultProps = {
  gift: {},
};

export default RegistrationGiftSection;
