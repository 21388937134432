import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { isEmpty } from 'lodash';
// === REDUX === //
import { setRegistrationData } from './../../reducers/registrationDataReducer';
import { setHeroBannerData } from './../../reducers/heroBannerReducer';
// === UTILS AND CONSTANTS === //
import { animateMountingBlock, animateUnMountingBlock } from './../../../common/utilities/animate_util';
// === APP UTILS AND CONSTANTS === //
import { getMandatoryTickets, getTicket, getFund } from './../../utils';
import { ALUMNI_BASE_URL } from './../../routes';
// === COMPONENTS === //
import AgendaItemContainer from './AgendaItemContainer';
import AgendaTicketsModal from './AgendaTicketsModal';
import TicketsModal from './TicketsModal'
import GiftDonationModal from './GiftDonationModal';
import AddOnsModal from "./addondsModal";

class TicketsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agenda: {},
      ticket: {},
      showAgendaTicketsModal: false,
      showTicketsModal: false,
      showGiftModal: false,
      showCartModal: false,
      showAddsonModal: false,
      dependentTicketId: null
    };
  }

  componentDidMount() {
    animateMountingBlock(document.getElementById('hero-banner-content'), this.animateCallBack);
  }

  animateCallBack = () => {
    this.props.setHeroBannerData({ 'back_text': this.props.eventData.name, 'title': 'Select tickets' })
  }

  componentWillMount() {
    if (isEmpty(this.props.registrationData)) {
      this.props.history.push(ALUMNI_BASE_URL.replace(':eventSlug', this.props.eventData.slug))
    }
  }

  componentWillUnmount() {
    animateUnMountingBlock(document.getElementById('hero-banner-content'));
  }

  /**
   * Method to set the agenda and show the agenda tickets modal
   * @param {integer} agendaId 
   * If only one ticket in agenda open ticket modal directly
   */
  showTicketsModalBasedOnAgenda(agendaId) {
    let agenda = this.getAgenda(agendaId);
    const selectedTickets = this.getSelectedTicketIds()
    const tickets = agenda.tickets.filter((ticket) => this.shouldShowThisTicket(ticket, selectedTickets)) || []
    if (tickets.length === 1) {
      this.showTicketModal(tickets[0].id);
    } else {
      this.setState({
        showAddsonModal: false,
        dependentTicketId: null,
        showAgendaTicketsModal: true,
        showTicketsModal: false,
        agenda: { ...agenda, tickets },
      });
    }
  }


  getSelectedTicketIds = () => {
    const { tickets } = this.props.registrationData
    const result = []
    if (!isEmpty(tickets)) {
      for (let item of tickets) {
        const { ticket } = item
        result.push(ticket.id)
      }
    }
    return result;
  }

  shouldShowThisTicket = (ticketData, selectedTickets) => {
    const { is_active, id, depends_on } = ticketData
    if (is_active) {
      if (depends_on) {
        if (selectedTickets.includes(depends_on))
          return true
        else
          return false
      }
      return true
    }
    return false
  }

  getAgenda(agendaId) {
    return this.props.eventData.agendas.filter((agenda) => {
      return agenda.id === agendaId
    })[0]
  }

  // Method to show the ticket modal by setting the ticket
  showTicketModal(ticketId) {
    this.setState({
      showAddsonModal: false,
      dependentTicketId: null,
      showAgendaTicketsModal: false,
      showTicketsModal: true,
      ticket: getTicket(this.props.eventData.tickets, ticketId)
    });
  }

  showGiftModal(fundId) {
    this.setState({
      showAgendaTicketsModal: false,
      showGiftModal: true,
      ticket: getFund(this.props.eventData.funds, fundId)
    });
  }

  handleCancelTicketsModal(isCartUpdate = false) {
    this.setState({
      showAgendaTicketsModal: !isCartUpdate && !isEmpty(this.state.agenda),
      showTicketsModal: false,
      ...(isCartUpdate ? { agenda: {} } : {})
    })
  }

  handleCancelGiftModal() {
    this.setState({
      showGiftModal: false,
    })
  }

  // Method to add the fund in the cart
  addGiftInCart(amount, fundGift) {
    let { registrationData } = this.props;
    let gift = registrationData.gift || {};
    let splits = gift.splits || [];
    const index = splits.findIndex(item => item.fund.id === fundGift.fund.id);
    if (index > -1) {
      splits[index].amount = amount;
    }
    else {
      splits.push({
        amount,
        fund: {
          id: fundGift.fund.id,
          name: fundGift.fund.name
        },
        name: fundGift.name
      })
    }
    registrationData.gift = { splits };
    this.props.setRegistrationData(registrationData);
    this.setState({ showGiftModal: false });
  }

  removeGiftFromCart(givingFundId) {
    let { registrationData } = this.props;
    let { gift = {} } = registrationData;
    let { splits = [] } = gift
    const index = splits.findIndex(gift => gift.fund.id === givingFundId);
    if (index > -1) {
      splits.splice(index, 1);
    }
    registrationData.gift = { splits };
    this.props.setRegistrationData(registrationData);
    this.setState({ showGiftModal: false });
  }

  cancelAddonsModal = () => {
    this.setState({
      showAddsonModal: false,
      dependentTicketId: null
    })
  }

  checkForDependentTicket = ticketId => {
    this.setState({
      showAddsonModal: true,
      dependentTicketId: ticketId
    })
  }

  render() {
    const { currencyEntity } = this.props.contextData;
    const { tickets, gift = {} } = this.props.registrationData;
    const { ticketEntries } = this.props.ticketDetailsData
    const isDonated = !!this.props.registrationData.is_donated_to_fund;
    return (
      <div>
        <AgendaItemContainer
          currencyEntity={currencyEntity}
          eventData={this.props.eventData}
          selectedTickets={tickets}
          giftDonated={gift}
          isDonated={isDonated}
          handleAgendaItemClick={(agendaId) => this.showTicketsModalBasedOnAgenda(agendaId)}
          handleTicketItemClick={(ticketId) => this.showTicketModal(ticketId)}
          handleFundItemClick={(fundId) => this.showGiftModal(fundId)}
        />
        <Choose>
          <When condition={this.state.showAgendaTicketsModal}>
            <AgendaTicketsModal
              agenda={this.state.agenda}
              currencyEntity={currencyEntity}
              selectedTickets={tickets}
              handleCancel={() => this.setState({ showAgendaTicketsModal: false, agenda: {} })}
              handleTicketSelection={(ticketId) => this.showTicketModal(ticketId)}
            />
          </When>
          <When condition={this.state.showTicketsModal}>
            <TicketsModal
              checkForDependentTicket={this.checkForDependentTicket}
              ticket={this.state.ticket}
              agenda={this.state.agenda}
              currencyEntity={currencyEntity}
              handleCancel={(isCartUpdate) => this.handleCancelTicketsModal(isCartUpdate)}
            />
          </When>
          <When condition={!isDonated && this.state.showGiftModal}>
            <GiftDonationModal
              currencyEntity={currencyEntity}
              eventFund={this.state.ticket}
              handleCancel={() => this.handleCancelGiftModal()}
              handleAddGift={(amount, gift) => this.addGiftInCart(amount, gift)}
              handleGiftRemove={(givingFundId) => this.removeGiftFromCart(givingFundId)}
            />
          </When>
          <When condition={this.state.showAddsonModal}>
            <AddOnsModal
              selectedTickets={tickets}
              handleCancel={this.cancelAddonsModal}
              currencyEntity={currencyEntity}
              handleAgendaItemClick={(agendaId) => this.showTicketsModalBasedOnAgenda(agendaId)}
              handleTicketItemClick={(ticketId) => this.showTicketModal(ticketId)}
              dependentTicketId={this.state.dependentTicketId}
            />
          </When>
        </Choose>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  eventData: state.eventDataReducer.data || {},
  status: state.eventDataReducer.status,
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
  ticketDetailsData: state.ticketDetailsDataReducer.data || {}
})

const mapDispatchToProps = dispatch => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  setHeroBannerData: (data) => dispatch(setHeroBannerData(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketsPage));