/* eslint-disable max-depth */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Menu, Dropdown, message, Popconfirm, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { func, bool } from 'prop-types';
import { find, forEach, groupBy, isEmpty, keys, values } from 'lodash';
import { faTrashAlt, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
// === REDUX === //
import { setContextData } from 'Src/alumniEvents/reducers/contextDataReducer';
import { setRegistrationData } from 'Src/alumniEvents/reducers/registrationDataReducer';
// === APP UTILS AND CONSTANTS === //
import { registerGuest, processPayment, postTicketEntries } from 'Src/alumniEvents/actions';
import {
  getCartData,
  canGuestDeleteAndGetTickets,
  deleteGuestAndCount,
  getInitialDiscount,
  getTotalGiftAmount,
  getReqGuestId,
} from 'Src/alumniEvents/utils';
import { CONFIRMATION_VIEW_URL } from 'Src/alumniEvents/routes';
import { openNotificationWithIcon } from 'Src/common/utilities/notification';
import { setTicketDetailsData } from 'Src/alumniEvents/reducers/ticketDetailsDataReducer';
import ThemeXModal from 'Src/common/components/themeXModal';
import initializeCaptchaV2 from 'Src/common/utilities/captcha_util';
import { getUrlParam } from 'Src/common/utilities/data_util';
import { OPEN_TICKET, SEATED_TICKET, SPONSORED_TICKET } from '../../../constants';

// === COMPONENTS === //
import ModalHeader from '../modalHeader';
import RegistrationItemSection from '../registrationItems';
import RegistrationTicketSection from '../registrationItems/registrationTicketSection';
import RegistrationDiscountSection from '../registrationItems/registrationDiscountSection';
import RegistrationCartSection from '../registrationItems/registrationCartSection';
import RegistrationGiftSection from '../registrationItems/registrationGiftSection';
import DiscountModal from '../discountModal';

import './style.scss';

const ButtonGroup = Button.Group;

class CartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mandatoryTickets: [],
      otherTickets: [],
      showDiscountModal: false,
      discount: getInitialDiscount(props.registrationData),
      cartSubTotal: 0,
      loading: false,
      paymentCollectLaterLoading: false,
      isPaymentStarted: false,
      registrationHashLink: window.location.search,
    };
  }

  UNSAFE_componentWillMount() {
    const { props } = this;
    const { registrationData, eventData } = props;
    const { tickets, guests, gift } = registrationData;
    this.setState(getCartData(eventData.tickets, tickets, guests, gift), () => {
      const { discount_code: discountCode, tickets: regTickets } = registrationData;

      if (discountCode && discountCode?.tickets?.length) {
        const ticketsToRemove = discountCode.tickets;
        const foundMatch = ticketsToRemove.some((ticketToRemove) =>
          regTickets.some((regTicket) => regTicket.ticket.id === ticketToRemove.id),
        );

        if (!foundMatch) {
          this.handleDiscountRemove();
        } else {
          this.setDiscountValue(
            discountCode.is_public ? discountCode.code : 'custom_user_input',
            discountCode.is_public ? null : discountCode,
          );
        }
      } else if (discountCode) {
        this.setDiscountValue(
          discountCode.is_public ? discountCode.code : 'custom_user_input',
          discountCode.is_public ? null : discountCode,
        );
      }
    });
  }

  deleteGuest(guestIndex) {
    const { registrationData, eventData, setRegistrationData } = this.props;
    const { tickets } = eventData;
    const { canDelete, guestTickets } = canGuestDeleteAndGetTickets(tickets, registrationData.guests, guestIndex);
    if (canDelete) {
      const { data } = deleteGuestAndCount(registrationData, guestTickets, guestIndex);
      setRegistrationData(data);
      this.setState(getCartData(tickets, data.tickets, data.guests));
    } else {
      message.error('To delete this guest, please go back and remove any associated tickets.');
    }
  }

  handleDiscountRemove() {
    const { registrationData, setRegistrationData } = this.props;
    const registrationDataL = { ...registrationData };
    registrationDataL.discount_amount = 0;
    registrationDataL.discount_code = null;
    this.setState({ discount: {} });
    setRegistrationData(registrationDataL);
  }

  toggleDiscountModal() {
    this.setState((prevState) => ({ showDiscountModal: !prevState.showDiscountModal }));
  }

  validateAndApplyDiscount(discount, privateDiscount) {
    this.toggleDiscountModal();
    this.setDiscountValue(discount, privateDiscount);
  }

  // Method to set the discount code
  setDiscountValue(discountCode, privateDiscount) {
    const { setRegistrationData, registrationData, eventData } = this.props;
    const { discounts } = eventData;
    if ((discountCode && discountCode !== 'custom_user_input') || !isEmpty(privateDiscount)) {
      let discount = {};
      if (discountCode === 'custom_user_input') {
        discount = privateDiscount;
        discountCode = discount.code;
      } else {
        discount = discounts.filter((discount) => discount.code === discountCode)[0];
      }

      const registrationDataL = { ...registrationData };
      let appliedDiscountValue = 0;

      let { cartSubTotal } = this.state;
      // Removing gift amount from cart subtotal, so that discount will not applied on that
      const splits = (registrationDataL.gift && registrationDataL.gift.splits) || [];
      cartSubTotal -= getTotalGiftAmount(splits);

      const maxDiscount = discount.max_discount;
      const discountValue = discount.value;

      if (discount.tickets.length) {
        discount.tickets.forEach((ticket) => {
          const registrantTicket = registrationDataL.tickets.filter((t) => t.ticket.id === ticket.id)[0];
          if (registrantTicket) {
            appliedDiscountValue += ticket.price * registrantTicket.quantity;
          }
        });
      } else {
        appliedDiscountValue = cartSubTotal;
      }

      if (discount.discount_type === 'percentage_value') {
        appliedDiscountValue = (appliedDiscountValue * discountValue) / 100;
      } else {
        appliedDiscountValue = discountValue;
      }

      if (maxDiscount && appliedDiscountValue > maxDiscount) {
        appliedDiscountValue = maxDiscount;
      }

      if (appliedDiscountValue > cartSubTotal) {
        appliedDiscountValue = cartSubTotal;
      }

      if (this.state.cartSubTotal <= discount.min_cart_value) {
        this.handleDiscountRemove();
        return;
      }

      appliedDiscountValue = parseFloat(appliedDiscountValue).toFixed(2);

      const appliedDiscount = {
        code: discountCode,
        amount: appliedDiscountValue,
        is_public: discount.is_public,
        max_discount: maxDiscount,
        tickets: discount.tickets,
        value: discount.value,
        discount_type: discount.discount_type,
      };

      registrationData.discount_amount = appliedDiscountValue;
      registrationData.discount_code = appliedDiscount;
      this.setState({ discount: appliedDiscount });
      setRegistrationData(registrationData);
    }
  }

  getPayableAmount(registrationData) {
    const { cartSubTotal, discount } = this.state;
    return cartSubTotal - (discount.amount || 0) - (registrationData.paid_amount || 0);
  }

  handleClick() {
    const { isCheckout, handleCancel } = this.props;
    if (isCheckout) {
      this.setState({ loading: true });
      this.createOrUpdateRegistration();
    } else {
      handleCancel();
    }
  }

  handlePaymentCollectionLater() {
    const { registrationData } = this.props;
    this.setState({ paymentCollectLaterLoading: true });
    this.createOrUpdateRegistration(registrationData, true);
  }

  recordOfflinePayment() {
    const { registrationData } = this.props;
    registrationData.mode = 'offline';
    this.setState({ loading: true });
    this.createOrUpdateRegistration(registrationData);
  }

  handleOnlinePayment() {
    this.setState({ loading: true });
    this.createOrUpdateRegistration();
  }

  updateFieldEntries = async (registrationId, ticketEntries, ticketEntriesResponse, guests, hash) => {
    const { eventData } = this.props;
    const { tickets, id } = eventData;
    const payloadArray = [];

    keys(ticketEntries).forEach((ticketId) => {
      const reqTicket = find(tickets, ({ id }) => id === parseInt(ticketId, 10));
      const data = ticketEntries[ticketId];
      if (!reqTicket || isEmpty(data)) return;
      if (reqTicket.ticket_type === SPONSORED_TICKET || reqTicket.ticket_type === SEATED_TICKET) {
        for (const guestId of keys(data)) {
          const reqGuest = find(guests, (guest) => {
            const [type, id] = guestId.split('__');
            if (type === 'tmp_id') {
              return guest.tmp_id === parseInt(id, 10);
            }
            return guest.id === parseInt(id, 10);
          });
          if (
            reqGuest &&
            !isEmpty(reqTicket.fields) &&
            reqTicket.fields[0].choices.map((data) => data.name).includes(data[guestId])
          ) {
            const checkBoughtTicket = find(reqGuest.tickets, ({ ticket }) => ticket.id === parseInt(ticketId, 10));
            if (checkBoughtTicket) {
              payloadArray.push({
                ticket_bought: {
                  ticket: {
                    id: parseInt(ticketId, 10),
                  },
                },
                guest: {
                  id: reqGuest.id,
                },
                entries: [
                  {
                    ticket_field: {
                      id: reqTicket.fields[0].id,
                    },
                    selected_choice: {
                      id: find(reqTicket.fields[0].choices, ({ name }) => name === data[guestId])?.id,
                    },
                  },
                ],
              });
            }
          }
        }
      } else if (reqTicket.ticket_type === OPEN_TICKET) {
        for (const choice of keys(data)) {
          if (reqTicket.fields[0].choices.map((data) => data.name).includes(choice) && data[choice]) {
            payloadArray.push({
              quantity: data[choice],
              ticket_bought: {
                ticket: {
                  id: parseInt(ticketId, 10),
                },
              },
              guest: null,
              entries: [
                {
                  ticket_field: {
                    id: reqTicket.fields[0].id,
                  },
                  selected_choice: {
                    id: find(reqTicket.fields[0].choices, ({ name }) => name === choice)?.id,
                  },
                },
              ],
            });
          }
        }
      }
    });

    const response = await postTicketEntries(id, registrationId, payloadArray, hash);
    return response?.data;
  };

  setTicketEntriesData = (ticketEntries) => {
    const { eventData } = this.props;
    if (eventData) {
      const result = {};
      const groupByEntries = groupBy(ticketEntries, 'ticket_bought.ticket.id');
      for (const ticketId of keys(groupByEntries)) {
        const reqTicket = find(eventData.tickets, ({ id }) => id === parseInt(ticketId, 10));
        if (reqTicket.ticket_type === OPEN_TICKET) {
          const temp = {};
          for (const entry of groupByEntries[ticketId]) {
            Object.assign(temp, {
              [find(reqTicket.fields[0].choices, (choice) => choice.id === entry.entries[0].selected_choice.id)?.name]:
                entry.quantity,
            });
          }
          Object.assign(result, { [ticketId]: temp });
        } else if ([SEATED_TICKET, SPONSORED_TICKET].includes(reqTicket.ticket_type)) {
          const temp = {};
          for (const entry of groupByEntries[ticketId]) {
            Object.assign(temp, {
              [`id__${entry.guest.id}`]: find(
                reqTicket.fields[0].choices,
                (choice) => choice.id === entry.entries[0].selected_choice.id,
              )?.names,
            });
          }
          Object.assign(result, { [ticketId]: temp });
        }
      }
      return { ticketEntries: result, ticketEntriesResponse: ticketEntries };
    }
    return { ticketEntries: {}, ticketEntriesResponse: [] };
  };

  validateTicketDetails() {
    const { eventData, registrationData, ticketDetailsData } = this.props;
    const allBoughtTicket = registrationData.tickets;
    const ticketEntries = ticketDetailsData.ticketEntries || {};

    let isValidateSuccess = true;
    // validate open ticket
    const boughtOpenTicket = allBoughtTicket.filter(({ ticket }) => ticket.ticket_type === OPEN_TICKET);
    for (const item of boughtOpenTicket) {
      const areChoicesThere = eventData.tickets.filter((ticket) => ticket.id === item.ticket.id)[0];
      if (item.quantity && !isEmpty(areChoicesThere.fields)) {
        if (
          ticketEntries[item.ticket.id.toString()] === undefined ||
          item.quantity !==
            values(ticketEntries[item.ticket.id.toString()]).reduce((total, data) => total + parseInt(data, 10), 0)
        ) {
          isValidateSuccess = false;
          openNotificationWithIcon({
            type: 'error',
            message: 'Ticket details error',
            description: `Sorry, but looks like some entries are missing for ${item.ticket.name}`,
          });
          break;
        }
      }
    }
    // validate seated and team ticket
    const boughtSeatedTicket = allBoughtTicket.filter(
      ({ ticket }) => [SEATED_TICKET, SPONSORED_TICKET].includes(ticket.ticket_type),
      // eslint-disable-next-line function-paren-newline
    );
    for (const item of boughtSeatedTicket) {
      const areChoicesThere = eventData.tickets.filter((ticket) => ticket.id === item.ticket.id)[0];
      if (item.quantity && !isEmpty(areChoicesThere.fields)) {
        const allTicketEntriesGuest = keys(ticketEntries[item.ticket.id.toString()] || {});
        for (const guest of registrationData.guests) {
          for (const guestTicket of guest.tickets) {
            if (guestTicket.ticket.id === item.ticket.id) {
              if (!allTicketEntriesGuest.includes(getReqGuestId(guest.tmp_id, guest.id))) {
                isValidateSuccess = false;
                openNotificationWithIcon({
                  type: 'error',
                  message: 'Ticket details error',
                  description: `Sorry, but looks like some entries are missing for ${item.ticket.name}`,
                });
                break;
              }
            }
          }
        }
      }
    }

    return isValidateSuccess;
  }

  injectCaptcha(registrationData, callback) {
    if (!registrationData.id && window.captchaEnabled) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(window.googleCaptchaKey, { action: 'submit' }).then((token) => {
          registrationData.captcha_token = token;
          callback(registrationData);
        });
      });
    } else {
      callback(registrationData);
    }
  }

  async handleRegistrationData(response, ticketDetailsData, isCollectLater, registrationData) {
    const { data } = response;
    const ticketEntriesResponse = await this.updateFieldEntries(
      data.id,
      ticketDetailsData.ticketEntries,
      ticketDetailsData.ticketEntriesResponse,
      data.guests,
      data.edit_hash,
    );
    data.guests.forEach((guest, index) => {
      guest.tmp_id = index;
    });
    setTicketDetailsData({
      registrationId: data.id,
      ...this.setTicketEntriesData(ticketEntriesResponse),
    });
    this.props.setRegistrationData(data);
    this.handleRegistrationHashData(data);

    if (data.payable_amount > 0 && registrationData.mode !== 'offline' && !isCollectLater) {
      processPayment(
        data.payment_config,
        this.handlePaymentSuccess,
        this.handlePaymentFailed,
        this.handleStripePopupClose,
        this.handleProcessStarted,
      );
    } else {
      this.redirectToConfirmation();
    }
  }

  handleTicketError(err, tickets) {
    if (tickets[0].constructor === Object) {
      forEach(tickets, (t) => t?.non_field_errors && message.error(t.non_field_errors));
    } else {
      message.error(err.response?.data?.tickets[0]);
    }
    this.setState({ loading: false });
  }

  createOrUpdateRegistration(registrationData = this.props.registrationData, isCollectLater = false) {
    const { eventData, contextData, ticketDetailsData } = this.props;
    if (!this.validateTicketDetails()) {
      this.setState({ loading: false });
      return;
    }
    registrationData.rsvp_status = 'yes';

    this.injectCaptcha(registrationData, (registrationData) => {
      registerGuest({
        registrationData,
        eventId: eventData.id,
        hashToken: contextData.registrationHash,
        invitationHash: contextData.invitationHash,
      })
        .then(async (response) => {
          this.handleRegistrationData(response, ticketDetailsData, isCollectLater, registrationData);
        })
        .catch((err) => {
          const { tickets, detail } = err.response?.data || {};

          if (err.response?.status === 400 && detail === 'Captcha Low Score') {
            initializeCaptchaV2((token) => {
              registrationData.captcha_token = token;
              registrationData.captcha_v2 = true;
              registerGuest({
                registrationData,
                eventId: eventData.id,
                hashToken: contextData.registrationHash,
                invitationHash: contextData.invitationHash,
              })
                .then(async (response) => {
                  this.handleRegistrationData(response, ticketDetailsData, isCollectLater, registrationData);
                })
                .catch((err) => {
                  const { tickets } = err.response?.data || {};

                  if (tickets.length) {
                    this.handleTicketError(err, tickets);
                  } else {
                    this.setState({ loading: false });
                  }
                });
            });
          } else if (tickets?.length) {
            this.handleTicketError(err, tickets);
          } else if (err.response?.status === 500) {
            message.error('Something went wrong, please try again later.');
            this.setState({ loading: false });
          } else if (err.response?.data?.first_name) {
            message.error(`First Name: ${err.response.data.first_name}`);
            this.setState({ loading: false });
          } else if (err.response?.data?.last_name) {
            message.error(`Last Name: ${err.response.data.last_name}`);
            this.setState({ loading: false });
          } else {
            message.error(err.response?.data);
            this.setState({ loading: false });
          }
        });
    });
  }

  handlePaymentSuccess = () => {
    this.redirectToConfirmation();
  };

  handlePaymentFailed = () => {
    this.setState({ loading: false });
    openNotificationWithIcon({
      type: 'error',
      message: 'Payment unsuccessful',
      description: 'Sorry, your payment failed. No charges were made.',
    });
  };

  handleStripePopupClose = () => {
    const { isPaymentStarted } = this.state;
    if (!isPaymentStarted) {
      this.setState({
        loading: false,
        paymentCollectLaterLoading: false,
      });
    }
  };

  handleProcessStarted = () => {
    this.setState({ isPaymentStarted: true });
  };

  handleRegistrationHashData(data) {
    if (data.edit_hash) {
      const { setContextData } = this.props;
      setContextData({
        registrationHash: data.edit_hash,
      });
      this.setState({ registrationHashLink: `?r=${data.edit_key}` });
    }
  }

  redirectToConfirmation() {
    const { registrationHashLink } = this.state;
    const { eventData, history } = this.props;
    let query = registrationHashLink;
    const addGuestParam = getUrlParam('add_guest');
    const fromAdminParam = getUrlParam('from_admin');

    if (addGuestParam === 'true') {
      query = `${query}&add_guest=true`;
    }
    if (fromAdminParam === 'true') {
      query = `${query}&from_admin=true`;
    }

    history.push({
      pathname: CONFIRMATION_VIEW_URL.replace(':eventSlug', eventData.slug),
      search: query,
    });
  }

  getButtonText(payableAmount) {
    const { isCheckout, contextData } = this.props;
    if (isCheckout) {
      if (payableAmount > 0) {
        return (
          <span>
            <span>Pay&nbsp;</span>
            <span dangerouslySetInnerHTML={{ __html: contextData.currencyEntity }} />
            <span>{payableAmount}</span>
          </span>
        );
      }
      return 'Complete Registration';
    }
    return 'Close';
  }

  render() {
    const { registrationData, isCheckout, contextData, eventData, handleCancel } = this.props;
    const { isAdminEditingGuest, isAdminAddingGuest, currencyEntity } = contextData;
    const { discount, mandatoryTickets, otherTickets, cartSubTotal, showDiscountModal } = this.state;
    const { code } = discount;
    const { loading, paymentCollectLaterLoading } = this.state;
    const payableAmount = this.getPayableAmount(registrationData);
    return (
      <ThemeXModal
        modalTitle={<ModalHeader eventName={eventData.name} />}
        visible
        handleCancel={handleCancel}
        wrapClassName="cart-modal"
        closable={!loading}
        width="28rem">
        <div id="cart-modal-body">
          <If condition={registrationData.guests.length > 1}>
            <RegistrationItemSection title="Guest Details">
              {registrationData.guests.map((guest, index) => (
                <div className="guest-item">
                  <p className="arc-p">{`${index + 1}. ${guest.first_name} ${guest.last_name || ''}`}</p>
                  <If condition={index !== 0}>
                    <Popconfirm onConfirm={() => this.deleteGuest(index)} title="Are you sure wanted to delete this?">
                      <Button
                        type="link"
                        className="link-btn arc-focus-outline"
                        aria-label={`Delete ${guest.first_name} ${guest.last_name || ''}`}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </Popconfirm>
                  </If>
                </div>
              ))}
            </RegistrationItemSection>
          </If>
          <RegistrationTicketSection
            mandatoryTickets={mandatoryTickets}
            otherTickets={otherTickets}
            guestCount={registrationData.guests.length}
            currencyEntity={currencyEntity}
            showEdit
            handleEditAction={(ticketId) => handleCancel(ticketId)}
          />
          <If condition={eventData.is_discounts_available}>
            <RegistrationDiscountSection
              discountCode={code}
              removeDiscount={() => this.handleDiscountRemove()}
              toggleDiscountModal={() => this.toggleDiscountModal()}
            />
          </If>
          <RegistrationGiftSection gift={registrationData.gift} currencyEntity={currencyEntity} />
        </div>
        <div>
          <RegistrationCartSection
            currencyEntity={currencyEntity}
            cartSubTotal={cartSubTotal}
            discountValue={discount.amount}
            paidAmount={registrationData.paid_amount}
            payableAmount={payableAmount}
            isRefundEnabled={eventData.is_refund_enabled}
          />
          <div id="g-recaptcha" className="g-recaptcha" data-size="invisible" />
          <Choose>
            <When
              condition={
                (isAdminEditingGuest ||
                  isAdminAddingGuest ||
                  window.isSiteAdmin ||
                  window.isGlobalEventAdmin ||
                  window.isEventAdmin) &&
                isCheckout &&
                payableAmount > 0
              }>
              <ButtonGroup id="admin-payment-actions">
                <Button
                  size="large"
                  className="arc-btn-yellow arc-modal-footer-full-width-btn left"
                  disabled={loading}
                  loading={paymentCollectLaterLoading}
                  onClick={() => this.handlePaymentCollectionLater(payableAmount)}>
                  <span>Collect payment later</span>
                  <Tooltip title="This option is visible to you because you are an administrator. A regular user will only see Pay Online option">
                    <FontAwesomeIcon icon={faInfoCircle} className="ml8" />
                  </Tooltip>
                </Button>
                <Dropdown
                  placement="topCenter"
                  disabled={loading}
                  overlay={
                    <Menu>
                      <Menu.Item key="pay" onClick={() => this.handleOnlinePayment()} id="initiate-payment-button">
                        Pay Online
                      </Menu.Item>
                      <Menu.Item key="record" onClick={() => this.recordOfflinePayment()}>
                        Record an offline payment
                      </Menu.Item>
                    </Menu>
                  }>
                  <Button
                    disabled={paymentCollectLaterLoading}
                    size="large"
                    type="primary"
                    className="arc-modal-footer-full-width-btn right"
                    loading={loading}>
                    Record a payment
                    <Tooltip title="This option is visible to you because you are an administrator. A regular user will only see Pay Online option">
                      <FontAwesomeIcon icon={faInfoCircle} className="ml8" />
                    </Tooltip>
                  </Button>
                </Dropdown>
              </ButtonGroup>
            </When>
            <Otherwise>
              <Button
                size="large"
                id="initiate-payment-button"
                className="arc-modal-footer-full-width-btn"
                type="primary"
                onClick={() => this.handleClick()}
                loading={loading}>
                {this.getButtonText(payableAmount)}
              </Button>
            </Otherwise>
          </Choose>
        </div>
        <If condition={showDiscountModal}>
          <DiscountModal
            cartSubTotal={cartSubTotal}
            appliedCode={discount.code}
            getDiscount={(discountL, privateDiscount) => this.validateAndApplyDiscount(discountL, privateDiscount)}
            handleClose={() => this.toggleDiscountModal()}
            currencyEntity={currencyEntity}
          />
        </If>
      </ThemeXModal>
    );
  }
}

CartModal.propTypes = {
  handleCancel: func.isRequired,
  isCheckout: bool,
};

CartModal.defaultProps = {
  isCheckout: false,
};

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
  ticketDetailsData: state.ticketDetailsDataReducer.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  setContextData: (data) => dispatch(setContextData(data)),
  setTicketDetailsData: (data) => dispatch(setTicketDetailsData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartModal));
