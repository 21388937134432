import { faCheck, faClose, faWrench } from '@fortawesome/pro-light-svg-icons';
import { faArrowsRotate, faEnvelope, faEye, faGear, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import {
  TEXT_VALUE,
  CHECKBOX_VALUE,
  DROPDOWN_VALUE,
  DROPDOWN_RANGE_VALUE,
  RADIO_VALUE,
  GOOGLE_LOCATION_VALUE,
} from 'Src/adminFormsX/constants';

export const OPEN_TICKET = 'open';
export const MANDATORY_TICKET = 'mandatory';
export const SEATED_TICKET = 'seated';
export const SPONSORED_TICKET = 'sponsored';

export const EVENT_CREATE_MANAGER = 'event_create_manager';
export const EVENT_EDIT_MANAGER = 'event_edit_manager';
export const EVENT_COMMUNICATION_MANAGER = 'event_communication_manager';
export const EVENT_GUEST_MANAGER = 'event_guest_manager';
export const EVENT_REPORT_MANAGER = 'event_report_manager';
export const EVENT_EXPORT_MANAGER = 'event_export_manager';
export const EVENT_GUEST_KIT_MANAGER = 'event_guest_kit_manager';
export const EVENT_APPROVAL_MANAGER = 'event_approval_manager';
export const EVENT_CONNECTIONS_MANAGER = 'event_connection_manager';
export const EVENT_SYNC_AND_EXPORT_MANAGER = 'event_sync_and_export_manager';
export const EVENT_REPORT_PRINT_MANAGER = 'event_report_print';
export const EVENT_LABELS_MANAGER = 'event_labels_manager';
export const EVENT_PERMISSION_MANAGER = 'event_permission_manager';
export const EVENT_GUEST_INVITE = 'event_guest_invite';

export const PENDING_REGISTRATION_PAYMENT_STATUS = 'pending_payment';

export const TAX_RECEIPT_NOTIFICATION_RULE = {
  NO_NOTIFICATION: 'no_notification',
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};

export const TABS = [
  {
    tab: 'Settings',
    key: [EVENT_EDIT_MANAGER],
  },
  {
    tab: 'Invitees',
    key: [EVENT_GUEST_INVITE],
  },
  {
    tab: 'Guests',
    key: [EVENT_GUEST_MANAGER],
  },
  {
    tab: 'Guest kit',
    key: [EVENT_GUEST_KIT_MANAGER],
  },
  {
    tab: 'Guest communication',
    key: [EVENT_COMMUNICATION_MANAGER],
  },
  {
    tab: 'Reports',
    key: [EVENT_REPORT_MANAGER],
  },
  {
    tab: 'Label customisation',
    key: [EVENT_LABELS_MANAGER],
  },
  {
    tab: `${window.isSiteAdmin || window.isGlobalEventAdmin ? 'Sync and Exports' : 'Exports'}`,
    key: [EVENT_EXPORT_MANAGER],
  },
  {
    tab: 'Connections',
    key: [EVENT_CONNECTIONS_MANAGER],
  },
];

export const COVER_IMAGE = 'cover_image';
export const LOGO_IMAGE = 'logo_image';

export const TICKET_TYPE_DESCRIPTION_MAPPING = {
  mandatory: {
    text: `Tickets that are required for all 
    guests like an entry fee. The ticket quantity adjusts automatically 
    based on how many guests are specified by the buyer.`,
    image_name: 'mandatory-ticket.jpg',
  },
  open: {
    text: `Tickets that can be 
    bought in any quantity by the buyer, like 
    T-shirts or Gift Tickets.`,
    image_name: 'open_ticket.jpg',
  },
  seated: {
    text: `Tickets that can only be allocated to guests, like a meal ticket. 
    The buyer is required to specify the guests 
    that need this ticket.`,
    image_name: 'seated_ticket.jpg',
  },
  sponsored: {
    text: `Tickets that allow the buyer to specify the 
    names of their guests later. Ideal for 
    team based events or sponsored tickets`,
    image_name: 'sponsored_ticket.jpg',
  },
};

export const GUEST_FILTERS = {
  ATTENDING: 'registration__rsvp_status=yes&registration__is_registration_approved=true',
  NOT_ATTENDING: 'registration__rsvp_status=no',
  MIGHT_ATTEND: 'registration__rsvp_status=maybe&registration__is_registration_approved=true',
  PENDING_PAYMENT: 'registration__registration_status=pending_payment',
  PAYMENT_INITIATED: 'registration__registration_status=payment_initiated',
  PAYMENT_FAILED: 'registration__registration_status=payment_failed',
  UNMAPPED: 'is_mapped=False&registration__rsvp_status=yes&is_profile_mapping_ignored=False',
  IGNORED: 'is_profile_mapping_ignored=True',
  CHECK_IN: 'checked_in=True',
};

export const PRIVACY_SETTINGS = {
  PUBLIC: 'public',
  REGISTERED_GUESTS_ONLY: 'registered_guests_only',
};

export const REGISTRATION_STATUSES = {
  STATUS_PENDING_PAYMENT: 'pending_payment',
  STATUS_SUCCESSFUL: 'registration_successful',
  STATUS_CANCELLED: 'registration_cancelled',
};

export const EMAIL_STATUS = {
  ALMABASEAPPROVALPENDING: 5,
  APPROVALPENDING: 10,
  CANCELLED: 8,
  DISCARDED: 3,
  DRAFT: 9,
  FAILED: 11,
  HIDE: 1,
  RECEIVED: 4,
  SCHEDULED: 7,
  SENDING: 6,
  SENT: 2,
};

export const TEMPLATES_SECTION = 'guest-communication-events';

// Almasights touchpoints
export const TOUCHPOINTS = {
  MAPPED_GUEST: 'events-mapped-a-guest',
  UNMAPPED_GUEST: 'events-unmapped-a-guest',
  IGNORED_GUEST: 'events-ignored-a-guest',
};

export const FORM_PURPOSE_EVENT_SYNC_WORKFLOWS = 11;

// Slug for internal field that is generated by system for workflow trigger event form
export const EVENT_ID_FIELD_SLUG = 'event_id__custom';
export const EVENT_SHOULD_UPDATE_FIELD_SLUG = 'event_should_update__custom';
export const EVENT_REGISTRATIONS_FIELD_SLUG = 'event_registrations__custom';
export const EVENT_TICKETS_FIELD_SLUG = 'event_tickets__custom';

export const SYNC_CONFIGURATION_KB =
  'https://docs.almabase.com/en/articles/6338285-events-sync-from-almabase-to-raiser-s-edge#h_8a435ba5a7';

export const VALID_FIELD_TYPE_FOR_GUEST_DESCRIPTOR = [
  TEXT_VALUE,
  CHECKBOX_VALUE,
  DROPDOWN_VALUE,
  DROPDOWN_RANGE_VALUE,
  RADIO_VALUE,
  GOOGLE_LOCATION_VALUE,
];

// Sub Event Criterias
export const CRITERIA_NO_CRITERIA = 'no_criteria';
export const CRITERIA_AFFILIATION = 'affiliation';
export const CRITERIA_CUSTOM = 'custom';
export const EVENTSX_SYNC_ERROR_EVENT_DUPLICATE_NAME = 'event_duplicate_name';
export const EVENTSX_SYNC_ERROR_EVENT_INVALID_CONNECTED_USER = 'event_invalid_connected_user';
export const EVENTSX_SYNC_ERROR_EVENT_MISSING_DATE = 'event_missing_date';
export const EVENTSX_SYNC_ERROR_TICKET_MISSING_FUND_ID = 'ticket_missing_fund_id';
export const EVENTSX_SYNC_ERROR_FUND_MISSING_EXTERNAL_DATABASE_ID = 'fund_missing_external_database_id';
export const EVENTSX_SYNC_ERROR_FUND_INVALID_EXTERNAL_DATABASE_ID = 'fund_invalid_external_database_id';
export const EVENTSX_SYNC_WARNING_UNMAPPED_HOST = 'event_unmapped_host';
export const EVENTSX_SYNC_WARNING_UNMAPPED_GUEST = 'event_unmapped_guest';
export const EVENTSX_SYNC_WARNING_GUEST_MISSING_EXTERNAL_DATABASE_ID = 'event_guest_missing_external_database_id';
export const EVENTSX_SYNC_ERROR_UNSPECIFIED_PARTICIPANT_LEVEL = 'event_unspecified_participant_level';
export const EVENTSX_SYNC_ERROR_INVALID_EXTERNAL_DATABASE_ID = 'event_invalid_external_database_id';
export const EVENTSX_SUB_EVENT_SYNC_ERROR_EVENT_DUPLICATE_NAME = 'sub_event_duplicate_name';
export const EVENTSX_SUB_EVENT_SYNC_ERROR_EVENT_MISSING_DATE = 'sub_event_missing_date';

export const SYNC_ERROR_MAPPING = {
  [EVENTSX_SYNC_ERROR_EVENT_DUPLICATE_NAME]: {
    title: 'An event with this name already exists on your database',
    description: 'Modify the event name to resolve this issue.',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
  [EVENTSX_SYNC_ERROR_EVENT_MISSING_DATE]: {
    title: 'Start and end date have not been set for this event',
    description: 'Both these are mandatory fields to sync data. Update them to resolve this issue.',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
  [EVENTSX_SYNC_ERROR_EVENT_INVALID_CONNECTED_USER]: {
    title: 'The selected account does not have permission to sync to your database',
    description: 'Change to a different user with appropriate permissions to resolve this issue.',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
  [EVENTSX_SYNC_ERROR_INVALID_EXTERNAL_DATABASE_ID]: {
    title: 'Provided External Database ID is invalid',
    description: 'Enter a valid external database id to resolve this error',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
  [EVENTSX_SYNC_ERROR_TICKET_MISSING_FUND_ID]: {
    title: '{count_and_item} on this event {helping_verb} not associated with a fund',
    description:
      "Tickets that aren't associated with a fund can not be pushed to the database. Add fund to fix this issue.",
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: 'ticket',
    helpingVerb: 'is',
  },
  [EVENTSX_SYNC_ERROR_FUND_MISSING_EXTERNAL_DATABASE_ID]: {
    title: '{count_and_item} associated to tickets {helping_verb} missing  fund ID',
    description: 'Add the System Record ID for these funds to fix this issue',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: 'fund',
    helpingVerb: 'is',
  },
  [EVENTSX_SYNC_ERROR_FUND_INVALID_EXTERNAL_DATABASE_ID]: {
    title: '{count_and_item} associated to tickets {helping_verb} invalid System Record ID',
    description: 'Add the correct System Record ID for these funds to fix this issue',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: 'fund',
    helpingVerb: 'has',
  },
  [EVENTSX_SYNC_ERROR_UNSPECIFIED_PARTICIPANT_LEVEL]: {
    title: 'Participation level is not specified',
    description: 'Select participation level.',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
  [EVENTSX_SYNC_WARNING_UNMAPPED_HOST]: {
    title: '{count_and_item} {helping_verb} not mapped to a record',
    description:
      "If a host is unmapped, the host and all accompanying guests on that registration won't be synced to your database.",
    actionText: 'Map Records',
    actionIcon: faWrench,
    type: 'warning',
    item: 'host',
    helpingVerb: 'is',
  },
  [EVENTSX_SYNC_WARNING_GUEST_MISSING_EXTERNAL_DATABASE_ID]: {
    title: '{count_and_item} {helping_verb} missing constituent ID',
    description:
      "Update these records with the corresponding constituent's system record ID from your database to fix this issue.",
    actionText: 'Fix Records',
    actionIcon: faWrench,
    type: 'warning',
    item: 'mapped guest',
    helpingVerb: 'is',
  },

  [EVENTSX_SYNC_WARNING_UNMAPPED_GUEST]: {
    title: '{count_and_item} {helping_verb} not mapped to a record',
    description:
      'You can choose to map and sync, leave them unmapped but sync as participant, or not sync their details at all.',
    actionText: 'Fix Records',
    actionIcon: faWrench,
    type: 'warning',
    item: 'accompanying guest',
    helpingVerb: 'is',
  },
  [EVENTSX_SUB_EVENT_SYNC_ERROR_EVENT_DUPLICATE_NAME]: {
    title: 'An sub event with name "{count_and_item}" already exists on your database',
    description: 'Modify sub event name to resolve this issue.',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
  [EVENTSX_SUB_EVENT_SYNC_ERROR_EVENT_MISSING_DATE]: {
    title: 'Start and end date have not been set for "{count_and_item}" sub event',
    description: 'Both these are mandatory fields to sync data. Update them to resolve this issue.',
    actionText: 'Fix Issue',
    actionIcon: faWrench,
    type: 'error',
    item: '',
    helpingVerb: '',
  },
};

export const TICKET_CONFIG_OPTIONS = {
  all_tickets_one_gift: 'All payments(tickets and gifts) are pushed as one gift.',
  non_gift_ticket_gifts_and_other_tickets_gift: 'Ticket payments and Gifts are pushed as separate gifts.',
  all_tickets_separate_gifts: 'Each ticket payment and gifts are pushed as individual gifts.',
  only_rsvp_status: 'Only RSVP Status for Guests.',
  only_gift_tickets: 'Only gifts (via gift tickets) are pushed.',
};

export const JUNE_EVENT_NAMES = {
  managePermissions: "Clicked 'Manage Permissions' in an event",
  requestedUpgrade: 'Requested Upgrade',
  guestInviteTab: 'Visited "Guest Invite" tab',
  inviteGuestsBtn: 'Clicked on "Invite guests" button',
  addInviteIcon: 'Clicked the "Add" icon to include in the invite list',
  emailInviteesViaAcc: 'Clicked on "Email Invitees" button (via ACC)',
  exportInviteeList: 'Clicked on "Export invitee" list',
  sendOutInvitations: 'Clicked on "Send out invitations" button',
  copyPersonalisedLink: 'Copied Personalised Event Invite Link',
  designFromScratch: 'Clicked on "Design email from scratch"',
  chooseTemplate: 'Clicked on "Choose a template"',
  duplicateEmail: 'Clicked on "Duplicate above email"',
  startAutomation: 'Clicked on Start Automation (on confirmation modal)',
  stopEditRegistration: 'Turned off ‘Allow Edit’ toggle',
};

export const SUB_EVENT_TICKET_CARD_ID = 'sub-event-ticket-card';
export const TARGETED_SUB_EVENT_VALIDATION_TYPE = 'sub_event__targeted_sub_event';
export const SUB_EVENT_GUEST_LIMIT_VALIDATION_TYPE = 'sub_event__guest_limit';
export const ACCESS_CONTROL_ACTION = 'setup_access_control';
export const UPGRADE_EVENTS_LANDING_PAGE_URL = 'https://www.almabase.com/lp/multiday-events#features';
export const UPGRADE_EVENTS_DEMO_URL = 'https://meetings.hubspot.com/sai-siddarth/manage-complex-multi-day-events';
export const UPGRADE_GIVING_LANDING_PAGE_URL = 'https://www.almabase.com/products/crowdfunding';
export const UPGRADE_GIVING_DEMO_URL = 'https://meetings.hubspot.com/sai-siddarth/online-giving';
export const GETTING_STARTED_WITH_EVENT_INVITES =
  'https://docs.almabase.com/en/articles/9394341-how-can-i-send-personalized-email-invites-to-potential-event-guests';

export const INVITEE_STATUS = {
  ALL: 'all_invitees',
  PENDING: 'invitation_pending',
  SENT: 'invitation_sent',
  ACCEPTED: 'invitation_accepted',
  DECLINED: 'invitation_declined',
};

export const WORKFLOW_STATUS_MAP = {
  Draft: { icon: faPenToSquare, text: 'Continue editing' },
  Active: { icon: faGear, text: 'Configure' },
  Paused: { icon: faArrowsRotate, text: 'Restart' },
  Completed: { icon: faEye, text: 'View details' },
};

export const DEFAULT_SCREEN_INFO_TABS = [
  {
    imgSrc: `${window.staticPath}img/events/guest_invite/default_screen/create_list.svg`,
    heading: 'Create invitee list',
    text: 'Choose email groups or individual invitees to create your list',
  },
  {
    imgSrc: `${window.staticPath}img/events/guest_invite/default_screen/unique_invitation.svg`,
    heading: 'Unique invitation link',
    text: 'Share the invite link to the invitees',
  },
  {
    imgSrc: `${window.staticPath}img/events/guest_invite/default_screen/automate_invite.svg`,
    heading: 'Automate invitation emails',
    text: 'Schedule multiple emails to invite members to your event',
  },
];

export const STEP_STATUS_MAPPER = {
  Expired: { icon: faClose, text: `Email couldn't be sent` },
  Ready: { icon: faEnvelope, text: 'Email goes out on' },
  Sent: { icon: faCheck, text: 'Email sent on' },
};

export const EVENT_TYPE_OPTIONS = [
  { value: 1, label: 'Open' },
  { value: 2, label: 'Moderated' },
  { value: 3, label: 'Invite-only' },
];

export const DISCOVERABILITY_OPTIONS = [
  { value: true, label: 'Shown' },
  { value: false, label: 'Hidden' },
];

export const MODERATED_EVENT = 2;
export const INVITE_ONLY_EVENT = 3;
