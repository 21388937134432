import React from 'react';
import { string, func } from 'prop-types';
import { Button } from 'antd';

import RegistrationItemSection from './../index';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

const RegistrationDiscountSection = ({ discountCode, toggleDiscountModal, removeDiscount }) => {
  return (
    <RegistrationItemSection title="Discounts">
      <div className="arc-d-flex arc-flex-justify-center arc-flex-vertical-align">
        <Button
          type="link"
          className="link-btn arc-focus-outline"
          id="discount-selection-block"
          onClick={() => toggleDiscountModal()}>
          {discountCode || 'Add discount'}
        </Button>
        <If condition={discountCode}>
          <FontAwesomeIcon className="arc-cursor-p ml8" icon={faTrashAlt} onClick={() => removeDiscount()} />
        </If>
      </div>
    </RegistrationItemSection>
  );
};

RegistrationDiscountSection.propTypes = {
  toggleDiscountModal: func.isRequired,
  removeDiscount: func.isRequired,
  discountCode: string,
};

RegistrationDiscountSection.defaultProps = {
  title: string.isRequired,
};

export default RegistrationDiscountSection;
