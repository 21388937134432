import React from 'react';
import { getFormattedDate } from 'Src/common/utilities/moment_util';

function DateBlock({ label, dateTime, timezone }) {
  const dayString = dateTime ? getFormattedDate(dateTime, 'dddd,') : null;
  const dateString = dateTime ? getFormattedDate(dateTime, 'DD MMM YYYY') : null;
  const timeString = dateTime ? `${getFormattedDate(dateTime, 'hh:mm a')} ${timezone}` : null;

  return (
    <div className="date-block">
      <p className="arc-H100 arc-color-B55">{label}</p>
      <p className="arc-H300 arc-color-B85 mt8 time-label">{timeString}</p>
      <p className="arc-p arc-color-B85 mt8">{dayString}</p>
      <p className="arc-p arc-color-B85">{dateString}</p>
    </div>
  );
}

export default DateBlock;
