/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import FormLabelAlongTooltip from 'Src/common/components/formLabelAlongTooltip';
import { getKeyValue, validateEmail, validateEmailByApi } from 'Src/common/utilities/data_util';

const FormItem = Form.Item;

class EmailInput extends Component {
  // pass property validateUsingApi to validate email with api
  constructor(props) {
    super(props);
    this.state = {};
    this.messages = {
      SUCCESS: {
        validateStatus: 'success',
        help: '',
      },
      WARNING: {
        validateStatus: 'warning',
        help: 'Was that a typo? Did you mean ',
      },
      INVALID_ERROR: {
        validateStatus: 'error',
        help: props.formItem.invalidMessage || "Was that a typo? That doesn't seem to be a valid email",
      },
      REQUIRED_ERROR: {
        validateStatus: 'error',
        help: props.formItem.requiredMessage || 'Please enter your email',
      },
    };
  }

  static defaultProps = {
    form: {},
    formItem: {},
    formRecord: {},
  };

  static propTypes = {
    form: PropTypes.object,
    formItem: PropTypes.object,
    formRecord: PropTypes.object,
  };

  /*
  - If a formRecord exists, sets the email
  */
  componentDidMount() {
    const { form } = this.props;
    const { formItem } = this.props;
    const { formRecord } = this.props;
    // Get the email if the component is showing existing data
    const email = getKeyValue(formRecord, formItem.key, null);
    form.setFieldsValue({ [formItem.key]: email });
  }

  validateEmailUsingApi(rule, value) {
    /*
      Check the email address -> if api returns email as invalid or risky we show warning message
      If api returns suggested email, we will show that email to user as well
    */

    validateEmailByApi(value)
      .then((response) => {
        const { suggested_email, is_valid, is_risky, is_possible_typo } = response.data;
        if (is_possible_typo) {
          // the assumption here is that if is_possible_typo is true, there will be suggested_email
          this.setState({ help: `${this.messages.WARNING.help}${suggested_email}`, validateStatus: 'warning' });
        } else if (!is_valid || is_risky) {
          this.setState({ help: this.messages.INVALID_ERROR.help, validateStatus: 'warning' });
        } else {
          this.setState(this.messages.SUCCESS);
        }
      })
      .catch(() => {
        this.setState(this.messages.SUCCESS);
      });
  }

  validateEmail(rule, value, callback) {
    if (value) {
      const email = value.trim();
      if (!validateEmail(email)) {
        this.setState(this.messages.INVALID_ERROR);
      } else {
        if (this.props.validateUsingApi) {
          this.setState({ validateStatus: 'validating' });
          this.validateEmailUsingApi(rule, email);
        } else this.setState(this.messages.SUCCESS);
        callback();
      }
    } else if (this.props.formItem.required) {
      this.setState(this.messages.REQUIRED_ERROR);
    } else {
      this.setState(this.messages.SUCCESS);
      callback();
    }
  }

  renderFormItemLabel(formItem) {
    const tooltipText = getKeyValue(formItem, 'tooltipText');
    if (tooltipText) {
      return <FormLabelAlongTooltip label={formItem.label} tooltipText={tooltipText} />;
    }
    return formItem.label;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formItem } = this.props;

    return (
      <div id="email-input-box">
        <FormItem
          {...formItem.layout}
          label={this.renderFormItemLabel(formItem)}
          validateStatus={this.state.validateStatus || 'success'}
          help={this.state.help || ''}
          // hasFeedback
          validateTrigger="onBlur">
          {getFieldDecorator(formItem.key, {
            rules: [
              {
                validator: this.validateEmail.bind(this),
              },
              {
                required: formItem.required || false,
              },
            ],
            validateTrigger: 'onBlur',
          })(
            <Input
              autoComplete="email"
              placeholder={getKeyValue(formItem, 'placeholder')}
              disabled={getKeyValue(formItem, 'disabled', false)}
              readOnly={getKeyValue(formItem, 'readOnly', false)}
              addonAfter={getKeyValue(formItem, 'addonAfter', null)}
            />,
          )}
        </FormItem>
      </div>
    );
  }
}

export default EmailInput;
