const SET_TICKET_DETAILS_DATA = 'EVENT/SET_TICKET_DETAILS_DATA'
const eventSlug = window.eventData.slug;

const registrationData = window.registrationData;

/** 
 * method to initialize the redux state with local-storage in case there is new registration 
*/
function getInitialState() {
  if (!registrationData && Storage !== void (0) && localStorage.getItem(`${eventSlug}+${SET_TICKET_DETAILS_DATA}`)) {
    return JSON.parse(localStorage.getItem(`${eventSlug}+${SET_TICKET_DETAILS_DATA}`));
  }
  return {}
}

const initialState = {
  data: getInitialState(),
}

export const setTicketDetailsData = (data) => {
  return {
    type: SET_TICKET_DETAILS_DATA,
    payload: data
  }
}

const ticketDetailsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TICKET_DETAILS_DATA:

      // Update local storage until registration has no ID (which mean registration not created or exist)
      if (action.payload && !action.payload.registrationId && Storage !== void (0)) {
        localStorage.setItem(`${eventSlug}+${SET_TICKET_DETAILS_DATA}`, JSON.stringify(action.payload));
      }
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default ticketDetailsDataReducer;