import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { CURRENCIES } from '../common/constants';
import { getCurrency, getUrlParam } from '../common/utilities/data_util';
// === REDUX === //
import { setRegistrationData } from './reducers/registrationDataReducer';
import { setEventData } from './reducers/eventDataReducer';
import { setContextData } from './reducers/contextDataReducer';

import { getMandatoryTickets } from './utils';
import { find, groupBy, keys } from 'lodash';
import { OPEN_TICKET, SEATED_TICKET, SPONSORED_TICKET } from './constants';
import { setTicketDetailsData } from './reducers/ticketDetailsDataReducer';

const addGuestParam = getUrlParam('add_guest');
const isAdminEditingRegistration = getUrlParam('registered_by_admin');
const editHash = getUrlParam('r');

class ContextWrapper extends React.Component {
  /**
   * If user is event admin and addGuestParam === true
   * * * which mean admin is adding guest
   * else
   * * * if registration data is there, and url has edit hash or user is logged in
   * * * * set registration data and edit mode = true
   */
  componentWillMount() {
    const isUserEventAdmin = window.isEventAdmin;
    let isAdminEditingGuest = false;
    let isAdminAddingGuest = false;
    let isEditMode = false;
    let { registrationData } = this.props;
    if (isUserEventAdmin && addGuestParam === 'true') {
      isAdminAddingGuest = true;
      this.props.setRegistrationData(registrationData);
    } else if (window.registrationData && (editHash || window.userData)) {
      const data = window.registrationData;
      // eslint-disable-next-line no-return-assign,no-param-reassign
      data.guests.map((guest, index) => (guest.tmp_id = index));
      registrationData = data;
      this.props.setRegistrationData(data);
      isEditMode = true;
    }

    if (isUserEventAdmin && editHash && window.registrationData && isAdminEditingRegistration === 'true') {
      // TODO: handle if event admin open his edit link in that case he is not editing the guest
      // isAdminEditingGuest = false;
      isAdminEditingGuest = true;
    }

    this.props.setEventData(window.eventData);
    this.setInitialContextData(isAdminEditingGuest, isAdminAddingGuest, isEditMode, isUserEventAdmin, registrationData);
    this.setTicketEntriesData();
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    const loader = document.getElementById('loader');
    if (loader) {
      loader.parentNode.removeChild(loader);
    }
  }

  setTicketEntriesData = () => {
    const { eventData, registrationData } = window;
    const getTicketOptionValue = (tickteData, ticketFieldId, choiceId) => {
      const reqTicketField = find(tickteData.fields, (field) => field.id === ticketFieldId);
      if (reqTicketField) {
        return find(reqTicketField['choices'], (choice) => choice.id === choiceId)?.name;
      }
    };
    if (eventData && registrationData) {
      if (eventData.ticket_bought_details) {
        const ticketEntries = eventData.ticket_bought_details;
        const result = {};
        const groupByEntries = groupBy(ticketEntries, 'ticket_bought.ticket.id');
        for (let ticketId of keys(groupByEntries)) {
          const reqTicket = find(eventData.tickets, ({ id }) => id === parseInt(ticketId));
          if (reqTicket.ticket_type === OPEN_TICKET) {
            let temp = {};
            for (let entry of groupByEntries[ticketId]) {
              Object.assign(temp, {
                [getTicketOptionValue(
                  reqTicket,
                  entry['entries'][0]['ticket_field']['id'],
                  entry['entries'][0]['selected_choice']['id'],
                )]: entry.quantity,
              });
            }
            Object.assign(result, { [ticketId]: temp });
          } else if ([SEATED_TICKET, SPONSORED_TICKET].includes(reqTicket.ticket_type)) {
            let temp = {};
            for (let entry of groupByEntries[ticketId]) {
              Object.assign(temp, {
                [`id__${entry.guest.id}`]: getTicketOptionValue(
                  reqTicket,
                  entry['entries'][0]['ticket_field']['id'],
                  entry['entries'][0]['selected_choice']['id'],
                ),
              });
            }
            Object.assign(result, { [ticketId]: temp });
          }
        }
        this.props.setTicketDetails({
          registrationId: registrationData.id,
          ticketEntries: result,
          ticketEntriesResponse: ticketEntries,
        });
      }
    }
  };

  setInitialContextData(isAdminEditingGuest, isAdminAddingGuest, isEditMode, isUserEventAdmin, registrationData) {
    const { eventData } = window;
    const currencyEntity = getCurrency(eventData.currency, CURRENCIES);
    const mandatoryTickets = getMandatoryTickets(eventData.tickets);
    const qs = queryString.parse(window.location.search);
    this.props.setContextData({
      currencyEntity,
      mandatoryTickets,
      isAdminEditingGuest,
      isAdminAddingGuest,
      rsvpStatuses: window.rsvpStatuses,
      registrationStatuses: window.registrationStatuses,
      registrationHash: window.registrationHash,
      isTicketedEvent: !!eventData.tickets.length,
      isEditMode,
      isEventAdmin: isUserEventAdmin,
      userData: window.userData,
      affiliations: window.roles || [],
      initialRegistrationData: registrationData,
      invitationHash: qs?.i || '',
    });
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
});

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  setEventData: (data) => dispatch(setEventData(data)),
  setContextData: (data) => dispatch(setContextData(data)),
  setTicketDetails: (data) => dispatch(setTicketDetailsData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextWrapper);
