import React from 'react';
import { Alert } from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

// not pretty generic but should work fro now
function LockedContent() {
  return (
    <Alert
      message={(
        <p className="arc-support arc-error-color arc-font-weight-bold">
          <I18nCustomFormatter id="locked-content-message" defaultMessage="Awaiting approval" />
        </p>
        )}
      description={(
        <p className="arc-support arc-error-color">
          <I18nCustomFormatter id="locked-content-description" defaultMessage="For security reasons, this information is only shared with approved registrations. Once your registration has been approved, information in this section will be available to you right here" />
        </p>
        )}
      type="error"
      className="arc-error-b arc-error-bg arc-error-alert-br"
    />
  );
}

export default LockedContent;
