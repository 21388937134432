import { PRIVACY_SETTINGS, RSVP_STATUSES } from 'Src/alumniEvents/constants';

export const isAllowedToViewContent = function (privacy, registration) {
  /*
        This util function decides whether to allow content to user or not.
        Currently restriction is on add a calendar, announcements and venue on guest kit
        
        If rsvp status is yes but registration is not approved - all the approved content is blocked (private announcement and venue if it is private)
        If rsvp status is yes and registration is Approved - all the content is visible
    */
  if (
    privacy === PRIVACY_SETTINGS.PUBLIC ||
    (registration.rsvp_status === RSVP_STATUSES.RSVP_YES && registration.is_registration_approved)
  ) {
    return true;
  }
  if (privacy === PRIVACY_SETTINGS.REGISTERED_GUESTS_ONLY && !registration.is_registration_approved) return false;
  return privacy === PRIVACY_SETTINGS.REGISTERED_GUESTS_ONLY;
};
