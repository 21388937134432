import React, { useState } from 'react';
import { Card } from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import LockedContent from 'Src/common/components/lockedContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTvRetro, faSync } from '@fortawesome/pro-light-svg-icons';

import './style.scss';

let isOverlayClicked = false;

function LivestreamCard({ url, isContentLocked = false }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [isLoadingLiveStream, setIsLoadingLiveStream] = useState(false);

  function getEmbeddableUrl() {
    if (url.indexOf('embed') === -1) {
      const splits = url.split('/');
      if (splits.length) {
        return `https://youtube.com/embed/${splits[splits.length - 1]}`;
      }
    }
    return url;
  }

  function loadLiveStream() {
    setIsLoadingLiveStream(true);
    setTimeout(() => {
      setIsOverlayVisible(false);
      isOverlayClicked = true;
    }, 3000);
  }

  return (
    <Card
      bordered={false}
      className="event-card livestream-card arc-card-border-radius arc-card-box-shadow"
      id="event-gallery-card"
      title={isContentLocked
        ? (
          <p className="arc-H200">
            <FontAwesomeIcon icon={faTvRetro} />
            <span className="ml8 arc-font-weight-medium">
              <I18nCustomFormatter id="event-livestream-card-title" />
            </span>
          </p>
        ) : null}
    >
      <Choose>
        <When condition={!isContentLocked}>
          <div className="iframe-wrapper">
            <If condition={isOverlayVisible && !isOverlayClicked}>
              <div className="iframe-overlay">
                <a className="arc-H300 arc-color-white" onClick={loadLiveStream}>
                  <Choose>
                    <When condition={isLoadingLiveStream}>
                      <FontAwesomeIcon icon={faSync} spin />
                    </When>
                    <Otherwise>
                      <FontAwesomeIcon icon={faTvRetro} className="mr8" />
                      Click here to watch now
                    </Otherwise>
                  </Choose>
                </a>
              </div>
            </If>
            <iframe
              type="text/html"
              src={getEmbeddableUrl()}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </When>
        <Otherwise>
          <div className="locked-content-wrapper">
            <LockedContent />
          </div>
        </Otherwise>
      </Choose>
    </Card>
  );
}

export default LivestreamCard;
