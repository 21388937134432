import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Input, Select } from 'antd';
import { string, number, func, shape, arrayOf } from 'prop-types';

import { getFormEntryRecord } from 'Src/common/utilities/form_utils';
import CustomFormItem from 'Src/common/components/customFormItem';
import './style.scss';

const FormItem = Form.Item;

function GuestFormCard({ form, record, index, fields, title, affiliations, validateGuestsEmail, eventData }) {
  const { getFieldDecorator } = form;
  const formEntries = (record.form_entry && record.form_entry.field_entries) || [];

  return (
    <Card title={title} className="guest-form-card regular-form">
      <p className="required-field-text">
        <span className="arc-color-error">*</span>
        {' '}
        Indicates a required field
      </p>
      <FormItem label="First Name">
        {getFieldDecorator(`first_name@_@${index}`, {
          initialValue: record.first_name,
          rules: [
            {
              required: true,
              message: 'Please enter first name',
            },
          ],
        })(<Input />)}
      </FormItem>
      <FormItem label="Last Name">
        {getFieldDecorator(`last_name@_@${index}`, {
          initialValue: record.last_name,
        })(<Input />)}
      </FormItem>
      <FormItem label="Email">
        {getFieldDecorator(`email@_@${index}`, {
          initialValue: record.email,
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              validator: validateGuestsEmail,
            },
          ],
          validateTrigger: 'onBlur',
        })(<Input disabled={index === 0} />)}
      </FormItem>
      <If condition={eventData.should_collect_affiliation}>
        <FormItem label="Affiliations">
          {getFieldDecorator(`affiliation@_@${index}`, {
            initialValue: record.affiliations?.map((affiliation) => affiliation.id),
          })(
            <Select
              className="guest-affiliation-select"
              placeholder="Alumni"
              mode="multiple"
              allowClear
              aria-expanded="true"
              style={{ width: '100%' }}
              direction="vertical"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {affiliations.map((affiliation) => (
                <Select.Option value={affiliation.id} key={affiliation.id}>
                  {affiliation.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
      </If>
      {fields.map((item) => {
        const field = { ...item };
        field.key = `${field.key}@_@${index}`;
        field.condition_field = field.condition_field && `${field.condition_field}@_@${index}`;
        return (
          <CustomFormItem
            form={form}
            formInput={field}
            formRecord={getFormEntryRecord(formEntries, field.id, field.type, field.key)}
            key={field.id}
            metadata={{
              source_type: 'customform',
              source_id: eventData && eventData.guest_form && eventData.guest_form.id,
              access_level: 'protected',
            }} />
        );
      })}
    </Card>
  );
}

GuestFormCard.propTypes = {
  form: shape(),
  index: number.isRequired,
  title: string.isRequired,
  fields: arrayOf(shape()),
  affiliations: arrayOf(shape()),
  validateGuestsEmail: func.isRequired,
  eventData: shape(),
};

GuestFormCard.defaultProps = {
  form: {},
  fields: [],
  affiliations: [],
  eventData: {},
};

export default GuestFormCard;
