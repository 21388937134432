import { faMinus, faPlus, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Input, Button } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const COUNTER_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

export function OpenTicketOptions({ optionsData, fields, callback }) {
  const [data, setData] = useState({});

  useEffect(() => {
    initData(optionsData);
  }, [optionsData]);

  const initData = (optionsData = {}) => {
    const temp = {};
    if (!isEmpty(fields))
      for (const choice of fields[0]['choices']) {
        Object.assign(temp, { [choice.name]: 0 });
      }
    setData({ ...temp, ...optionsData });
  };

  const isChoiceActive = (choiceTobeChecked) => {
    for (const choice of fields[0]['choices']) {
      if (choiceTobeChecked === choice.name) {
        return choice.active;
      }
    }
  };

  const parseChoices = (choices) => {
    let result = [];
    for (let choice of choices) {
      if (choice.active === true) {
        result.push(choice);
      } else if (choice.active === false && data[choice.name]) {
        result.push(choice);
      }
    }
    return result;
  };

  return (
    <div className="open-ticket-option-wrapper">
      <p className="arc-H150">Select quantity</p>
      {data &&
        parseChoices(fields[0]['choices'])
          .sort((a, b) => a.display_order - b.display_order)
          .map(({ name, id }) => (
            <div key={id} className="open-ticket-option-item">
              <div className="open-ticket-option-counter-wrapper">
                <div className="open-ticket-option-count-title">{name}</div>
                <div className="open-ticket-option-counter-with-alert">
                  <div class={'open-ticket-option-counter ' + (isChoiceActive(name) ? '' : 'half-opacity')}>
                    <Button
                      type="link"
                      aria-label={`Decrease quantity of ${name}`}
                      className="link-btn arc-focus-outline"
                      onClick={() => data[name] && isChoiceActive(name) && callback(COUNTER_ACTIONS.REMOVE, name)}>
                      <FontAwesomeIcon
                        icon={faMinus}
                        className={data[name] && isChoiceActive(name) ? 'arc-cursor-p' : 'arc-cursor-not-allowed'}
                      />
                    </Button>
                    <Input
                      readOnly
                      value={data[name]}
                      className="arc-text-align-c"
                      aria-label={`Ticket quantity of ${name}`}
                    />
                    <Button
                      type="link"
                      aria-label={`Increase quantity of ${name}`}
                      className="link-btn arc-focus-outline"
                      onClick={() => isChoiceActive(name) && callback(COUNTER_ACTIONS.ADD, name)}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={isChoiceActive(name) ? 'arc-cursor-p' : 'arc-cursor-not-allowed'}
                      />
                    </Button>
                  </div>
                  <If condition={!isChoiceActive(name)}>
                    <div className="open-ticket-archived">
                      <Tooltip title="This option can no longer be edited" placement="topLeft">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Tooltip>
                    </div>
                  </If>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
  eventData: state.eventDataReducer.data || {},
});

export default connect(mapStateToProps, {})(OpenTicketOptions);
