import { REDUX_STATUSES } from '../../common/constants';
import store from '../store';
import instance from '../../common/utilities/axios_util';
import { EVENT_REGISTER_API_PATH } from '../endpoints';

const eventSlug = window.eventData.slug;
const registrationData = window.registrationData;

const initialState = {
  data: getInitialState(),
  eventId: null,
  registrationId: null,
  status: REDUX_STATUSES.FETCHING,
};

const REQUEST_REGISTRATION_DATA = 'EVENT/REQUEST_REGISTRATION_DATA';
const SET_REGISTRATION_DATA = 'EVENT/SET_REGISTRATION_DATA';

/**
 * method to initialize the redux state with local-storage in case there is new registration
 */
function getInitialState() {
  if (!registrationData && Storage !== void 0 && localStorage.getItem(eventSlug)) {
    return JSON.parse(localStorage.getItem(eventSlug));
  }
  return {};
}

export const requestRegistrationData = (data) => {
  return {
    type: REQUEST_REGISTRATION_DATA,
    payload: data,
  };
};

export const setRegistrationData = (data) => {
  return {
    type: SET_REGISTRATION_DATA,
    payload: data,
  };
};

const fetchRegistrationData = (data) => {
  const path = EVENT_REGISTER_API_PATH.replace('{}', data.eventId).concat(data.registrationId, '/');
  //
  instance
    .get(path)
    .then((response) => {
      store.dispatch(setRegistrationData(response.data));
    })
    .catch((error) => {});
};

const registrationDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REGISTRATION_DATA:
      fetchRegistrationData(action.payload);
      return {
        ...state,
        eventId: action.payload.eventId,
        registrationId: action.payload.registrationId,
      };
    case SET_REGISTRATION_DATA:
      // Update local storage until registration has no ID (which mean registration not created or exist)
      if (action.payload && !action.payload.id && Storage !== void 0) {
        localStorage.setItem(eventSlug, JSON.stringify(action.payload));
      }
      return {
        ...state,
        data: { ...action.payload },
        status: REDUX_STATUSES.FETCHED,
      };
    default:
      return state;
  }
};

export default registrationDataReducer;
