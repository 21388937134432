import React, { useState } from 'react';
import ThemeXModal from 'Src/common/components/themeXModal';
import { Form, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import './style.scss';
import ReactAddToCalendar from 'react-add-to-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

const RemindToRegisterModal = ({ calendarData, reminderData, ...props }) => {
  /*
    *****Remind to register modal*****
    Generic component for the user to set a reminder for themselves.
    Takes 4 props. 2 for rendering here, 2 to pass onto themexmodal.
        calendarData - object - {title, description}
        This is for passing into the invite
        reminderData - object - {name, title, remindBefore, text}
        This is for rendering the modal
        isVisible - boolean - pass to ThemeXModal
        handleCancel - function - pass to ThemeXModal
        NOTE - all of the above are required for the modal to render.
    */

  const [reminderDateTimeStart, setReminderDateTimeStart] = useState('');
  const [reminderDateTimeEnd, setReminderDateTimeEnd] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [form] = Form.useForm();
  const disabledDates = (current) => {
    return current && (current > moment(reminderData.remindBefore).startOf('day') || current < moment().startOf('day'));
  };

  const validateDateTime = () => {
    let setDate = form.getFieldValue('reminder_date');
    if (setDate) {
      setDate = moment(setDate).utc();
      setValidationErrorMessage('');
      setReminderDateTimeStart(setDate.format('DD MMM YYYY hh:mm a'));
      setReminderDateTimeEnd(setDate.add(15, 'minutes').format('DD MMM YYYY hh:mm a'));
    } else {
      // if time validation message exists, don't clear it.
      setValidationErrorMessage('Date cannot be empty.');
    }
  };

  return (
    <ThemeXModal
      {...props}
      closable={true}
      graphic={`${staticPath}img/events/registration_reminder.png`}
      wrapClassName="remind-to-register-modal">
      <div className="remind-register-wrapper arc-text-align-c">
        <Form form={form} name="remindToRegisterForm" layout="vertical">
          <Row align="middle">
            <Col span={24}>
              <p className="mb12 mt16 arc-H200">{reminderData.title}</p>
            </Col>
          </Row>
          <Row>
            <Col span={16} className="remind-register-date">
              <Form.Item name="reminder_date">
                <DatePicker format="DD MMM YYYY" className="remind-register-date" disabledDate={disabledDates} />
              </Form.Item>
            </Col>
            <Col span={24} className="remind-register-error arc-error-color">
              {validationErrorMessage}
            </Col>
          </Row>
        </Form>
        <div
          className={`${!form.getFieldValue('reminder_date') && 'disable-dropdown'} mb8`}
          onClick={() => validateDateTime()}>
          <ReactAddToCalendar
            event={{
              title: calendarData.title,
              description: calendarData.description,
              startTime: reminderDateTimeStart,
              endTime: reminderDateTimeEnd,
              location: window.location.href,
            }}
            buttonLabel="Set reminder"
            buttonTemplate={{ textOnly: 'none' }}
          />
          <p className="reminder-help-text arc-support">
            <FontAwesomeIcon icon={faInfoCircle} /> {reminderData.helpText}
          </p>
        </div>
      </div>
    </ThemeXModal>
  );
};

export default RemindToRegisterModal;
