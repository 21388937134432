/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { Fragment, useEffect, useState } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { connect } from 'react-redux';
import { isEmpty, includes, intersection } from 'lodash';
import { Avatar, Button, Card, Col, Divider, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faTicketAlt,
  faPen,
  faUser,
  faArrowLeft,
  faArrowRight,
  faFileUser,
  faPrint,
  faCalendar,
} from '@fortawesome/pro-light-svg-icons';
import AddItineraryToCalendar from 'Src/alumniEvents/alumni/confirmation/modals/addItineraryTocalendar';
import { ITINERARY_VIEW_URL, ALUMNI_BASE_URL, RECEIPT_VIEW_URL } from 'Src/alumniEvents/routes';
import { ANNOUNCEMENTS_API_PATH } from 'Src/alumniEvents/endpoints';
import { BREAKPOINT } from 'Src/common/constants';
import { COVER_IMAGE, ORIENTATION, OPEN_TICKET, LOGO_IMAGE } from 'Src/alumniEvents/constants';
import instance from 'Src/common/utilities/axios_util';
import VenueCard from 'Src/alumniEvents/alumni/home/DetailBox/VenueCard';
import OnlineVenueCard from 'Src/alumniEvents/alumni/home/DetailBox/OnlineVenueCard';
import ZoomVenueCard from 'Src/alumniEvents/alumni/home/DetailBox/ZoomVenueCard';
import LivestreamCard from 'Src/alumniEvents/alumni/home/DetailBox/LivestreamCard';
import GiftCard from 'Src/alumniEvents/alumni/home/DetailBox/GiftCard';
import CountDown from 'Src/common/components/countDown';
import { getCartData } from 'Src/alumniEvents/utils';
import { getUrlParam } from 'Src/common/utilities/data_util';
import { getDateTimeDiff } from 'Src/common/utilities/moment_util';
import ThemeXFooter from 'Src/common/components/themeXFooter';
import { isAllowedToViewContent } from 'Src/alumniEvents/alumni/common/utils/data';
import DivButton from 'Src/common/components/controller/buttons/buttonController';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import GuestListModal from 'Src/alumniEvents/alumni/home/DetailBar/GuestListModal';
import useCurrentWidth from 'Src/common/hooks/useCurrentWidth';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import QrCodeGenerator from 'Src/common/components/qrCodeGenerator/index';
import { getAgendaItems } from 'Src/alumniEvents/utils/app_moment_utils';
import GuestDetailsModal from './guestDetailsModal';
import TicketModal from './ticketModal';
import Announcements from './announcements';
import DateBlock from './dateBlock';

import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import './style.scss';

const MOBILE_MAX_VIEW_PORT_SIZE = 768;

function GuestKit({ eventData, registrationData, contextData }) {
  /*
        Guest kit ideally gathers all the information relevant to registered user (rsvp status - Yes)
        All the user registration details, ticket details, guest details, venue details and announcements will be part of guest kit
        Guest kit also has some content which has privacy restrictions - announcements and add to calendar.
        Guest kit link will be shared in all the emails and clicking on View registration should get user here
    */
  const authKey = getUrlParam('r') || '';
  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);
  const [isGuestListModalOpen, setIsGuestListModalOpen] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState({});
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(0);
  const [isGuestModalVisible, setIsGuestModalVisible] = useState(false);
  const [isEventStarted, setIsEventStarted] = useState(false);
  const [isEventEnded, setIsEventEnded] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const { currencyEntity } = contextData;
  const guestCount = eventData.count_guests;
  const nonAnonymousGuestCount = eventData.attending_guest_profiles.filter(
    (profile) => profile.should_show_on_attendee_list,
  ).length;
  const { mandatoryTickets, otherTickets } = getCartData(
    eventData.tickets,
    registrationData.tickets,
    registrationData.guests,
  );
  const [navigatedGuest, setNavigatedGuest] = useState({});
  const [guestAgendas, setGuestAgendas] = useState([]);
  const [isAddItineraryModalVisible, setIsAddItineraryModalVisible] = useState(false);
  const viewPortWidth = useCurrentWidth();

  const guestData = registrationData.guests.filter((guest) => guest.is_primary)[0];

  function getAnnouncements() {
    const hashToken = contextData.registrationHash;
    if (hashToken) {
      instance.defaults.headers.common['VERIFY-TOKEN'] = hashToken;
    }
    return instance({
      method: 'get',
      url: ANNOUNCEMENTS_API_PATH.replace('{}', eventData.id)
        .concat(`${registrationData.id}/items/`)
        .concat(window.location.search),
    });
  }

  useEffect(() => {
    if (!isEmpty(eventData)) {
      if (eventData.start_datetime) {
        if (getDateTimeDiff(eventData.start_datetime) <= 0) {
          setIsEventStarted(true);
        }
        if (getDateTimeDiff(eventData.end_datetime) <= 0) {
          setIsEventEnded(true);
        }
      }
      // registrationData will be empty for registration canceled.
      if (!isEmpty(registrationData)) {
        getAnnouncements().then((response) => {
          setAnnouncements(response.data);
        });
      }
    }
  }, [eventData]);

  useEffect(() => {
    if (!isEmpty(registrationData)) {
      setNavigatedGuest(registrationData.guests[0]);
    }
  }, [registrationData]);

  useEffect(() => {
    setAgendas(getAllBoughtTickets());
  }, []);

  function toggleTicketModal() {
    setIsTicketModalVisible(!isTicketModalVisible);
  }

  function toggleGuestListModal() {
    setIsGuestListModalOpen(!isGuestListModalOpen);
  }

  function toggleGuestModal() {
    setIsGuestModalVisible(!isGuestModalVisible);
  }

  function selectGuestAndShowDetails(guest) {
    setSelectedGuest(guest);
    toggleGuestModal();
  }

  function getItineraryUrl(guestId) {
    const url = ITINERARY_VIEW_URL.replace(':guestId', guestId);
    return url.replace(':eventSlug', eventData.slug).concat('?r=', authKey);
  }

  function moveToNextGuest() {
    const nextIndex = selectedGuestIndex + 1;
    setSelectedGuestIndex(nextIndex);
    setNavigatedGuest(registrationData.guests[nextIndex]);
  }

  function moveToPrevGuest() {
    const nextIndex = selectedGuestIndex - 1;
    setSelectedGuestIndex(nextIndex);
    setNavigatedGuest(registrationData.guests[nextIndex]);
  }

  function getFeaturedImage() {
    const width = document.documentElement.clientWidth || 0;
    const orientation = width < BREAKPOINT ? ORIENTATION.PORTRAIT : ORIENTATION.LANDSCAPE;
    const eventImages = eventData.images.filter((image) => image.image_type !== LOGO_IMAGE);
    const featuredImage = eventData.images.filter(
      (image) => image.image_type === COVER_IMAGE && image.image_orientation === orientation,
    );
    if (featuredImage.length) {
      return featuredImage[0].image;
    }
    if (eventImages.length) {
      return eventImages[0].image;
    }

    return window.defaultBannerImage;
  }

  function getEventStartsInLabel() {
    if (isEventEnded) {
      return 'This Event has Ended';
    }
    if (isEventStarted) {
      return 'This Event is Live';
    }
    return 'Event start in';
  }

  // this will redirect registrant to edit url page
  function getEditRegistrationUrl() {
    return ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug)
      .concat('?r=', authKey)
      .concat('&edit_registration=true');
  }

  function getContactEmail() {
    if (!isEmpty(eventData.contact_persons)) {
      const { name, email, phone_number } = eventData.contact_persons[0];
      if (email && phone_number) {
        return `${name} at ${email} or call at ${phone_number}`;
      }
      if (!email && phone_number) {
        return `${name}, call ${phone_number}`;
      }
      if (!phone_number && email) {
        return `${name} at ${email}`;
      }
    }
    return window.collegeEmail;
  }

  const openTickets = eventData.tickets
    .filter((ticket) => ticket.ticket_type === OPEN_TICKET)
    .map((ticket) => ticket.id);

  const openBoughtTickets = registrationData.tickets
    .filter((item) => includes(openTickets, item.ticket.id))
    .map((item) => ({ id: item.ticket.id, quantity: item.quantity }));

  function getAllBoughtTickets() {
    const seatedBoughtTickets = guestData.tickets.map((item) => item.ticket.id);
    const allBoughtTickets = [...seatedBoughtTickets, ...openBoughtTickets.map((item) => item.id)];
    return allBoughtTickets;
  }

  function setAgendas(tickets) {
    const eventAgendas = eventData.agendas.filter((item) => !item.is_hide_on_itinerary);
    const agendaItems = getAgendaItems(eventAgendas);
    const newGuestAgendas = [];
    agendaItems.forEach((item) => {
      item.data.forEach((agenda) => {
        const agendaTickets = agenda.tickets.map((ticket) => ticket.id);
        if (!!intersection(tickets, agendaTickets).length || !agendaTickets.length) {
          newGuestAgendas.push(agenda);
        }
      });
    });
    setGuestAgendas(newGuestAgendas);
  }

  function renderAddToCalendar() {
    // eventData.venue_location is a new property on registration Model.
    // It has the venue details after factoring in privacy.
    const guestKitUrl = `${window.location.origin}/e/${eventData?.slug}/kit/?r=${eventData?.edit_key}&edit_registration=true`;
    const calendarEventObject = {
      title: eventData.name,
      // eslint-disable-next-line max-len
      description: `Please check your registration kit for instructions on how to attend the event. You can also view your receipt or modify your guests and registration details from here: ${guestKitUrl} \n\nIf you need any assistance, please reach out to ${getContactEmail()}.`,
      startTime: eventData.start_datetime,
      endTime: eventData.end_datetime,
    };
    if (registrationData.venue_location) {
      calendarEventObject.location = registrationData.venue_location;
    }
    return (
      <AddToCalendar
        event={{ ...calendarEventObject }}
        buttonTemplate={{ calendar: 'left' }}
        buttonLabel="Add to calendar"
      />
    );
  }
  return (
    <div className="guest-kit-wrapper">
      <div className="blurred-image" style={{ backgroundImage: `url(${getFeaturedImage() || window.preloadImage})` }} />
      <div className="guest-kit-header">
        <div className="guest-kit-header-wrapper">
          <DivButton className="guest-kit-back-btn" onClick={() => window.history.back()}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </DivButton>
          <p className="arc-H300 arc-color-white mt8">{window.collegeName}</p>
        </div>
      </div>
      <div className="guest-kit-body">
        <Row gutter={16} justify="center">
          <Col lg={12} md={12} sm={24} xs={24}>
            <Card
              className="arc-card-border-radius arc-card-box-shadow"
              bordered={false}
              style={{ width: '100%' }}
              cover={
                <div>
                  <If condition={viewPortWidth <= MOBILE_MAX_VIEW_PORT_SIZE}>
                    <If condition={eventData.livestream_url}>
                      <div className="mb16">
                        <LivestreamCard
                          url={eventData.livestream_url}
                          isContentLocked={!isAllowedToViewContent(eventData.venue_privacy, registrationData)}
                        />
                      </div>
                    </If>
                    <If condition={!isEmpty(eventData.gift_settings)}>
                      {eventData.gift_settings.map((gift) => (
                        <div key={gift.id}>
                          <GiftCard gift={gift} email={registrationData.email} eventData={eventData} />
                        </div>
                      ))}
                    </If>
                  </If>
                  <div className="relative-position">
                    <img alt={eventData.name} src={getFeaturedImage() || window.preloadImage} />
                    <div className="overlay" />
                    <div className="event-details">
                      <a
                        className="event-details-redirect"
                        href={ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug)}
                        target="_blank"
                        rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <p className="arc-H500 arc-color-white event-name">{eventData.name}</p>
                      </a>
                    </div>
                  </div>
                </div>
              }>
              <If condition={eventData.start_datetime}>
                <div className="date-blocks">
                  <DateBlock label="STARTS" dateTime={eventData.start_datetime} timezone={eventData.short_timezone} />
                  <DateBlock label="ENDS" dateTime={eventData.end_datetime} timezone={eventData.short_timezone} />
                </div>
                <Divider />
              </If>
              <div className="guest-details-wrapper">
                <p className="arc-H200">{getEventStartsInLabel()}</p>
                <If condition={eventData.start_datetime && !isEventStarted && !isEventEnded}>
                  <CountDown dateTime={eventData.start_datetime} callback={() => setIsEventStarted(true)} />
                </If>
                <div className="guest-details mt16">
                  <If condition={eventData.is_guest_count_shown_to_users && guestCount}>
                    <div id="event-latest-attendee-block">
                      <div id="event-latest-attendees">
                        {eventData.attending_guest_profiles.map((attendee, index) => (
                          <Avatar
                            src={attendee.matched_profile && attendee.matched_profile.present_picture}
                            icon={<UserOutlined />}
                            key={attendee?.id || index}
                            className="attendee-avatar"
                          />
                        ))}
                      </div>
                      <span className="arc-p">
                        <Choose>
                          <When condition={guestCount <= 2 && nonAnonymousGuestCount === guestCount}>
                            <I18nCustomFormatter
                              id="event-guest-is-attending"
                              values={{
                                full_name: eventData.attending_guest_profiles[0].full_name,
                                second_full_name:
                                  nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].full_name : null,
                                non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                              }}
                            />
                          </When>
                          <When condition={nonAnonymousGuestCount === 0}>
                            <I18nCustomFormatter
                              id="guest-counts-attending-numbers"
                              values={{
                                guests: guestCount,
                              }}
                            />
                          </When>
                          <Otherwise>
                            <I18nCustomFormatter
                              id="event-guests-more-than-one-prefix"
                              values={{
                                full_name: eventData.attending_guest_profiles[0].first_name,
                                second_full_name:
                                  nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].first_name : null,
                                non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                              }}
                            />{' '}
                            <span className="guest-list-trigger" onClick={() => toggleGuestListModal()}>
                              <I18nCustomFormatter
                                id="guest-counts-attending"
                                values={{
                                  guests: eventData.count_guests - Math.min(nonAnonymousGuestCount, 2),
                                }}
                              />
                            </span>
                          </Otherwise>
                        </Choose>
                      </span>
                    </div>
                  </If>
                </div>
              </div>
              <If condition={!isEventEnded}>
                <p className="arc-p arc-color-B85 mt16">
                  <I18nCustomFormatter id="note-for-add-to-calendar" />
                </p>
                <Choose>
                  <When condition={eventData.is_pro && !isEmpty(guestAgendas)}>
                    <div className="add-to-calendar-wrapper arc-d-inline-block mt16">
                      <Button type="primary" block onClick={() => setIsAddItineraryModalVisible(true)}>
                        <FontAwesomeIcon icon={faCalendar} />
                        <span className="ml8">Add to calendar</span>
                      </Button>
                    </div>
                  </When>
                  <Otherwise>
                    <div className="add-to-calendar-wrapper">{renderAddToCalendar()}</div>
                  </Otherwise>
                </Choose>
              </If>
              <Choose>
                <When condition={mandatoryTickets.length || otherTickets.length}>
                  <p className="mt16 arc-color-primary">
                    <FontAwesomeIcon icon={faTicketAlt} />
                    <a className="ml8" onClick={toggleTicketModal}>
                      <I18nCustomFormatter id="view-registration-details" />
                    </a>
                  </p>
                </When>
                <Otherwise>
                  <p className="mt16 arc-color-primary">
                    <FontAwesomeIcon icon={faPen} />
                    <a href={getEditRegistrationUrl()} className="ml8">
                      <I18nCustomFormatter id="edit-registration-details" />
                    </a>
                  </p>
                </Otherwise>
              </Choose>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={RECEIPT_VIEW_URL.replace(':eventSlug', eventData.slug).concat(`?r=${authKey}`)}
                className="arc-color-primary arc-d-block">
                <FontAwesomeIcon icon={faPrint} className="mr8 mt16" />
                <I18nCustomFormatter id="confirmation-receipt-print-label" />
              </a>

              <Divider />
              <Fragment>
                <p className="arc-H300 arc-color-black">{navigatedGuest.full_name}</p>
                <If condition={navigatedGuest.email}>
                  <p className="arc-p arc-color-B55">{navigatedGuest.email}</p>
                </If>
                <p className="arc-p arc-color-primary mt16">
                  <span>
                    <FontAwesomeIcon icon={faUser} />
                    <a className="ml8" onClick={() => selectGuestAndShowDetails(navigatedGuest)}>
                      <I18nCustomFormatter id="view-details" />
                    </a>
                  </span>
                  <If condition={eventData.agendas.length}>
                    <span className="ml16">
                      <FontAwesomeIcon icon={faFileUser} />
                      <a
                        href={getItineraryUrl(navigatedGuest.id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml8">
                        <I18nCustomFormatter id="confirmation-form-item-view-itinerary" />
                      </a>
                    </span>
                  </If>
                </p>
                {/* show navigator if there are more than 1 guest */}
                <If condition={!isEmpty(registrationData.guests) && registrationData.guests.length > 1}>
                  <div className="action-btn-container mt16">
                    <Button
                      className="arc-btn-subtle"
                      shape="circle"
                      onClick={moveToPrevGuest}
                      disabled={selectedGuestIndex === 0}>
                      <FontAwesomeIcon icon={faArrowLeft} className="arc-H150" />
                    </Button>
                    <Button
                      className="arc-btn-subtle ml24"
                      shape="circle"
                      onClick={moveToNextGuest}
                      disabled={selectedGuestIndex === registrationData.guests.length - 1}>
                      <FontAwesomeIcon icon={faArrowRight} className="arc-H150" />
                    </Button>
                  </div>
                </If>
              </Fragment>
            </Card>
          </Col>
          {/* <If
            condition={
              !isEmpty(eventData.webinar_id)
              || !isEmpty(eventData.venue_link)
              || eventData.venue
              || eventData.livestream_url
              || !isEmpty(eventData.gift_settings)
              || announcements.length
              || eventData.is_pro
            }> */}
          <Col lg={12} md={12} sm={24} xs={24} className="right-hand-side-wrapper">
            <Choose>
              <When condition={!isEmpty(eventData.webinar_id)}>
                <div className="mb16">
                  <ZoomVenueCard
                    venueData={registrationData.zoom_registration}
                    webinarSettingsData={eventData.webinar_details}
                    isContentLocked={!isAllowedToViewContent(eventData.venue_privacy, registrationData)}
                  />
                </div>
              </When>
              <Otherwise>
                <Choose>
                  <When condition={!isEmpty(eventData.venue_link)}>
                    <div className="mb16">
                      <OnlineVenueCard
                        venue={eventData.venue_link}
                        isContentLocked={!isAllowedToViewContent(eventData.venue_privacy, registrationData)}
                      />
                    </div>
                  </When>
                  <Otherwise>
                    <If condition={eventData.venue}>
                      <div className="mb16">
                        <VenueCard
                          venueData={eventData.venue}
                          isContentLocked={!isAllowedToViewContent(eventData.venue_privacy, registrationData)}
                        />
                      </div>
                    </If>
                  </Otherwise>
                </Choose>
              </Otherwise>
            </Choose>
            <If condition={eventData.edit_key && eventData.is_checkin_kiosk_enabled}>
              <QrCodeGenerator
                title="QR Code for Entry"
                supportText="Scan this QR code at the venue for easy entry"
                qrCodeConfig={{
                  value: eventData.edit_key,
                  size: 170,
                }}
                openedQrCodeConfig={{
                  value: eventData.edit_key,
                  size: 300,
                }}
              />
            </If>
            <If condition={viewPortWidth > MOBILE_MAX_VIEW_PORT_SIZE}>
              <If condition={eventData.livestream_url}>
                <div className="mb16">
                  <LivestreamCard
                    url={eventData.livestream_url}
                    isContentLocked={!isAllowedToViewContent(eventData.venue_privacy, registrationData)}
                  />
                </div>
              </If>
              <If condition={!isEmpty(eventData.gift_settings)}>
                {eventData.gift_settings.map((gift) => (
                  <div key={gift.id} className="mb16">
                    <GiftCard gift={gift} email={registrationData.email} eventData={eventData} />
                  </div>
                ))}
              </If>
            </If>
            <If condition={announcements.length}>
              <div>
                <Announcements announcements={announcements} registration={registrationData} />
              </div>
            </If>
          </Col>
          {/* </If> */}
        </Row>
      </div>
      <div className="mt16">
        <ThemeXFooter hidHashTag />
      </div>
      <If condition={isTicketModalVisible}>
        <TicketModal
          mandatoryTickets={mandatoryTickets}
          otherTickets={otherTickets}
          closeModal={toggleTicketModal}
          guestCount={registrationData.guests.length}
          currencyEntity={currencyEntity}
          getEditRegistrationUrl={getEditRegistrationUrl}
          registrationData={registrationData}
        />
      </If>
      <If condition={isGuestModalVisible}>
        <GuestDetailsModal closeModal={toggleGuestModal} guest={selectedGuest} registrationData={registrationData} />
      </If>
      <If condition={isGuestListModalOpen}>
        <GuestListModal
          eventId={eventData.id}
          isShareEnabled={eventData.is_share_enabled}
          handleClose={() => toggleGuestListModal()}
        />
      </If>
      <If condition={isAddItineraryModalVisible}>
        <AddItineraryToCalendar
          agendas={guestAgendas}
          onClose={() => setIsAddItineraryModalVisible(false)}
          getContactEmail={getContactEmail}
          eventData={eventData}
          registrationData={registrationData}
        />
      </If>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
});

export default connect(mapStateToProps, null)(GuestKit);
