import React, { forwardRef, useImperativeHandle } from 'react';
import { Divider } from 'antd';

import './style.scss';

const ActivityNotifier = forwardRef((props, ref) => {

    function handleOnClick() {
        props.doResetFeed();
        props.onClick();
    }

    useImperativeHandle(ref, () => ({
        refClick() {
            handleOnClick();
        }
    }));

    if ((props.adds.length || props.deletes.length) && !props.hasUserPosted) {
        return (
            <Divider className="feed-activity-notifier">
                <div onClick={() => handleOnClick()} className="arc-p">
                    <p>You have new updates</p>
                    <a>Refresh</a>
                </div>
            </Divider>
        )
    }
    return null;
});

export default ActivityNotifier;