/* eslint-disable complexity */
/* eslint-disable max-statements */

import React from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import { string, func, number, bool, shape } from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import { readableNumber } from 'Src/common/utilities/data_util';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import { getFormattedDate } from '../../../../common/utilities/moment_util';
import { MANDATORY_TICKET } from '../../../constants';

import './style.scss';

const i18Messages = require('Data/en-US/event.json');

const TICKET = 'ticket';

function AgendaItem({
  boughtQuantity,
  type,
  data,
  currencyEntity,
  handleTicketBookAction,
  handleAgendaBookAction,
  inModal,
  dateString,
}) {
  if (data.ticket_type === MANDATORY_TICKET) return null;

  let isNotAvailableForSale = false;
  let isSoldOut = false;
  let isSaleEnded = false;
  let ticketSaleStartDate = null;
  let priceString = 0;
  let timeString = '';

  if (type === TICKET) {
    isNotAvailableForSale = !data.is_available;
    isSoldOut = data.count_available_tickets != null ? !data.count_available_tickets : false;
    isSaleEnded = data.is_sale_ended;
    priceString = readableNumber(data.price);
    ticketSaleStartDate = data.start_datetime;
  } else {
    const isSingleTicketAgenda = data.tickets.length === 1;
    const singleAgendaTicket = data.tickets[0];
    const minPrice = data.tickets.reduce((min, b) => Math.min(min, b.price), singleAgendaTicket.price);
    if (isSingleTicketAgenda) {
      isNotAvailableForSale = !singleAgendaTicket.is_available;
      isSoldOut =
        singleAgendaTicket.count_available_tickets != null ? !singleAgendaTicket.count_available_tickets : false;
      isSaleEnded = singleAgendaTicket.is_sale_ended;
      ticketSaleStartDate = singleAgendaTicket.start_datetime;
      if (minPrice === 0) {
        priceString = 'Free';
      } else {
        priceString = `${readableNumber(minPrice)}`;
      }
    } else if (minPrice === 0) {
      priceString = 'Multiple tickets available';
    } else {
      priceString = `${readableNumber(minPrice)} onwards`;
    }
    timeString = `${getFormattedDate(data.start_datetime, 'hh:mma')} - ${getFormattedDate(
      data.end_datetime,
      'hh:mma',
    )}`;
  }

  function getDisabledButtonTooltipText() {
    if (isNotAvailableForSale && !isSaleEnded) {
      return `Sale for this ticket starts on ${getFormattedDate(ticketSaleStartDate, 'ddd, DD MMM YYYY')}`;
    }
    return 'This ticket is no longer available for sale.';
  }

  return (
    <div className={`event-agenda-item ${inModal ? ' below-margin' : ' extra-padding half-width'}`}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32 }} type="flex" align="middle">
        <Col span={24} className="event-agenda-item-detail">
          <div>
            <If condition={type === TICKET && data.image}>
              <img src={data.image} className="event-ticket-image" alt="" />
            </If>
            <div>
              <p className="arc-H150 arc-color-B85">{data.name}</p>
              <p className="arc-support arc-color-B85 item-price-and-date">
                {priceString !== 'Free' && priceString !== 'Multiple tickets available' && (
                  <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                )}
                <span>{priceString}</span>
              </p>
              <p className="arc-support item-price-and-date">
                {timeString && <span className="agenda-item-time-range">{timeString}</span>}
              </p>
            </div>
          </div>
          <div>
            <Choose>
              <When condition={isNotAvailableForSale || (isSoldOut && !boughtQuantity)}>
                <Tooltip placement="topLeft" title={getDisabledButtonTooltipText()} trigger={['hover', 'click']}>
                  <Button
                    type="primary"
                    className={`${boughtQuantity ? 'ticket-bought-highlight' : 'arc-btn-subtle'} arc-focus-outline`}
                    disabled={isNotAvailableForSale || isSoldOut}
                    aria-label={`${
                      boughtQuantity
                        ? `${i18Messages['event-ticket-edit-label']} - ${data.name}`
                        : `${i18Messages['event-ticket-book-label']} - ${data.name}`
                    }`}>
                    {boughtQuantity ? (
                      <I18nCustomFormatter id="event-ticket-edit-label" />
                    ) : (
                      <I18nCustomFormatter id="event-ticket-book-label" />
                    )}
                  </Button>
                </Tooltip>
              </When>
              <Otherwise>
                <Button
                  type="primary"
                  className={`${boughtQuantity ? 'ticket-bought-highlight' : 'arc-btn-subtle'} arc-focus-outline`}
                  onClick={() => (type === TICKET ? handleTicketBookAction() : handleAgendaBookAction())}
                  aria-label={`${
                    boughtQuantity
                      ? `${i18Messages['event-ticket-edit-label']} - ${data.name}`
                      : `${i18Messages['event-ticket-book-label']} - ${data.name}`
                  }`}>
                  {boughtQuantity ? (
                    <I18nCustomFormatter id="event-ticket-edit-label" />
                  ) : (
                    <I18nCustomFormatter id="event-ticket-book-label" />
                  )}
                </Button>
              </Otherwise>
            </Choose>
          </div>
        </Col>
        <If condition={data.description}>
          <Col span={24} className="event-agenda-item-detail">
            <div className="arc-p arc-color-B45 item-description" data-color-mode="light">
              <MDEditor.Markdown
                source={data.description}
                linkTarget="_blank"
                previewOptions={{
                  linkTarget: '_blank',
                }}
              />
            </div>
          </Col>
        </If>
        <If condition={dateString}>
          <Col span={24} className="event-agenda-item-detail">
            <p className="arc-p arc-color-B45 item-description">{dateString}</p>
          </Col>
        </If>
      </Row>
    </div>
  );
}

AgendaItem.propTypes = {
  boughtQuantity: number,
  type: string,
  data: shape(),
  currencyEntity: string.isRequired,
  handleTicketBookAction: func,
  handleAgendaBookAction: func,
  inModal: bool,
  dateString: string,
};

AgendaItem.defaultProps = {
  boughtQuantity: 0,
  data: {},
  inModal: false,
  type: '',
  handleTicketBookAction: () => {},
  handleAgendaBookAction: () => {},
  dateString: '',
};

export default AgendaItem;
