import React, { useState, useEffect } from 'react';
import { bool, func, string, number } from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Avatar, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import instance from './../../../../utilities/axios_util';
import { getUrlParam } from './../../../../utilities/data_util';

import { REACTIONS_GET_API_PATH } from './../../../../endpoints';
import { GET_STREAM_REACTION_TYPES } from './../../../../constants';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

const antIcon = <LoadingOutlined spin />;
let nextId = null;

function ReactionsList({
    visible,
    moduleName,
    totalLikes,
    objectId,
    activityId,
    doCloseModal
}) {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        if (!visible) {
            setItems([]);
            setHasMore(true);
            nextId = null;
        }
    }, [visible]);

    function getReactedUsers() {
        setLoading(true);
        let path = REACTIONS_GET_API_PATH.replace('{object_id}', objectId);
        path = path.replace('{module_name}', moduleName);
        path = path.replace('{activity_id}', activityId);
        path = path.replace('{reaction_type}', GET_STREAM_REACTION_TYPES.LIKE);
        path = path.concat('?limit=', 20);
        if (nextId) {
            path = path.concat('&id_lt=', nextId);
        }
        instance.get(path, { hideNotification: true }).then(response => {
            setItems([...items, ...response.data.results]);
            if (response.data.next) {
                nextId = getUrlParam('id_lt', response.data.next);
                setHasMore(true);
            }
            else {
                setHasMore(false);
            }
            setLoading(false);
        })
    }

    return (
        <Modal
            visible={visible}
            footer={null}
            closable={false}
            width='22rem'
            onCancel={() => doCloseModal()}
            wrapClassName="reactions-list-modal"
        >
            <div className="header">
                <FontAwesomeIcon icon={faArrowLeft} className="back-icon" onClick={() => doCloseModal()}/>
                <FontAwesomeIcon icon={faHeart}/>
                <p className="arc-H200 arc-color-B85 title">{totalLikes} likes</p>
            </div>
            <InfiniteScroll
                initialLoad={true}
                pageStart={0}
                loadMore={() => getReactedUsers()}
                hasMore={!loading && hasMore}
                useWindow={false}
            >
                <div className="list">
                    {items.map(item => {
                        const user = item.user.data;
                        return (
                            <div className="user-list-item" key={item.id}>
                                <div className="list-item-detail">
                                    <Avatar src={user.present_picture} />
                                    <div>
                                        <a href={user.profile_url} target="_blank">
                                            <p className="arc-p arc-color-B85">{user.formatted_name}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <If condition={loading && hasMore}>
                    <Spin indicator={antIcon} />
                </If>
            </InfiniteScroll>
        </Modal>
    )
}

ReactionsList.propTypes = {
    visible: bool.isRequired,
    doCloseModal: func.isRequired,
    moduleName: string.isRequired,
    objectId: number.isRequired,
    activityId: string.isRequired,
    totalLikes: number.isRequired
}

export default ReactionsList;