export const CUSTOM_FORMS_LIST_API_PATH = 'custom_forms/';
export const LOGIN_API = '/login';
export const EVENT_BASE_API_PATH = 'eventsx/';
export const DISCOUNT_VALID_API_PATH = `${EVENT_BASE_API_PATH}{}/discounts/code_valid?code=`;
export const EVENT_REGISTER_API_PATH = `${EVENT_BASE_API_PATH}{}/register/`;
export const EVENT_REGISTER_CANCEL_API_PATH = `${EVENT_REGISTER_API_PATH}{registrationId}/cancel/`;
export const REGISTRANT_EMAIL_CONFIRMATION_API_PATH = `${EVENT_REGISTER_API_PATH}send_confirmation_email/`;
export const EVENT_GUEST_LIST_API_PATH = `${EVENT_BASE_API_PATH}{}/guest_list/?page={page}&page_size=20`;
export const EVENT_INVITE_API_PATH = `${EVENT_BASE_API_PATH}{}/invite/`;
export const ANNOUNCEMENTS_API_PATH = `${EVENT_BASE_API_PATH}{}/announcements/`;
export const GIFT_DETAIL_URL = '/eventsx/<event_id>/gift_settings/<gift_id>';
export const GENERATE_PAYMENT_LINK_URL = '/givingx/<page_id>/payment_link_templates/<template_id>/payment_link/';
export const TICKET_ENTRIES_PATH = '/eventsx/<event_id>/registrations/<id>/ticket_details/?skip_pagination=true';
export const USER_FEEDBACK_API_PATH = '/user_feedback/submit/?content_type=eventxregistration&object_id=<object_id>';
