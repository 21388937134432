import React from 'react';
import { func } from 'prop-types';
import { Button } from 'antd';
import './style.scss';

function NonLoggedInPermissionState({ handleNonLoggedIn }) {
  const imgPath = `${staticPath}img/react-modules/feeds/`;

  return (
    <div className="non-logged-in-permission-state">
      <div style={{ backgroundImage: `url(${imgPath}empty_state_bg.png)` }} className="graphic-block">
        <img src={`${imgPath}conversation.png`} alt="Conversation" />
      </div>
      <div>
        <p className="arc-p">
          <span>To post, comment or react on conversations, you will need to </span>
          <Button type="link" className="link-btn arc-focus-outline" onClick={handleNonLoggedIn}>
            Log in.
          </Button>
        </p>
      </div>
    </div>
  );
}

export default NonLoggedInPermissionState;
