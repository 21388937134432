import React, { useState } from 'react';
import {
  Button, Card, Col, Progress, Row, Statistic,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers, faGift, faSeedling, faSync,
} from '@fortawesome/pro-light-svg-icons';
import isEmpty from 'lodash/isEmpty';
import CurrencyRenderer from 'Src/common/components/currencyRenderer';
import axiosInstance from 'Src/common/utilities/axios_util';
import { getGiftSettings, generatePaymentLink } from 'Src/alumniEvents/actions';

import './style.scss';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';

function GiftCard({ gift, email, eventData }) {
  const [loading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [giftL, setGiftL] = useState(gift);

  function redirectToUrl(url) {
    window.open(url, '_blank');
  }

  function generatePaymentLinkL() {
    if (!isEmpty(gift.payment_link_template)) {
      setLoading(true);
      generatePaymentLink(giftL.page.id, gift.payment_link_template.id, email).then((response) => {
        setLoading(false);
        redirectToUrl(`${response.data.detail}&utm_source=event&utm_medium=gift-button&utm_campaign=${eventData.id}`);
      }).catch((err) => {
        setLoading(false);
      });
    } else {
      redirectToUrl(`${giftL.page.campaign_url}?is_payment_modal_open=true&utm_source=event&utm_medium=gift-button&utm_campaign=${eventData.id}`);
    }
  }

  function refreshGift() {
    setIsRefreshing(true);
    getGiftSettings(eventData.id, giftL.id).then((response) => {
      setTimeout(() => {
        setIsRefreshing(false);
      }, 1000);
      setGiftL(response.data);
    }).catch((err) => {
      setIsRefreshing(false);
    });
  }

  return (
    <Card bordered={false} className="event-card gift-card arc-card-border-radius arc-card-box-shadow" id="event-gallery-card">
      <p className="arc-H200 arc-color-black">{giftL.page.name}</p>
      <a className="arc-color-primary refresh-gift" href="javascript:void(0)" onClick={refreshGift}><FontAwesomeIcon icon={faSync} spin={isRefreshing} /></a>
      <div
        className="mt16 mb16"
        dangerouslySetInnerHTML={{
          __html: giftL.description,
        }}
      />
      <Row className="arc-p arc-color-B55 mt16" type="flex" justify="space-between" align="middle">
        <Col>
          <Choose>
            <When condition={giftL.page.goal_prefix === 'donor'}>
              <FontAwesomeIcon icon={faUsers} />
              <I18nCustomFormatter
                id="giving.donors"
                values={{ donors: giftL.page.count_donors }}
              />
            </When>
            <Otherwise>
              <FontAwesomeIcon icon={faGift} className="mr4" />
              <span>
                <I18nCustomFormatter
                  id="gifts-count"
                  values={{
                    gifts: giftL.page.count_gifts,
                  }}
                />
              </span>
            </Otherwise>
          </Choose>
        </Col>
        <Col>
          <FontAwesomeIcon icon={faSeedling} className="mr4" />
          <CurrencyRenderer currencyId={giftL.page.currency} />
          <Statistic value={parseFloat(giftL.page.amount)} />
          {' '}
          <span className="arc-support">raised</span>
        </Col>
      </Row>
      <div>
        <Progress percent={giftL.page.completion_percentage} showInfo={false} />
      </div>
      <If condition={giftL.page.goal}>
        <p className="arc-H300 arc-color-B85 mt8 goal-percentage">
          {giftL.page.completion_percentage}
          %
        </p>
        <p className="arc-p arc-color-B85">
          Reached of
          {' '}
          <If condition={giftL.page.goal_prefix === 'amount'}>
            <CurrencyRenderer currencyId={giftL.page.currency} />
          </If>
          <Statistic value={giftL.page.goal} />
          {' '}
          <If condition={giftL.page.goal_prefix === 'gift'}>
            <I18nCustomFormatter
              id="gifts-plural"
              values={{
                gifts: giftL.page.goal,
              }}
            />
            {' '}
          </If>
          goal
        </p>
      </If>
      <Button
        type="primary"
        className="mt16"
        onClick={generatePaymentLinkL}
        loading={loading}
      >
        {giftL.button_text}
      </Button>
    </Card>
  );
}

export default GiftCard;
