import { REDUX_STATUSES } from '../../common/constants';
import store from '../store';
import instance from '../../common/utilities/axios_util';
import { CUSTOM_FORMS_LIST_API_PATH } from '../endpoints';

const initialState = {
    data: [],
    formId: null,
    status: REDUX_STATUSES.FETCHING
}

const REQUEST_REGISTRATION_FORM_LAYOUT = 'EVENT/REQUEST_REGISTRATION_FORM_LAYOUT';
const SET_REGISTRATION_FORM_LAYOUT = 'EVENT/SET_REGISTRATION_FORM_LAYOUT'

export const requestRegistrationFormLayout = (formId) => {
    return {
        type: REQUEST_REGISTRATION_FORM_LAYOUT,
        payload: formId
    }
}

export const setRegistrationFormLayout = (layout) => {
    return {
        type: SET_REGISTRATION_FORM_LAYOUT,
        payload: layout
    }
}

const fetchRegistrationFormLayout = formId => {
    const path = CUSTOM_FORMS_LIST_API_PATH.concat(formId, '/?fields=fields')
    instance.get(path).then((response) => {
        store.dispatch(setRegistrationFormLayout(response.data.fields || []));
    }).catch((error) => {});
}

const registrationFormLayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_REGISTRATION_FORM_LAYOUT:
            fetchRegistrationFormLayout(action.payload);
            return {
                ...state,
                formId: action.payload
            };
        case SET_REGISTRATION_FORM_LAYOUT:
            return {
                ...state,
                data: action.payload,
                status: REDUX_STATUSES.FETCHED
            };
        default:
            return state;
    }
}

export default registrationFormLayoutReducer;