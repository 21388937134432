import React, { useState, useRef, useEffect } from 'react';
import { bool, string, number, object, func } from 'prop-types';
import { message } from 'antd';
import { StreamApp, FlatFeed, Activity, CommentList, InfiniteScrollPaginator, LoadMorePaginator } from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';

import instance from './../../../utilities/axios_util';
import { getUrlParam } from './../../../utilities/data_util';
import {
    FEED_POST_API_PATH,
    FEED_GET_API_PATH,
    PUBLIC_FEED_API_PATH,
    REACTIONS_GET_API_PATH
} from './../../../endpoints';
import { GET_STREAM_REACTION_TYPES } from './../../../constants';

import FeedPostForm from './../feedPostForm';
import ActivityNotifier from './../activityNotifier';
import ActivityHeader from './../activityHeader';
import ActivityCommentField from './../activityCommentField';
import ActivityReactions from './../activityReactions';
import ActivityCommentItem from './../activityCommentItem';
import FeedPermissionAndEmptyState from './../feedPermissionAndEmptyState';
import ThemeXSignInModal from './../../themeXSignInModal';

import './style.scss';
let nextId = null;
let initialWritePermissionValue = null;
let fetchedFirstFil = false;

function Feed({
    token,
    feedGroup,
    moduleName,
    feedType,
    objectId,
    feedUserId,
    user,
    isLoggedIn,
    isAdmin,
    hasWritePermission,
    handleNoPermission,
    handleGetFeed,
    hideFeedPostForm,
    fetchedFirstReactionFilter,
    onRemoveActivity,
    showContentObject,
    hideLoginModal,
}) {
    const notifierRef = useRef();
    const nextUrl = window.location.pathname;

    const [hasNoContent, setHasNoContent] = useState(false);
    const [hasUserPosted, setHasUserPosted] = useState(false);
    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const [fetchedFirstFilter, setFetchedFirstFilter] = useState(!fetchedFirstReactionFilter);
    const feedApiPath = FEED_POST_API_PATH.replace('{module_name}', moduleName);

    useEffect(() => {
        if (typeof (initialWritePermissionValue) != 'boolean') {
            initialWritePermissionValue = hasWritePermission;
        }
        if (initialWritePermissionValue != hasWritePermission) {
            initialWritePermissionValue = hasWritePermission;
            notifierRef.current.refClick();
        }
    }, [hasWritePermission])


    /**
     * Method to catch the all getStream error
     * @param {object} streamError 
     * @param {string} errorType 
     * @param {object} requestOption 
     */
    function handleError(streamError, errorType, requestOption) {
        console.log(streamError);
    }

    /**
     * Method to get the feed
     * @param {number} client 
     * @param {number} userId 
     * @param {object} options 
     * This is custom function to override the actual getStream
     * behavior to get the feeds, this will return promise to getStream
     */
    function getFeed(client, userId, options) {
        let path = isLoggedIn ? FEED_GET_API_PATH : PUBLIC_FEED_API_PATH;
        path = path.replace('{object_id}', objectId);
        path = path.replace('{module_name}', moduleName);
        path = path.replace('{feed_type}', feedType);
        path = path.concat('?withReactionCounts=true&withOwnReactions=true')
        path = path.concat('&limit=', options.limit);
        if (options.reactions && options.reactions.recent) {
            path = path.concat('&withRecentReactions=', true)
        }
        if (nextId) {
            path = path.concat('&id_lt=', nextId);
        }
        return instance.get(path, { hideNotification: true }).then(response => {
            nextId = getUrlParam('id_lt', response.data.next);
            if (!nextId && !response.data.results.length) {
                setHasNoContent(true);
            }
            else {
                setHasNoContent(false);
            }
            setHasUserPosted(false);
            return response.data;
        })
    }

    /**
     * Method to get the reactions for a particular activity
     */
    function getReactions(options) {
        if (!fetchedFirstFil && !fetchedFirstFilter && handleGetFeed) {
            setFetchedFirstFilter(true);
            fetchedFirstFil = true;
            notifierRef.current.refClick();
            return { "next": "", "results": [] }
        }
        let path = REACTIONS_GET_API_PATH.replace('{object_id}', objectId);
        path = path.replace('{module_name}', moduleName);
        path = path.replace('{activity_id}', options.activity_id);
        path = path.replace('{reaction_type}', GET_STREAM_REACTION_TYPES.COMMENT);
        path = path.concat('?limit=', 5);
        path = path.concat('&id_lt=', options.id_lt);
        return instance.get(path, { hideNotification: true }).then(response => {
            return response.data;
        })
    }

    function handleNonLoggedIn() {
        setSignInModalVisible(true)
    }

    function handleOnNoPermission() {
        if (handleNoPermission) {
            return handleNoPermission();
        }
        return message.error(`You don't have permission`)
    }

    function resetFeed() {
        nextId = null
    }

    function refreshFeed() {
        setHasUserPosted(true);
        notifierRef.current.refClick();
    }

    function doActivityDeleteRequest(activityId) {
        if(typeof onRemoveActivity !== 'undefined') {
            onRemoveActivity()
        }
        return activityId
    }

    function nonLoggedInHandler() {
        if (hideLoginModal) window.location = `/login?next=${nextUrl}`;
        else handleNonLoggedIn();
      }

    return (
        <div id="feed-wrapper" className={isLoggedIn && !hasNoContent ? 'full-card-radius' : ''}>
            <StreamApp
                apiKey={window.streamApiKey}
                appId={window.streamAppId}
                token={token}
                errorHandler={(response, extra, more, event) => handleError(response, extra, more, event)}
                defaultUserData={{
                    name: 'Unknown'
                }}
            >
                <If condition={hasWritePermission && !hideFeedPostForm}>
                    <FeedPermissionAndEmptyState
                        isNonLoggedIn={!isLoggedIn}
                        handleNonLoggedIn={nonLoggedInHandler}
                        hasNoContent={hasNoContent}
                    />
                    <FeedPostForm
                        feedToken={token}
                        feedApiPath={feedApiPath.replace('{object_id}', objectId)}
                        user={{
                            picture: user.present_picture,
                            name: user.full_name
                        }}
                        isLoggedIn={isLoggedIn}
                        handleNonLoggedIn={() => handleNonLoggedIn()}
                        doRefreshFeed={() => refreshFeed()}
                    />
                </If>
                <FlatFeed
                    notify={isLoggedIn}
                    doReactionsFilterRequest={(options) => getReactions(options)}
                    doFeedRequest={(client, feedGroup, userId, options) => handleGetFeed ? handleGetFeed() : getFeed(client, userId, options)}
                    userId={feedUserId}
                    feedGroup={feedGroup}
                    options={{ reactions: { recent: true, counts: true }, limit: 10 }}
                    Paginator={InfiniteScrollPaginator}
                    doActivityDeleteRequest={doActivityDeleteRequest}
                    doReactionAddRequest={(kind, activity, data) => { return data }}
                    doReactionDeleteRequest={(id) => { return id }}
                    doChildReactionAddRequest={(kind, activity, data) => { return data }}
                    Placeholder={() => null}
                    Notifier={(props) => (
                        <ActivityNotifier
                            {...props}
                            doResetFeed={() => resetFeed()}
                            ref={notifierRef}
                            hasUserPosted={hasUserPosted}
                        />
                    )}
                    Activity={(props) => {
                        return (
                            <Activity {...props}
                                Header={<ActivityHeader
                                    activity={props.activity}
                                    showContentObject={showContentObject}
                                    isLoggedIn={isLoggedIn}
                                    hasWritePermission={hasWritePermission}
                                    moduleName={moduleName}
                                    objectId={objectId}
                                    isAdmin={isAdmin}
                                    userId={user.user_id}
                                    doReactionAddRequest={props.onAddReaction}
                                    doActivityRemoveRequest={props.onRemoveActivity}
                                />}
                                Footer={() => (
                                    <div className="feed-action-wrapper">
                                        <ActivityReactions
                                            moduleName={moduleName}
                                            objectId={objectId}
                                            activity={props.activity}
                                            toggleFunc={props.onToggleReaction}
                                            isLoggedIn={isLoggedIn}
                                            hasWritePermission={hasWritePermission}
                                            handleNonLoggedIn={() => handleNonLoggedIn()}
                                            handleNoPermission={() => handleOnNoPermission()}
                                        />
                                        <div>
                                            <CommentList
                                                activityId={props.activity.id}
                                                reverseOrder={true}
                                                Paginator={(pageProps) => {
                                                    return (
                                                        <LoadMorePaginator
                                                            {...pageProps}
                                                            LoadMoreButton={(childProps) => {
                                                                if (isLoggedIn) {
                                                                    return (
                                                                        <p
                                                                            {...childProps}
                                                                            className="arc-p feed-more-comment"
                                                                        >{(props.activity.highlighted_comment_ids || []).length ? 'View more comments' : 'Load earlier comments'}</p>
                                                                    )
                                                                }
                                                                return (
                                                                    <p
                                                                        className="arc-p feed-more-comment"
                                                                        onClick={() => handleNonLoggedIn()}
                                                                    >Load earlier comments</p>
                                                                )
                                                            }}
                                                        />
                                                    )
                                                }}
                                                CommentItem={(childProps) => {
                                                    return (
                                                        <ActivityCommentItem
                                                            activity={props.activity}
                                                            comment={childProps.comment}
                                                            isAdmin={isAdmin}
                                                            isLoggedIn={isLoggedIn}
                                                            userId={user.user_id}
                                                            hasWritePermission={hasWritePermission}
                                                            doReactionAddRequest={props.onAddChildReaction}
                                                            doReactionRemoveRequest={props.onRemoveReaction}
                                                        />
                                                    )
                                                }}
                                            />
                                            <ActivityCommentField
                                                user={{
                                                    picture: user.present_picture
                                                }}
                                                activity={props.activity}
                                                addFunc={props.onAddReaction}
                                                isLoggedIn={isLoggedIn}
                                                hasWritePermission={hasWritePermission}
                                                handleNonLoggedIn={() => handleNonLoggedIn()}
                                                handleNoPermission={() => handleOnNoPermission()}
                                            />
                                        </div>
                                    </div>
                                )}
                            />)
                    }
                    }

                />
            </StreamApp>
            <If condition={signInModalVisible}>
                <ThemeXSignInModal
                    visible={signInModalVisible}
                    handleClose={() => setSignInModalVisible(false)}
                />
            </If>
        </div>
    )
}

Feed.propTypes = {
    token: string.isRequired,
    feedGroup: string.isRequired,
    moduleName: string.isRequired,
    feedType: string.isRequired,
    objectId: number.isRequired,
    isAdmin: bool.isRequired,
    isLoggedIn: bool.isRequired,
    feedUserId: string.isRequired,
    user: object,
    hasWritePermission: bool,
    handleNoPermission: func,
    handleGetFeed: func,
    hideFeedPostForm: bool,
    showContentObject: bool,
    hideLoginModal:bool
}

Feed.defaultProps = {
    user: {},
    isAdmin: false,
    hasWritePermission: true,
    hideFeedPostForm: false,
    showContentObject: false,
    hideLoginModal: false
}

export default Feed;