/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty, includes, intersection } from 'lodash';
import { Avatar, Button, Divider, Modal, Tooltip } from 'antd';
import AddToCalendar from 'react-add-to-calendar';
import { ALUMNI_BASE_URL, RECEIPT_VIEW_URL } from 'Src/alumniEvents/routes';
import { PRIVACY_SETTINGS, OPEN_TICKET } from 'Src/alumniEvents/constants';
import { INVITE_ONLY_EVENT } from 'Src/adminEvents/constants';
import { getUrlParam } from 'Src/common/utilities/data_util';
import { REGISTRANT_EMAIL_CONFIRMATION_API_PATH, USER_FEEDBACK_API_PATH } from 'Src/alumniEvents/endpoints';
import { openNotificationWithIcon } from 'Src/common/utilities/notification';
import DetailsPageEmptyShell from 'Src/alumniEvents/alumni/common/detailsPageEmptyShell';
import ThemeXShareModal from 'Src/common/components/themeXShareModal';
import instance from 'Src/common/utilities/axios_util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faShare, faPrint, faCalendar, faPaperPlaneTop, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import UserFeedback from 'Src/common/components/UserFeedback/index';
import { getAgendaItems } from 'Src/alumniEvents/utils/app_moment_utils';
import { Share } from 'arcl';
import AddItineraryToCalendar from './modals/addItineraryTocalendar';

import './style.scss';

function Confirmation(props) {
  const authKey = getUrlParam('r') || '';
  const { eventData, registrationData, contextData } = props;
  const [, setIsSending] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [submittedFeedback, setSubmittedFeedback] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isSplashScreenVisible, setIsSplashScreenVisible] = useState(true);
  const eventDetailUrl = ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug);
  const [guestAgendas, setGuestAgendas] = useState([]);
  const [isAddItineraryModalVisible, setIsAddItineraryModalVisible] = useState(false);

  const { isAdminAddingGuest, isAdminEditingGuest } = contextData;

  const guestData = registrationData.guests?.filter((guest) => guest.is_primary)[0];
  const guestCount = eventData.count_guests;
  const nonAnonymousGuestCount = eventData.attending_guest_profiles.filter(
    (profile) => profile.should_show_on_attendee_list,
  ).length;

  if (isEmpty(registrationData) || !registrationData.registration_status) {
    window.open(eventDetailUrl, '_self');
    return false;
  }

  useEffect(() => {
    if (!isSplashScreenVisible) {
      const element = document.getElementById('registration-details-label');
      element?.focus();
    }
  }, [isSplashScreenVisible]);

  function resendConfirmationEmail(eventId, email) {
    const path = REGISTRANT_EMAIL_CONFIRMATION_API_PATH.replace('{}', eventId);
    setIsSending(true);
    instance
      .post(path, { email })
      .then(() => {
        openNotificationWithIcon({
          type: 'success',
          message: 'Email sent successfully.',
        });
        setIsSending(false);
      })
      .catch(() => {
        setIsSending(false);
      });
  }

  function submitFeedback(feedback) {
    const path = USER_FEEDBACK_API_PATH.replace('<object_id>', registrationData.id);
    setSubmittingFeedback(true);
    const data = [
      {
        rating: feedback.rating,
        comment: feedback.comment,
        selected_reasons: [],
        question: { id: window.userFeedbackQuestions[0].id },
      },
    ];
    instance
      .post(path, data, { hideNotification: true })
      .then(() => {
        setSubmittingFeedback(false);
        setSubmittedFeedback(true);
      })
      .catch(() => {
        setSubmittingFeedback(false);
      });
  }

  function getContactEmail() {
    if (!isEmpty(eventData.contact_persons)) {
      const { name, email, phone_number } = eventData.contact_persons[0];
      if (email && phone_number) {
        return `${name} at ${email} or call at ${phone_number}`;
      }
      if (!email && phone_number) {
        return `${name}, call ${phone_number}`;
      }
      if (!phone_number && email) {
        return `${name} at ${email}`;
      }
    }
    return window.collegeEmail;
  }

  function renderAddToCalendar() {
    // eventData.venue_location is a new property on registration Model.
    // It has the venue details after factoring in privacy.
    const editCode = eventData?.edit_key ? `?r=${eventData.edit_key}` : window.location.search;
    const guestKitUrl = `${window.location.origin}/e/${eventData?.slug}/kit/${editCode}&edit_registration=true`;
    const calendarEventObject = {
      title: eventData.name,
      description: `Please check your registration kit for instructions on how to attend the event.
        You can also view your receipt or modify your guests and registration details from here: ${guestKitUrl} \n\nIf you need any assistance, please reach out to ${getContactEmail()}.`,
      startTime: eventData.start_datetime,
      endTime: eventData.end_datetime,
      location: registrationData.venue_location,
    };
    return (
      <AddToCalendar
        event={{ ...calendarEventObject }}
        buttonTemplate={{ calendar: 'left' }}
        buttonLabel="Add to calendar"
        className="arc-focus-outline"
      />
    );
  }

  function toggleShareModal() {
    setIsShareModalVisible(!isShareModalVisible);
  }

  const openTickets = eventData.tickets
    .filter((ticket) => ticket.ticket_type === OPEN_TICKET)
    .map((ticket) => ticket.id);

  const openBoughtTickets = registrationData.tickets
    .filter((item) => includes(openTickets, item.ticket.id))
    .map((item) => ({ id: item.ticket.id, quantity: item.quantity }));

  function getAllBoughtTickets() {
    const seatedBoughtTickets = guestData.tickets.map((item) => item.ticket.id);
    const allBoughtTickets = [...seatedBoughtTickets, ...openBoughtTickets.map((item) => item.id)];
    return allBoughtTickets;
  }

  function setAgendas(tickets) {
    const eventAgendas = eventData.agendas.filter((item) => !item.is_hide_on_itinerary);
    const agendaItems = getAgendaItems(eventAgendas);
    const newGuestAgendas = [];
    agendaItems.forEach((item) => {
      item.data.forEach((agenda) => {
        const agendaTickets = agenda.tickets.map((ticket) => ticket.id);
        if (!!intersection(tickets, agendaTickets).length || !agendaTickets.length) {
          newGuestAgendas.push(agenda);
        }
      });
    });
    setGuestAgendas(newGuestAgendas);
  }

  useEffect(() => {
    setAgendas(getAllBoughtTickets());
    setIsModalVisible(true);
    setTimeout(() => {
      setIsSplashScreenVisible(false);
    }, 2000);
  }, []);

  function getRedirectUrl(slug) {
    const url = new URL(`${window.location.origin}${ALUMNI_BASE_URL.replace(':eventSlug', slug)}`);

    const addGuestParam = getUrlParam('add_guest');
    const fromAdminParam = getUrlParam('from_admin');

    if (addGuestParam === 'true') {
      url.searchParams.append('add_guest', 'true');
    }
    if (fromAdminParam === 'true') {
      url.searchParams.append('from_admin', 'true');
    }
    if (eventData.type === INVITE_ONLY_EVENT) {
      return window.location.href.replace('confirmation', '').concat('&edit_registration=true');
    }
    return url.href;
  }

  return (
    <div className="confirmation-page-container">
      <DetailsPageEmptyShell eventData={eventData} />
      <Modal
        title=""
        wrapClassName="confirmation-modal"
        visible={isModalVisible}
        footer={null}
        closable={false}
        centered>
        <If condition={isSplashScreenVisible}>
          <div className="animation-wrapper">
            <div className="check-container-wrapper">
              <div className="check-container">
                <FontAwesomeIcon icon={faCheck} className="check-icon" />
              </div>
            </div>
            {/* <p className="arc-H700 registration-successful-label">Registered</p> */}
          </div>
        </If>
        <If condition={!isSplashScreenVisible}>
          <div className="registration-details">
            <div className="registration-confirmation-check-icon-wrapper mb16 mt32">
              <FontAwesomeIcon icon={faCheck} className="registration-confirmation-check-icon" />
            </div>
            <p className="arc-H300 arc-color-success" id="registration-details-label" tabIndex={-1} aria-live="polite">
              <Choose>
                <When condition={isAdminAddingGuest || isAdminEditingGuest}>
                  <I18nCustomFormatter id="registration-details-by-admin-label" />
                </When>
                <Otherwise>
                  <I18nCustomFormatter id="registration-details-label" />
                </Otherwise>
              </Choose>
            </p>
            <If condition={!isAdminAddingGuest && !isAdminEditingGuest}>
              <p className="arc-p arc-color-B65 mt8 registration-details-description">
                <I18nCustomFormatter
                  id="event-registration-details-description"
                  values={{
                    email: registrationData.email,
                  }}
                />
              </p>
            </If>
          </div>
          <If
            condition={
              eventData.venue_privacy === PRIVACY_SETTINGS.PUBLIC && !isAdminAddingGuest && !isAdminEditingGuest
            }>
            <Choose>
              <When condition={eventData.is_pro && !isEmpty(guestAgendas)}>
                <div className="add-to-calendar-wrapper mt16">
                  <Button
                    block
                    className="arc-btn-light light-add-calendar-btn arc-focus-outline"
                    onClick={() => setIsAddItineraryModalVisible(true)}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <span className="ml8">Add to calendar</span>
                  </Button>
                </div>
              </When>
              <Otherwise>
                <div className="add-to-calendar-wrapper">{renderAddToCalendar()}</div>
              </Otherwise>
            </Choose>
          </If>

          <If condition={!isEmpty(window.userFeedbackQuestions) && !registrationData.is_feedback_submitted}>
            <Divider />
            <UserFeedback
              question={{
                main: window.userFeedbackQuestions[0].feedback_question,
                followUp: {
                  positive: window.userFeedbackQuestions[0].reason_question_positive.question,
                  negative: window.userFeedbackQuestions[0].reason_question_negative.question,
                },
              }}
              onSubmit={(feedback) => submitFeedback(feedback)}
              submitting={submittingFeedback}
              submittedFeedback={submittedFeedback}
            />
          </If>

          <If
            condition={
              eventData.is_guest_count_shown_to_users && guestCount && !isAdminAddingGuest && !isAdminEditingGuest
            }>
            <Divider />
            <div className="event-latest-attendees-wrapper">
              <div className="event-latest-attendees mr8">
                {eventData.attending_guest_profiles.map((attendee, index) => (
                  <Avatar
                    src={attendee.matched_profile && attendee.matched_profile.present_picture}
                    icon={<FontAwesomeIcon icon={faUser} />}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className="attendee-avatar"
                  />
                ))}
              </div>
              <span className="arc-p others-attending-label">
                <Choose>
                  <When condition={guestCount <= 2 && nonAnonymousGuestCount === guestCount}>
                    <I18nCustomFormatter
                      id="event-guest-is-attending"
                      values={{
                        full_name: eventData.attending_guest_profiles[0].full_name,
                        second_full_name:
                          nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].full_name : null,
                        non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                      }}
                    />
                  </When>
                  <When condition={nonAnonymousGuestCount === 0}>
                    <I18nCustomFormatter
                      id="guest-counts-attending-numbers"
                      values={{
                        guests: guestCount,
                      }}
                    />
                  </When>
                  <Otherwise>
                    <I18nCustomFormatter
                      id="event-guests-more-than-one-prefix"
                      values={{
                        full_name: eventData.attending_guest_profiles[0].first_name,
                        second_full_name:
                          nonAnonymousGuestCount >= 2 ? eventData.attending_guest_profiles[1].first_name : null,
                        non_anonymous_guests: Math.min(nonAnonymousGuestCount, 2),
                      }}
                    />{' '}
                    <span className="guest-list-trigger">
                      <I18nCustomFormatter
                        id="guest-counts-attending"
                        values={{
                          guests: guestCount - Math.min(nonAnonymousGuestCount, 2),
                        }}
                      />
                    </span>
                  </Otherwise>
                </Choose>
              </span>
            </div>
          </If>
          <If condition={eventData.is_share_enabled && !isAdminAddingGuest && !isAdminEditingGuest}>
            <div className="arc-d-flex arc-flex-justify-center mt16">
              <Share
                title="Share"
                trigger="click"
                url={`${window.location.origin}/e/${eventData.slug}`}
                disableNativeShare>
                <Button
                  type="link"
                  className="arc-color-primary link-btn arc-focus-outline"
                  style={{ width: 'fit-content' }}>
                  <FontAwesomeIcon icon={faShare} className="mr8" />
                  <I18nCustomFormatter id="confirmation-share-label" />
                </Button>
              </Share>
            </div>
          </If>
          <Divider />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={RECEIPT_VIEW_URL.replace(':eventSlug', eventData.slug).concat(`?r=${authKey}`)}
            className="arc-color-primary arc-d-block arc-focus-outline">
            <FontAwesomeIcon icon={faPrint} className="mr8" />
            <I18nCustomFormatter id="confirmation-receipt-print-label" />
          </a>
          <Divider />
          <If condition={registrationData.email}>
            <div className="pl24 pr24">
              <Button
                type="link"
                className="arc-p arc-color-primary arc-d-inline-block mb16 arc-focus-outline link-btn"
                onClick={() => resendConfirmationEmail(eventData.id, registrationData.email)}>
                <FontAwesomeIcon icon={faPaperPlaneTop} className="mr8" />
                <Choose>
                  <When condition={isAdminAddingGuest || isAdminEditingGuest}>
                    <I18nCustomFormatter
                      id="send-confirmation-by-admin-label"
                      values={{ email: registrationData.email }}
                    />
                  </When>
                  <Otherwise>
                    <I18nCustomFormatter id="resend-confirmation-label" />
                  </Otherwise>
                </Choose>
              </Button>
              <Tooltip title={`This will send an email to ${registrationData.email} with details of the registration`}>
                <FontAwesomeIcon icon={faInfoCircle} className="ml8" />
              </Tooltip>
            </div>
          </If>
          <div className="pl24 pr24 mt16">
            <Button
              block
              className="mb16 arc-focus-outline"
              type="primary"
              onClick={() => {
                window.location.href = getRedirectUrl(eventData.slug);
              }}>
              Done
            </Button>
          </div>
        </If>
      </Modal>
      <If condition={isShareModalVisible}>
        <ThemeXShareModal
          visible={isShareModalVisible}
          handleClose={toggleShareModal}
          url={`${window.location.origin}/e/${eventData.slug}`}
        />
      </If>
      <If condition={isAddItineraryModalVisible}>
        <AddItineraryToCalendar
          agendas={guestAgendas}
          onClose={() => setIsAddItineraryModalVisible(false)}
          getContactEmail={getContactEmail}
          eventData={eventData}
          registrationData={registrationData}
        />
      </If>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
});

export default connect(mapStateToProps, null)(Confirmation);
