const initialState = {
    data: {},
}

const SET_HERO_BANNER_DATA = 'EVENT/SET_HERO_BANNER_DATA'

export const setHeroBannerData = (data) => {
    return {
        type: SET_HERO_BANNER_DATA,
        payload: data
    }
}

const heroBannerDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HERO_BANNER_DATA:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
}

export default heroBannerDataReducer;