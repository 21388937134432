import React from 'react';
import { array } from 'prop-types';
import { Timeline } from 'antd';
import { map } from 'lodash';

import { getFormattedDate } from 'Src/common/utilities/moment_util';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { getAgendaItems } from '../../../../../utils/app_moment_utils';
import MDEditor from '@uiw/react-md-editor';
import './style.scss';

const ScheduleTab = ({ agendas }) => {
  const agendaItems = getAgendaItems(agendas);

  return (
    <div className="tab-content-body" id="schedule-timeline">
      {map(agendaItems, (agenda) => (
        <div className="schedule-timeline-item" key={getFormattedDate(agenda._d, 'X')}>
          <div className="schedule-timeline-item-header">
            <p aria-hidden="true" className="schedule-item-day arc-H100 arc-color-B65">
              {getFormattedDate(agenda._d, 'ddd')}
            </p>
            <p aria-hidden="true" className=" schedule-item-date arc-H200 arc-color-B85">
              {getFormattedDate(agenda._d, 'MMM D, YYYY')}
            </p>
            <h2 className="schedule-item-date-hidden" aria-hidden="false">
              {getFormattedDate(agenda._d, 'dddd MMM D, YYYY')}
            </h2>
          </div>
          <div className="schedule-timeline-item-body">
            <Timeline>
              {map(agenda.data, (item) => (
                <Timeline.Item key={item.id}>
                  <p className="schedule-timeline-item-time arc-support arc-color-B85">
                    {`${getFormattedDate(item.start_datetime, 'hh:mm a')} - ${getFormattedDate(
                      item.end_datetime,
                      'hh:mm a',
                    )}`}
                  </p>
                  <p className=" schedule-timeline-item-content schedule-timeline-item-name arc-H150 arc-color-B85">
                    {item.name}
                  </p>
                  {/* <p className="schedule-timeline-item-content arc-p arc-color-B45"> */}
                  <div data-color-mode="light" className="sub-event-description-container">
                    <MDEditor.Markdown
                      source={item.description}
                      linkTarget="_blank"
                      previewOptions={{
                        linkTarget: '_blank',
                      }}
                    />
                  </div>
                  {/* </p> */}
                  <If condition={item.venue}>
                    <p className="schedule-timeline-item-content arc-p arc-color-B45">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr8" />
                      <span>{item.venue}</span>
                    </p>
                  </If>
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
        </div>
      ))}
    </div>
  );
};

ScheduleTab.propTypes = {
  agendas: array,
};

ScheduleTab.defaultProps = {
  agendas: [],
};

export default ScheduleTab;
