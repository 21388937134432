import anchorme from 'anchorme';
import { uniq } from 'lodash';
import instance from './../../../utilities/axios_util';

import { GET_STREAM_OG_FETCH_API } from './../../../endpoints';

export const getUrlOpenGraphData = (url, token, apiKey) => {
    let apiUrl = GET_STREAM_OG_FETCH_API.replace('{}', apiKey);
    apiUrl = apiUrl.replace('{url}', url);
    const headers = {
        Authorization: token
    };
    return instance.get(apiUrl, { headers, hideNotification: true })
}

export const getLinksFromText = (text) => {
    const urlInfos = anchorme(text, {
        list: true,
        exclude: (info) => {
            return info.protocol !== 'https://' && info.protocol !== 'http://';
        }
    });
    return uniq(urlInfos.map((info) => {
        return info.protocol + info.encoded;
    }));
}