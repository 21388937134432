import moment from 'moment';
import { map, groupBy, sortBy } from 'lodash';

// Method to group and sort the agenda items by start day of agenda
export const getAgendaItems = (agendaItems) => {
  const groupedByDateAgendaItems = groupBy(agendaItems, (agenda) => {
    return moment.parseZone(agenda.start_datetime).startOf('day').format();
  });

  const mappedAgendaItems = map(groupedByDateAgendaItems, (items, key) => {
    return {
      _d: key,
      data: sortBy(items, (item) => {
        return moment(item.start_datetime).valueOf();
      }),
    };
  });

  const sortedAgendaItems = sortBy(mappedAgendaItems, (item) => {
    return new Date(item._d).getTime();
  });

  return sortedAgendaItems;
};
