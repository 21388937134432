import React, { PureComponent } from 'react';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Input, Menu, Dropdown, Button } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { Share } from 'arcl';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';

import { getEventGuestList } from 'Src/alumniEvents/actions';

import ThemeXModal from 'Src/common/components/themeXModal';
import ThemeXProfileDetailItem from 'Src/common/components/themeXProfileDetailItem';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { debounce, isEmpty } from 'lodash';
import { parseValue } from 'Src/common/utilities/data_util';

const INITIAL_PAGE = 1;

class GuestListModal extends PureComponent {
  searchGuests = debounce(this.handleGuestList, 500);

  constructor(props) {
    super(props);
    this.state = {
      guests: [],
      loading: true,
      hasMore: true,
      guestCount: 0,
      searchTerm: '',
      orderingValue: '-',
    };
  }

  componentDidMount() {
    this.handleGuestList();
  }

  handleGuestSort(orderingValue) {
    const { searchTerm } = this.state;
    this.handleGuestList(INITIAL_PAGE, searchTerm, orderingValue);
  }

  handleInfiniteOnLoad = () => {
    const { page, loading } = this.state;
    if (!loading) {
      this.handleGuestList(page + 1);
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  handleGuestList(page = INITIAL_PAGE, searchTerm = this.state.searchTerm, orderingValue = this.state.orderingValue) {
    this.setState({ loading: true });
    const { eventId } = this.props;
    const { guests } = this.state;
    getEventGuestList(eventId, page, searchTerm, orderingValue)
      .then((response) => {
        let guestsL = [];

        if (page !== 1) {
          guestsL = guests;
        }

        this.setState({
          guests: [...guestsL, ...response.data.results],
          loading: false,
          hasMore: !!response.data.next,
          guestCount: response.data.count,
          searchTerm,
          page,
          orderingValue,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  getFieldDescriptorValue = (descriptorValue) => {
    if (descriptorValue) {
      return <p className="arc-p arc-color-B55">{descriptorValue?.verbose_name || parseValue(descriptorValue)}</p>;
    }
    return null;
  };

  render() {
    const { guests, loading, hasMore, guestCount, orderingValue } = this.state;
    const { handleClose, isShareEnabled } = this.props;
    const sortMenu = (
      <Menu onClick={(e) => this.handleGuestSort(e.key)} selectedKeys={[orderingValue]}>
        <Menu.Item key="-created_at">
          <p>Most Recent</p>
        </Menu.Item>
        <Menu.Item key="first_name">
          <p>Alphabetical (First Name)</p>
        </Menu.Item>
        <Menu.Item key="last_name">
          <p>Alphabetical (Last Name)</p>
        </Menu.Item>
      </Menu>
    );
    return (
      <ThemeXModal
        visible
        modalTitle={
          <span aria-live="polite" role="heading" aria-level="2" id="search-attendees-input">
            <I18nCustomFormatter
              id="guest-counts-attending-heading"
              values={{
                guests: guestCount,
              }}
            />
          </span>
        }
        wrapClassName="guest-list-modal"
        handleCancel={() => handleClose()}>
        <Row type="flex" align="middle" justify="space-between" id="guest-search-header">
          <Col>
            <Input
              className="custom-input-search"
              prefix={<SearchOutlined />}
              bordered={false}
              placeholder="Search"
              aria-controls="search-attendees-input"
              onChange={(e) => this.searchGuests(INITIAL_PAGE, e.target.value)}
            />
          </Col>
          <Col>
            <Dropdown placement="bottomRight" overlay={sortMenu} className="guest-sort-menu">
              <Button type="link" className="link-btn arc-focus-outline">
                <FontAwesomeIcon icon={faSort} />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Choose>
          <When condition={isEmpty(guests)}>
            <If condition={!loading}>
              <div className="no-guest-block">
                <div className="arc-H200 mb12 arc-color-black" aria-live="polite" id="search-attendees-input">
                  No Guests Found
                </div>
                <Share
                  title="Share"
                  trigger="click"
                  url={`${window.location.origin}${window.location.pathname}`}
                  disableNativeShare>
                  <Button type="link" className="link-btn arc-focus-outline">
                    Invite a friend
                  </Button>
                </Share>
              </div>
            </If>
          </When>
          <Otherwise>
            <div className="guest-list-block">
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={this.handleInfiniteOnLoad}
                hasMore={!loading && hasMore}
                useWindow={false}>
                {guests.map((guest, index) => {
                  const matchedProfile = guest.matched_profile;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="guest-list-item" key={index}>
                      <ThemeXProfileDetailItem
                        src={matchedProfile && matchedProfile.present_picture}
                        title={guest.full_name ? guest.full_name : matchedProfile?.formatted_name || ''}
                        url={matchedProfile ? matchedProfile.profile_url : ''}
                        extra={this.getFieldDescriptorValue(guest.highlighted_value)}
                        size="large"
                      />
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </Otherwise>
        </Choose>
        <If condition={!isEmpty(guests) && isShareEnabled}>
          <div className="share-button-wrapper">
            <Share
              title="Share"
              trigger="click"
              url={`${window.location.origin}${window.location.pathname}`}
              disableNativeShare>
              <Button type="link" className="share-card-icon-text link-btn arc-focus-outline">
                <FontAwesomeIcon icon={faShareAlt} className="mr12" />
                <span className="arc-H100">INVITE A FRIEND</span>
              </Button>
            </Share>
          </div>
        </If>
        <div id="guest-modal-loading-status" role="status" aria-live="polite" className="arc-visibility-hidden">
          {loading && 'Loading Guests'}
        </div>
        <If condition={loading}>
          <div className="guest-list-loader-wrapper">
            <LoadingOutlined id="guest-list-loader" spin />
          </div>
        </If>
      </ThemeXModal>
    );
  }
}

export default GuestListModal;
