import { REDUX_STATUSES } from '../../common/constants';
import store from '../store';
import instance from '../../common/utilities/axios_util';
import { CUSTOM_FORMS_LIST_API_PATH } from '../endpoints';

const initialState = {
    data: [],
    formId: null,
    status: REDUX_STATUSES.FETCHING
}

const REQUEST_GUEST_FORM_LAYOUT = 'EVENT/REQUEST_GUEST_FORM_LAYOUT';
const SET_GUEST_FORM_LAYOUT = 'EVENT/SET_GUEST_FORM_LAYOUT'

export const requestGuestFormLayout = (formId) => {
    return {
        type: REQUEST_GUEST_FORM_LAYOUT,
        payload: formId
    }
}

export const setGuestFormLayout = (layout) => {
    return {
        type: SET_GUEST_FORM_LAYOUT,
        payload: layout
    }
}

const fetchGuestFormLayout = formId => {
    const path = CUSTOM_FORMS_LIST_API_PATH.concat(formId, '/?fields=fields')
    instance.get(path).then((response) => {
        store.dispatch(setGuestFormLayout(response.data.fields || []));
    })
    .catch((error) => {});
}

const guestFormLayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_GUEST_FORM_LAYOUT:
            fetchGuestFormLayout(action.payload);
            return {
                ...state,
                formId: action.payload
            };
        case SET_GUEST_FORM_LAYOUT:
            return {
                ...state,
                data: action.payload,
                status: REDUX_STATUSES.FETCHED
            };
        default:
            return state;
    }
}

export default guestFormLayoutReducer;