import React, { useState } from 'react';
import DivButton from 'Src/common/components/controller/buttons/buttonController';
import { Modal } from 'antd';
import { QRCode } from 'react-qrcode-logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { shape, string } from 'prop-types';
import './style.scss';

function QrCodeComp({ qrCodeConfig }) {
  return (
    <QRCode size={qrCodeConfig.size || 150} bgColor={qrCodeConfig.bgColor || '#f3f3f3'} value={qrCodeConfig.value} />
  );
}

QrCodeComp.propTypes = {
  qrCodeConfig: shape().isRequired,
};

function QrCodeGenerator({ qrCodeConfig, openedQrCodeConfig, title, supportText }) {
  const [isQrModalVisible, setIsQrModalVisible] = useState(false);

  return (
    <>
      <div className="qr-code-check-in-wrapper">
        <div className="content-wrapper">
          <div>
            <div className="main arc-H200 arc-color-black">{title}</div>
            <div className="support mt8">{supportText}</div>
          </div>
          <DivButton className="open-qr-code arc-color-primary" onClick={() => setIsQrModalVisible(true)}>
            Open QR
            <FontAwesomeIcon icon={faArrowRightLong} className="ml8" />
          </DivButton>
        </div>
        <div className="qr-code-wrapper">
          <QrCodeComp qrCodeConfig={qrCodeConfig} />
        </div>
      </div>
      <Modal
        visible={isQrModalVisible}
        className="opened-qr-wrapper"
        footer={null}
        closable={false}
        onCancel={() => setIsQrModalVisible(false)}>
        <div className="opened-qr-content">
          <DivButton className="close-btn" onClick={() => setIsQrModalVisible(false)}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </DivButton>
          <div className="qr-code-wrapper">
            <QrCodeComp qrCodeConfig={openedQrCodeConfig} />
          </div>
        </div>
      </Modal>
    </>
  );
}

QrCodeGenerator.propTypes = {
  qrCodeConfig: shape().isRequired,
  openedQrCodeConfig: shape().isRequired,
  title: string.isRequired,
  supportText: string,
};

QrCodeGenerator.defaultProps = {
  supportText: '',
};

export default QrCodeGenerator;
