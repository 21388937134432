import React, { Component } from 'react';
import { object, string, func } from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Input, Checkbox, message, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { includes, isEmpty, max, compact, find, keys, values } from 'lodash';
import pluralize from 'pluralize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faTimes, faMinus, faPlus, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { readableNumber } from 'Src/common/utilities/data_util';

import { setRegistrationData } from '../../../reducers/registrationDataReducer';
import { SEATED_TICKET, SPONSORED_TICKET, MAX_GUEST_ALLOWED } from '../../../constants';
import { getMandatoryTickets, getReqGuestId } from '../../../utils';
import ModalHeader from '../ModalHeader';
import { ConfirmModal } from '../confirmModal';
import OpenTicketOptions from './openTicketOptions';
import SeatedTicketOptions from './seatedTicketOptions';
import { setTicketDetailsData } from '../../../reducers/ticketDetailsDataReducer';

import './style.scss';

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

const COUNTER_ACTIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
};

let restrictedGuests = [];
let alreadyBoughtTicketCount = 0;
let boughtTicketsCount = 0;
let availableTicketCount = -1;
let limitPerUser = -1;

class TicketsModal extends Component {
  constructor(props) {
    super(props);

    const { ticket } = props;
    availableTicketCount = ticket.count_available_tickets !== null ? ticket.count_available_tickets : -1;
    limitPerUser = ticket.limit_per_user !== null ? ticket.limit_per_user : -1;

    this.state = {
      ticketCount: this.getInitialTicketCount(),
      guests: this.mapGuests(),
      selectedGuests: this.getInitialGuests(),
      showGuestAdditionBox: false,
      requiredGuestCount: 0,
      newAddedGuestTmpIds: [],
      maxGuestAllowed: props.eventData.max_guests_allowed || MAX_GUEST_ALLOWED,
      dependtedTickets: [],
      ticketEntries: props?.ticketDetailsData?.ticketEntries?.[ticket.id]
        ? props?.ticketDetailsData?.ticketEntries[ticket.id]
        : {},
    };
  }

  componentDidMount() {
    if ([SPONSORED_TICKET, SEATED_TICKET].indexOf(this.props.ticket.ticket_type) !== -1) {
      const guestCount = this.props.form.getFieldValue('guests').length;
      const { t, requiredGuestCount } = this.getTicketAndRequiredGuestCount(guestCount);

      this.setState({ requiredGuestCount });
    }
  }

  componentWillUnmount() {
    restrictedGuests = [];
    boughtTicketsCount = 0;
    alreadyBoughtTicketCount = 0;
  }

  /**
   * Method to get the initial guests and only for seated tickets
   * For edit ticket, it will prefill the guests based on edit data otherwise based on maxGuestsAllowed
   * and maxGuestsAllowed = ticket count * ticket seats
   */
  getInitialGuests() {
    const { ticket } = this.props;
    const guests = this.mapGuests();
    const guestIds = guests.filter((g) => includes(g.guest_tickets, ticket.id)).map((i) => i.tmp_id);
    if ([SPONSORED_TICKET, SEATED_TICKET].indexOf(ticket.ticket_type) !== -1 && !guestIds.length) {
      const ticketCount = this.getNonBoughtInitialTicketCount();
      const maxGuestsAllowed = ticketCount <= 0 ? guests.length : ticketCount * ticket.number_of_guest_allowed;
      guests.filter((guest) => {
        if (!includes(restrictedGuests, guest.tmp_id) && guestIds.length < maxGuestsAllowed) {
          guestIds.push(guest.tmp_id);
        }
      });
    }
    return guestIds;
  }

  /**
   * Method to get the initial ticket count, for edit it will be based on guest chooses before
   * or if number of guest allowed equal to 1, return guest count
   * otherwise 1
   */
  getInitialTicketCount() {
    const { registrationData, ticket } = this.props;
    let registrationTicket = registrationData?.tickets?.filter((item) => item.ticket.id === ticket.id)[0] || null;
    if (registrationTicket) {
      alreadyBoughtTicketCount = registrationTicket.quantity;
      return alreadyBoughtTicketCount;
    }
    if ([SPONSORED_TICKET, SEATED_TICKET].indexOf(this.props.ticket.ticket_type) !== -1) {
      boughtTicketsCount = this.getNonBoughtInitialTicketCount();
    } else {
      boughtTicketsCount = 0;
    }
    return boughtTicketsCount;
  }

  getNonBoughtInitialTicketCount() {
    const registeredGuestCount = this.props.registrationData.guests?.filter((guest) => guest.id);
    const guestCount = registeredGuestCount.length || this.props.registrationData.guests.length;
    const seatCount = this.props.ticket.number_of_guest_allowed;
    let ticketCount = Math.floor(guestCount / seatCount);
    // If ticket count is greater than available ticket count then set max ticket count set to available ticket count
    if (availableTicketCount > -1 && ticketCount > availableTicketCount) {
      ticketCount = availableTicketCount;
    }
    // If ticket count is greater than limit per user then set max ticket count set to limit per user
    if (limitPerUser > -1 && ticketCount > limitPerUser) {
      ticketCount = limitPerUser;
    }
    return ticketCount;
  }

  mapGuests() {
    return (
      (this.props.registrationData &&
        this.props.registrationData.guests.map((guest) => {
          const { full_name, first_name, last_name, tmp_id, id } = guest;
          return {
            first_name,
            last_name,
            full_name,
            tmp_id,
            id,
            guest_tickets: guest.tickets.map((item) => item.ticket.id),
          };
        })) ||
      []
    );
  }

  /**
   * Method to handle the counter action on ticket
   * @param {boolean} counterAction
   */
  handleTicketCounter(counterAction, selectedOption) {
    let { ticketCount } = this.state;
    let valueToAdd = -1;
    if (counterAction === COUNTER_ACTIONS.ADD) {
      valueToAdd *= -1;
      if (this.canBuyMoreTicketQuantity()) {
        ticketCount += 1;
        boughtTicketsCount += 1;
      } else {
        message.error('You have reached the maximum limit', 0.5);
      }
    } else if (ticketCount != 0) {
      ticketCount -= 1;
      boughtTicketsCount -= 1;
    }
    if (selectedOption) this.handleOpenTicketOption(selectedOption, valueToAdd);
    this.setState({ ticketCount });
  }

  handleOpenTicketOption = (selectedOption, valueToAdd) => {
    let temp = { ...this.state.ticketEntries };
    if (temp) {
      if (temp[selectedOption]) {
        temp[selectedOption] += valueToAdd;
      } else {
        Object.assign(temp, { [selectedOption]: valueToAdd < 0 ? 0 : valueToAdd });
      }
    } else {
      Object.assign(temp, {
        [selectedOption]: valueToAdd < 0 ? 0 : valueToAdd,
      });
    }
    this.setState({ ticketEntries: { ...temp } });
  };

  shouldShowOptions = () => {
    return !isEmpty(this.props.ticket.fields);
  };

  showDisableUpdateCartButton = () => {
    if (this.shouldShowOptions() && [SPONSORED_TICKET, SEATED_TICKET].includes(this.props.ticket.ticket_type)) {
      let shouldDisable = false;
      for (let guest of this.getSelectedGuests()) {
        const { tmp_id, id } = guest;
        if (!keys(this.state.ticketEntries).includes(getReqGuestId(tmp_id, id))) {
          shouldDisable = true;
          break;
        }
      }
      for (let value of values(this.state.ticketEntries)) {
        if (!isEmpty(this.props.ticket.fields)) {
          if (!this.props.ticket.fields[0]['choices'].map((data) => data.name).includes(value)) {
            shouldDisable = true;
            break;
          }
        }
      }
      return shouldDisable;
    }
    return false;
  };

  handleSeatedTicketOption = (guestId, guestTempId, choice) => {
    let temp = { ...this.state.ticketEntries };
    Object.assign(temp, { [getReqGuestId(guestTempId, guestId)]: choice });
    this.setState({ ticketEntries: temp });
  };

  getNameForUnnamedGuest() {
    // goes through all unnamed guests and returns a name for new guest
    // if not unnamed guest is present, return Guest 1 of <primary_guest.name> else
    // return Guest <no_of_unnamed_guests + 1> of <primary_guest.name>
    // regex - we are matching first_name with guest of <d> and figure out what is the highest number
    const currentGuests = compact(this.state.guests.map((val) => val.full_name?.match(/^Guest (\d+) of/)));
    // val[1] -> because index will be returned on index 1, here index is number of unnamed guest
    const newGuestIndex = isEmpty(currentGuests) ? 1 : max(currentGuests.map((val) => parseInt(val[1], 10))) + 1;
    return `Guest ${newGuestIndex} of ${this.props.registrationData.full_name}`;
  }

  addGuest() {
    // While adding the guest, if ticket type is sponsorship ticket, we can allow user to add guest without a name
    // when user adds a guest without a name, we will look for all guests, if not unnamed guest added we will add a guest with a name
    // with guest 1 of <primary_guest.name> else we will add guest with name guest <no_of_unnamed_guests + 1> of <primary_guest.name>
    if (this.props.ticket.ticket_type === SPONSORED_TICKET) {
      let guestName = this.props.form.getFieldValue('new_guest');
      if (!guestName) {
        guestName = this.getNameForUnnamedGuest();
        this.props.form.setFieldsValue({ new_guest: guestName });
      }
    }
    this.props.form.validateFields(['new_guest'], (err, values) => {
      if (!err) {
        const result = this.validateSeatedTicketCount();
        if (result.canBuyTicket) {
          const { guests, newAddedGuestTmpIds } = this.state;
          const latestTmpId =
            Math.max.apply(
              Math,
              guests.map((o) => o.tmp_id),
            ) + 1;
          let guestFirstName = values?.new_guest?.split(' ')[0];
          let guestLastName = values?.new_guest
            ?.split(' ')
            .filter((_, idx) => idx !== 0)
            .join(' ');
          guests.push({
            first_name: guestFirstName || '',
            last_name: guestLastName || '',
            full_name: values.new_guest || '',
            tmp_id: latestTmpId,
          });
          newAddedGuestTmpIds.push(latestTmpId);
          this.updateTicketCountAndGuests(guests, latestTmpId, result.ticketCount);
          this.props.form.resetFields(['new_guest']);
          this.setState({ showGuestAdditionBox: false, newAddedGuestTmpIds });
        } else {
          message.error('You have reached the maximum limit', 0.5);
        }
      }
    });
  }

  validateSeatedTicketCount() {
    if (!this.canBuyMoreTicketQuantity()) {
      return { canBuyTicket: false };
    }

    const guestCount = this.state.selectedGuests.length + 1;
    const cartTicketQuantity = this.state.ticketCount;
    const { ticketCount, r } = this.getTicketAndRequiredGuestCount(guestCount);

    if (cartTicketQuantity < ticketCount) {
      boughtTicketsCount += 1;
    }

    if (cartTicketQuantity > ticketCount) {
      boughtTicketsCount -= 1;
    }

    return { canBuyTicket: true, ticketCount };
  }

  /**
   * Method to update the ticket count and selected guests based on guest add or remove.
   * @param {array} guests
   * @param {integer} guestId
   */
  updateTicketCountAndGuests(guests, guestId) {
    const { selectedGuests } = this.state;
    selectedGuests.push(guestId);

    const { ticketCount, requiredGuestCount } = this.getTicketAndRequiredGuestCount(selectedGuests.length);

    this.setState({
      guests,
      selectedGuests,
      ticketCount,
      requiredGuestCount,
    });
  }

  handleGuestChange(values) {
    const guestCount = values.length;
    const cartTicketQuantity = this.state.ticketCount;
    const { ticketCount, requiredGuestCount } = this.getTicketAndRequiredGuestCount(guestCount);

    if (cartTicketQuantity > ticketCount) {
      boughtTicketsCount -= 1;
    } else if (this.canBuyMoreTicketQuantity()) {
      if (cartTicketQuantity < ticketCount) {
        boughtTicketsCount += 1;
      }
    } else {
      message.error('You have reached the maximum limit', 0.5);
      return false;
    }

    this.setState({
      ticketCount,
      requiredGuestCount,
      availableTicketCount,
      selectedGuests: values,
    });
  }

  // Method to get the bought ticket count and required guest count
  getTicketAndRequiredGuestCount(guestCount) {
    const seatCount = this.props.ticket.number_of_guest_allowed;
    const remainder = guestCount / seatCount;
    const ticketCount = Math.floor(remainder);
    let requiredGuestCount = 0;

    if (remainder % 1 != 0) {
      requiredGuestCount = (ticketCount + 1) * seatCount - guestCount;
    }

    return { ticketCount, requiredGuestCount };
  }

  // Method to toggle the guest in restrictedGuests based on action
  handleGuestCheckboxChange(targetObj) {
    const { value } = targetObj;
    const index = restrictedGuests.indexOf(value);
    if (targetObj.checked && index > -1) {
      restrictedGuests.splice(index, 1);
    }
    if (!targetObj.checked && index === -1) {
      restrictedGuests.push(value);
    }
    if (!this.canBuyMoreTicketQuantity()) {
      return false;
    }
  }

  // Method to get the selected guests
  getSelectedGuests() {
    if ([SPONSORED_TICKET, SEATED_TICKET].indexOf(this.props.ticket.ticket_type) !== -1) {
      return this.state.guests.filter((guest) => includes(this.state.selectedGuests, guest.tmp_id));
    }
    return [];
  }

  toggleShowGuestAdditionBox() {
    const { maxGuestAllowed } = this.state;
    if (this.state.guests.length < maxGuestAllowed) {
      this.setState({ showGuestAdditionBox: !this.state.showGuestAdditionBox });
    } else {
      message.error(`You can not add more than ${maxGuestAllowed} guests`);
    }
  }

  getTicketTotal() {
    return parseFloat(this.props.ticket.price || 0) * this.state.ticketCount;
  }

  getDisabledState(guestTmpId) {
    if (!this.canBuyMoreTicketQuantity()) {
      return includes(restrictedGuests, guestTmpId);
    }
    return false;
  }

  canBuyMoreTicketQuantity() {
    let canBuy = true;
    if (availableTicketCount > -1) {
      canBuy = boughtTicketsCount < availableTicketCount;
    }
    if (canBuy && limitPerUser > -1) {
      canBuy = alreadyBoughtTicketCount + boughtTicketsCount < limitPerUser;
    }
    return canBuy;
  }

  deleteNewGuest(e, guestId) {
    e.preventDefault();
    const { guests, newAddedGuestTmpIds, selectedGuests, ticketEntries } = this.state;
    const guestIndex = guests.findIndex((guest) => guest.tmp_id === guestId);
    const selectedGuestIndex = selectedGuests.indexOf(guestId);
    const newAddedGuestIndex = newAddedGuestTmpIds.indexOf(guestId);

    if (guestIndex > -1) {
      guests.splice(guestIndex, 1);
    }

    if (selectedGuestIndex > -1) {
      selectedGuests.splice(selectedGuestIndex, 1);
      this.handleGuestChange(selectedGuests);
    }

    if (newAddedGuestIndex) {
      newAddedGuestTmpIds.splice(newAddedGuestIndex, 1);
    }

    let temp = { ...ticketEntries };
    if (temp[guestId]) {
      delete temp[guestId];
    }

    this.setState({
      guests,
      newAddedGuestTmpIds,
      ticketEntries: temp,
    });
  }

  getAllDependentTickets = (ticketData, final) => {
    if (ticketData) {
      let allDependentTickets = [];
      const { registrationData, eventData } = this.props;
      for (const item of registrationData.tickets) {
        const tempTicket = find(eventData.tickets, ({ id }) => id === item.ticket.id);
        if (tempTicket && tempTicket?.depends_on === ticketData.id) {
          allDependentTickets.push({ ...tempTicket, quantity: item.quantity });
        }
      }
      final.push(...allDependentTickets);
      for (let i of allDependentTickets) {
        this.getAllDependentTickets(i, final);
      }
    }
  };

  /**
   * Method to update the cart
   * @param {integer} ticketCount
   * @param {array} guests
   * @param {object} ticket
   * TODO: This method need to refactor
   */
  updateCart(ticket) {
    // this.state.ticketCount, this.getSelectedGuests(), ticket

    const { ticketCount, ticketEntries } = this.state;
    const guests = this.getSelectedGuests();

    const { registrationData, ticketDetailsData } = this.props;
    if (!ticket) {
      ticket = this.props.ticket;
    }
    const mandatoryTickets = getMandatoryTickets(this.props.eventData.tickets);

    // checking if there is any dependent tickets
    if (ticketCount <= 0) {
      let dependtedTickets = [];
      this.getAllDependentTickets(ticket, dependtedTickets);
      this.setState({ dependtedTickets });
      if (dependtedTickets.length) return;
    }

    // update ticket fields entries
    let updatedTicketEntries = {};
    if (ticketDetailsData.ticketEntries) {
      Object.assign(updatedTicketEntries, ticketDetailsData.ticketEntries);
    }
    Object.assign(updatedTicketEntries, { [ticket.id]: ticketEntries });
    this.props.setTicketDetailsData({
      ...ticketDetailsData,
      registrationId: registrationData.id,
      ticketEntries: updatedTicketEntries,
    });

    // Adding tickets and guests in guests

    guests.map((guest) => {
      const index = registrationData.guests.findIndex((existingGuest) => guest.tmp_id === existingGuest.tmp_id);
      if (index > -1) {
        const guestTickets = registrationData.guests[index].tickets;
        const ticketIndex = guestTickets.findIndex((item) => item.ticket.id === ticket.id);
        if (ticketIndex === -1) {
          registrationData.guests[index].tickets.push({
            ticket: { id: ticket.id },
          });
        }
      } else {
        registrationData.guests.push({
          first_name: guest.first_name,
          last_name: guest.last_name,
          full_name: guest.full_name,
          tmp_id: guest.tmp_id,
          tickets: [
            {
              ticket: { id: ticket.id },
            },
            ...mandatoryTickets.map((ticket) => ({ ticket: { id: ticket.id } })),
          ],
        });
      }
    });

    // Removing deselected guests tickets

    registrationData.guests.map((existingGuest, guestIndex) => {
      const ticketIndex = existingGuest.tickets.findIndex((item) => item.ticket.id === ticket.id);
      if (ticketIndex > -1) {
        const index = guests.findIndex((guest) => guest.tmp_id === existingGuest.tmp_id);
        if (index === -1) {
          existingGuest.tickets.splice(ticketIndex, 1);
        }
      }
    });

    const guestCount = registrationData.guests.length;

    // Updating tickets

    const ticketIndex = registrationData.tickets.findIndex((item) => item.ticket.id === ticket.id);

    if (ticketIndex > -1) {
      if (ticketCount > 0) {
        registrationData.tickets[ticketIndex].quantity = ticketCount;
      } else {
        registrationData.tickets.splice(ticketIndex, 1);
      }
    } else if (ticketCount > 0) {
      registrationData.tickets.push({
        ticket: { id: ticket.id },
        quantity: ticketCount,
      });
    }

    // Adding mandatory tickets based on guests length

    mandatoryTickets.map((ticket) => {
      const index = registrationData.tickets.findIndex((item) => item.ticket.id === ticket.id);
      if (index > -1) {
        registrationData.tickets[index].quantity = guestCount;
      } else {
        registrationData.tickets.push({
          ticket: { id: ticket.id },
          quantity: guestCount,
        });
      }
    });

    const data = {
      ...registrationData,
      ...{
        guest_count: guestCount,
        discount_code: registrationData.discount_code || null,
        discount_amount: registrationData.discount_amount || 0,
      },
    };

    this.props.setRegistrationData(data);
    this.props.handleCancel(true);
    if (ticketCount > 0 && this.props.checkForDependentTicket) this.props.checkForDependentTicket(ticket.id);
  }

  handleDependentModalClose = () => {
    this.setState({ dependtedTickets: [] });
  };

  handleDependentModalConfirm = () => {
    let { registrationData, ticketDetailsData } = this.props;
    let { ticketEntries } = this.state;
    let updatedTicketEntries = {};
    if (ticketDetailsData.ticketEntries) {
      updatedTicketEntries = { ...ticketDetailsData.ticketEntries };
    }

    for (const ticket of this.state.dependtedTickets) {
      const ticketIndex = registrationData.tickets.findIndex((item) => item.ticket.id === ticket.id);

      // delete ticket fields entries
      if (updatedTicketEntries[ticket.id.toString()]) {
        delete updatedTicketEntries[ticket.id.toString()];
      }
      if (ticketEntries && ticketEntries[ticket.id.toString()]) {
        delete ticketEntries[ticket.id.toString()];
      }
      registrationData.tickets.splice(ticketIndex, 1);
    }

    this.props.setTicketDetailsData({
      ...ticketDetailsData,
      registrationId: registrationData.id,
      ticketEntries: updatedTicketEntries,
    });
    this.setState({ dependtedTickets: [], ticketEntries }, () => this.updateCart());
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { ticket, agenda, currencyEntity } = this.props;
    const { requiredGuestCount, ticketCount, newAddedGuestTmpIds } = this.state;
    return (
      <Modal
        visible={true}
        closable={false}
        maskClosable={true}
        footer={null}
        wrapClassName="tickets-modal"
        width="24rem"
        onCancel={() => this.props.handleCancel()}>
        <div className="ticket-modal-body">
          <ModalHeader
            ticket={ticket}
            agenda={agenda}
            currencyEntity={currencyEntity}
            handleCancel={() => this.props.handleCancel()}
          />
          <If condition={ticket.count_available_tickets && ticket.count_available_tickets < 5}>
            <p id="ticket-quantity-help-text" className="arc-p">
              {`Only ${ticket.count_available_tickets} ticket left`}
            </p>
          </If>
          <Choose>
            <When condition={[SPONSORED_TICKET, SEATED_TICKET].indexOf(ticket.ticket_type) !== -1}>
              <div id="guest-quantity-container" role="group" aria-label="Get this ticket for">
                <p className="arc-H150">Get this ticket for</p>
                <FormItem colon={false}>
                  {getFieldDecorator('guests', {
                    initialValue: this.state.selectedGuests,
                  })(
                    <CheckboxGroup onChange={(values) => this.handleGuestChange(values)}>
                      {this.state.guests.map((guest) => (
                        <Checkbox
                          style={{
                            display: 'block',
                            margin: 0,
                            padding: '0.2rem 0',
                          }}
                          value={guest.tmp_id}
                          key={guest.tmp_id}
                          disabled={this.getDisabledState(guest.tmp_id)}
                          onChange={(event) => this.handleGuestCheckboxChange(event.target)}>
                          <span>{guest.full_name || guest.first_name}</span>
                          <If condition={includes(newAddedGuestTmpIds, guest.tmp_id)}>
                            <Popconfirm
                              onConfirm={(e) => this.deleteNewGuest(e, guest.tmp_id)}
                              title={`Do you want to remove ${guest.full_name || guest.first_name}?`}>
                              <Button
                                type="link"
                                className="link-btn arc-focus-outline guest-delete-action"
                                aria-label={`Delete ${guest.full_name || guest.first_name}`}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </Popconfirm>
                          </If>
                        </Checkbox>
                      ))}
                    </CheckboxGroup>,
                  )}
                </FormItem>
                <If condition={this.props.eventData.is_accompanying_guest_allowed}>
                  {this.state.showGuestAdditionBox ? (
                    <FormItem
                      colon={false}
                      extra={
                        this.props.ticket.ticket_type === SPONSORED_TICKET && (
                          <p className="arc-support arc-B65 blank-name-label">
                            Leave blank to provide guest name later
                          </p>
                        )
                      }>
                      {getFieldDecorator('new_guest', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input name',
                          },
                        ],
                      })(
                        <Input
                          placeholder="Guest Name"
                          addonBefore={
                            <Button
                              type="link"
                              aria-label="Close"
                              className="link-btn arc-focus-outline"
                              onClick={() => this.toggleShowGuestAdditionBox()}>
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          }
                          addonAfter={
                            <Button
                              type="primary"
                              className="add-guest-btn"
                              aria-label="Add Guest"
                              onClick={() => this.addGuest()}>
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                          }
                          onPressEnter={() => this.addGuest()}
                          aria-label="Guest Name"
                          aria-required="true"
                        />,
                      )}
                    </FormItem>
                  ) : (
                    <Button
                      type="link"
                      className="link-btn arc-focus-outline"
                      onClick={() => this.toggleShowGuestAdditionBox()}
                      id="add-guest-handler">
                      Add a guest
                    </Button>
                  )}
                </If>
                <If condition={requiredGuestCount !== 0}>
                  <p className="required-guest-info">
                    This ticket can only be bought for groups of {ticket.number_of_guest_allowed} people. Please add{' '}
                    {requiredGuestCount} more {pluralize('guest', requiredGuestCount)} to proceed.
                  </p>
                </If>
              </div>
            </When>
            <Otherwise>
              <Choose>
                <When condition={this.shouldShowOptions()}>
                  <OpenTicketOptions
                    optionsData={this.state.ticketEntries}
                    fields={this.props.ticket.fields}
                    ticketId={this.props.ticket.id}
                    callback={(action, option) => this.handleTicketCounter(action, option)}
                  />
                </When>
                <Otherwise>
                  <div id="ticket-quantity-container">
                    <p className="arc-H150">Select quantity</p>
                    <div id="ticket-quantity-counter">
                      <Button
                        type="link"
                        aria-label="Decrease"
                        className="link-btn arc-focus-outline"
                        onClick={() => this.handleTicketCounter(COUNTER_ACTIONS.REMOVE)}>
                        <FontAwesomeIcon icon={faMinus} className="arc-cursor-p" />
                      </Button>
                      <Input readOnly value={ticketCount} aria-label="Ticket quantity" />
                      <Button
                        type="link"
                        aria-label="Increase"
                        className="link-btn arc-focus-outline"
                        onClick={() => this.handleTicketCounter(COUNTER_ACTIONS.ADD)}>
                        <FontAwesomeIcon icon={faPlus} className="arc-cursor-p" />
                      </Button>
                    </div>
                  </div>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </div>
        <div>
          <If
            condition={
              [SPONSORED_TICKET, SEATED_TICKET].indexOf(ticket.ticket_type) !== -1 || parseFloat(ticket.price)
            }>
            <If condition={this.shouldShowOptions()}>
              <SeatedTicketOptions
                optionsData={this.state.ticketEntries}
                fields={this.props.ticket.fields}
                guests={this.getSelectedGuests()}
                callback={(guestId, guestTempId, choice) => this.handleSeatedTicketOption(guestId, guestTempId, choice)}
              />
            </If>
            <div id="cart-amount-container">
              <If condition={[SPONSORED_TICKET, SEATED_TICKET].indexOf(ticket.ticket_type) !== -1}>
                <div className="cart-container-item">
                  <span className="arc-H150">Ticket Quantity</span>
                  <span id="cart-amount-text" className="arc-p">
                    {ticketCount}
                  </span>
                </div>
              </If>
              <If condition={parseFloat(ticket.price)}>
                <div className="cart-container-item">
                  <span className="arc-H200">Total</span>
                  <span id="cart-amount-text" className="arc-p">
                    <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                    <span>{readableNumber(this.getTicketTotal())}</span>
                  </span>
                </div>
              </If>
            </div>
          </If>
          <Button
            size="large"
            type="primary"
            className="arc-modal-footer-full-width-btn"
            disabled={
              ([SPONSORED_TICKET, SEATED_TICKET].indexOf(ticket.ticket_type) !== -1 && requiredGuestCount !== 0) ||
              this.showDisableUpdateCartButton()
            }
            onClick={() => this.updateCart()}>
            Update Cart
          </Button>
        </div>
        <If condition={this.state.dependtedTickets.length}>
          <ConfirmModal
            handleCancel={this.handleDependentModalClose}
            handleConfirm={this.handleDependentModalConfirm}
            ticket={ticket}
            dependentTickets={this.state.dependtedTickets}
          />
        </If>
      </Modal>
    );
  }
}

TicketsModal.propTypes = {
  ticket: object,
  agenda: object,
  currencyEntity: string.isRequired,
  handleCancel: func.isRequired,
};

TicketsModal.defaultProps = {
  ticket: {},
  agenda: {},
};

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  setTicketDetailsData: (data) => dispatch(setTicketDetailsData(data)),
});

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
  eventData: state.eventDataReducer.data || {},
  ticketDetailsData: state.ticketDetailsDataReducer.data || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(TicketsModal));
