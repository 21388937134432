import React from 'react';
import moment from 'moment';
import { string } from 'prop-types';
import { getFormattedDate } from '../../../../common/utilities/moment_util';

function EventDateBlock({ startDateTime, endDateTime, shortTimezone }) {
  const startDateString = startDateTime ? getFormattedDate(startDateTime, 'ddd, MMM D, YYYY') : null;
  const endDateString = endDateTime ? getFormattedDate(endDateTime, 'ddd, MMM D, YYYY') : null;
  const startTimeString = startDateTime ? `${getFormattedDate(startDateTime, 'hh:mm a')} ${shortTimezone}` : null;
  const endTimeString = endDateTime ? `${getFormattedDate(endDateTime, 'hh:mm a')} ${shortTimezone}` : null;

  function isSameDayEvent() {
    if (startDateTime && endDateTime) {
      return moment(startDateTime, 'DD-MM-YYYY').isSame(moment(endDateTime, 'DD-MM-YYYY'));
    }
    return false;
  }

  if (isSameDayEvent()) {
    if (startDateString) {
      return (
        <div id="event-date-text">
          <span>
            <span className="arc-H250 arc-B85 arc-d-block" aria-hidden="true">
              {startDateString}
            </span>
            <span className="arc-p arc-color-B55">
              <span>{startTimeString}</span>
              {endTimeString && <span>&nbsp; to {endTimeString}</span>}
            </span>
          </span>
        </div>
      );
    }
  } else {
    if (startTimeString || endTimeString) {
      return (
        <div id="event-date-text">
          <span>
            <span className="arc-H250 arc-B85 arc-d-block">{startDateString}</span>
            <span className="arc-p arc-color-B55">{startTimeString}</span>
          </span>
          <If condition={endTimeString}>
            <span className="arc-H250 arc-B85">to</span>
            <span>
              <span className="arc-H250 arc-B85 arc-d-block">{endDateString}</span>
              <span className="arc-p arc-color-B55">{endTimeString}</span>
            </span>
          </If>
        </div>
      );
    }
  }
  return null;
}

EventDateBlock.propTypes = {
  startDateTime: string,
  endDateTime: string,
};

EventDateBlock.defaultProps = {
  startDateTime: null,
  endDateTime: null,
};

export default EventDateBlock;
