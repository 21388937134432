import React from 'react';
import { Alert } from 'antd';
import { string, number, bool } from 'prop-types';
import { readableAbsNumber, readableNumber } from './../../../../../common/utilities/data_util';

import './style.scss';

const RegistrationCartSection = ({
    currencyEntity,
    cartSubTotal,
    discountValue,
    paidAmount,
    payableAmount,
    isRefundEnabled,
    isRefundAlertHidden,
    totalBenefits
}) => {
    const totalValue = cartSubTotal - discountValue;
    return (
        <div id="registration-cart-section">
            <If condition={cartSubTotal != totalValue}>
                <div className="registration-cart-section-item arc-p">
                    <span>Subtotal</span>
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                        <span>{readableNumber(cartSubTotal)}</span>
                    </span>
                </div>
            </If>
            <If condition={discountValue}>
                <div className="registration-cart-section-item arc-p">
                    <span>Discount</span>
                    <span>
                        -<span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                        <span>{readableNumber(discountValue)}</span>
                    </span>
                </div>
            </If>
            <div className="registration-cart-section-item arc-H150 arc-color-B85" id="primary-amount">
                <span>Total</span>
                <span>
                    <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                    <span>{readableNumber(totalValue)}</span>
                </span>
            </div>
            <If condition={paidAmount > 0}>
                <div className="registration-cart-section-item arc-p" id="existing-amount-text">
                    <span>Net Payment Received</span>
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                        <span>{readableNumber(paidAmount)}</span>
                    </span>
                </div>
                <If condition={totalBenefits}>
                    <div className="registration-cart-section-item arc-p">
                        <span>Net cost of benefits received</span>
                        <span>
                            <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                            <span>{readableAbsNumber(totalBenefits)}</span>
                        </span>
                    </div>
                </If>
                <div className="registration-cart-section-item arc-p">
                    <span>Net Payable</span>
                    <span>
                        <span>{payableAmount < 0 && '-'}</span>
                        <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                        <span>{readableAbsNumber(payableAmount)}</span>
                    </span>
                </div>
            </If>
            <If condition={payableAmount < 0 && !isRefundAlertHidden}>
                <Alert message={(
                    isRefundEnabled ?
                        'Refunds are processed manually. If eligible, It may take a few days to be processed.' :
                        'Proceeding will save your current ticket selection. However, refunds are not currently being processed for this event.'
                )} type="success" />
            </If>
        </div>
    )
}

RegistrationCartSection.propTypes = {
    currencyEntity: string.isRequired,
    cartSubTotal: number,
    discountValue: number,
    paidAmount: number,
    payableAmount: number,
    isRefundEnabled: bool,
    isRefundAlertHidden: bool,
    totalBenefits: number
}

RegistrationCartSection.defaultProps = {
    cartSubTotal: 0,
    discountValue: 0,
    paidAmount: 0,
    payableAmount: 0,
    isRefundEnabled: false,
    isRefundAlertHidden: false,
    totalBenefits: 0
}

export default RegistrationCartSection;