import React from 'react';
import { string } from 'prop-types';
import { Card } from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import LockedContent from 'Src/common/components/lockedContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCircle } from '@fortawesome/pro-light-svg-icons';

const OnlineVenueCard = ({ venue, isContentLocked = false }) => (
  <Card
    bordered={false}
    className="event-card"
    id="event-online-venue-card"
    title={(
      <p className="arc-H200">
        <FontAwesomeIcon icon={faLocationCircle} />
        <span className="ml8 arc-font-weight-medium">
          <I18nCustomFormatter id="event-online-venue-card-title" />
        </span>
      </p>
      )}
  >
    <Choose>
      <When condition={!isContentLocked}>
        <p className="arc-support arc-color-B55">MEETING URL :</p>
        <a href={venue} target="_blank" className="arc-p mt16 meeting-url">{venue}</a>
      </When>
      <Otherwise condition={!isContentLocked}>
        <LockedContent />
      </Otherwise>
    </Choose>

  </Card>
);

OnlineVenueCard.propTypes = {
  venue: string.isRequired,
};

export default OnlineVenueCard;
