import React from 'react';
import { array } from 'prop-types';
import { Card } from 'antd';

import './style.scss';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';

const ContactDetailCard = ({ contacts }) => (
  <Card bordered={false} className="event-card" id="event-contact-card">
    <h3 className="arc-H200 arc-color-B85">
      <I18nCustomFormatter id="event-contact-card-title" />
    </h3>
    <div id="event-contact-items">
      {contacts.map((contact) => (
        <div className="contact-item" key={contact.id}>
          <div className="avatar-item-content">
            <p className="contact-name arc-H150 arc-color-B85">{contact.name}</p>
            <p className="arc-p">{contact.email}</p>
            <p className="arc-p">{contact.phone_number}</p>
          </div>
        </div>
      ))}
    </div>
  </Card>
);

ContactDetailCard.propTypes = {
  contacts: array.isRequired,
};

export default ContactDetailCard;
