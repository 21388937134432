import { faUser, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Button } from 'antd';
import React from 'react';
import { handleEnterClick } from 'Src/common/utilities/dom_utils';
import { getReqGuestId } from '../../../utils';

export function SeatedTicketOptions({ optionsData, guests, fields, callback }) {
  const isChoiceActive = (choiceTobeChecked) => {
    for (const choice of fields[0]['choices']) {
      if (choiceTobeChecked === choice.name) {
        return choice.active;
      }
    }
  };

  const parseChoices = (choices, selectedData) => {
    let result = [];
    for (let choice of choices) {
      if (choice.active === true) {
        result.push(choice);
      } else if (choice.active === false && choice.name === selectedData) {
        result.push(choice);
      }
    }
    return result.sort((a, b) => a.display_order - b.display_order);
  };

  return (
    <div className="seated-ticket-option-wrapper">
      {guests &&
        guests.map(({ first_name, last_name, tmp_id, id }) => (
          <div className="seated-ticket-options">
            <div className="seated-ticket-option-guest-detail arc-H150 arc-B65">
              <FontAwesomeIcon icon={faUser} />
              {`${first_name || ''} ${last_name || ''}`}
            </div>
            <div className="seated-ticket-option-question">
              <span>*</span> {fields[0]['label']}
            </div>
            <div className="seated-ticket-options-item-wrapper">
              {fields &&
                parseChoices(fields[0]['choices'], optionsData[getReqGuestId(tmp_id, id)]).map(({ name }) => (
                  <div className="seated-ticket-options-item-with-alert">
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        handleEnterClick(e, () => isChoiceActive(name) && callback(id, tmp_id, name));
                      }}
                      onClick={() => isChoiceActive(name) && callback(id, tmp_id, name)}
                      className={`seated-ticket-options-item ${
                        optionsData[getReqGuestId(tmp_id, id)] === name ? 'selected' : ''
                      } ${isChoiceActive(name) ? 'arc-cursor-p' : 'arc-cursor-not-allowed'}`}>
                      {name}
                    </div>
                    <If condition={!isChoiceActive(name)}>
                      <div className="open-ticket-archived">
                        <Tooltip title="This option can no longer be edited" placement="topLeft">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </Tooltip>
                      </div>
                    </If>
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
}

export default SeatedTicketOptions;
