import React from 'react';
import { Row, Col } from 'antd';
import { bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';

const ModalHeader = ({ backEnabled, eventName }) => {
  return (
    <Row type="flex" align="middle">
      <Col>
        <If condition={backEnabled}>
          <FontAwesomeIcon icon={faArrowLeft} id="modal-header-back-icon" />
        </If>
        <img src={window.customerLogo} id="modal-header-image" alt={`${window.collegeName} logo`} />
      </Col>
      <Col>
        <p className="arc-H200 arc-text-ellipsis" role="heading" aria-level="2">
          {eventName}
        </p>
      </Col>
    </Row>
  );
};

ModalHeader.propTypes = {
  backEnabled: bool,
  eventName: string.isRequired,
};

ModalHeader.defaultProps = {
  backEnabled: false,
};

export default ModalHeader;
