/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { isEmpty, keys } from 'lodash';
// === REDUX === //
import {
  convertFormLayoutToComponentFields,
  getFormValue,
  getFormEntryRecord,
  processLocationFieldsArray,
} from 'Src/common/utilities/form_utils';
import { setRegistrationData } from 'Src/alumniEvents/reducers/registrationDataReducer';
import { setHeroBannerData } from 'Src/alumniEvents/reducers/heroBannerReducer';
import { requestRegistrationFormLayout } from 'Src/alumniEvents/reducers/registrationFormLayoutReducer';
import {
  REQUEST_FORM_VALIDATION,
  resetFormValidState,
  setFormValidated,
} from 'Src/alumniEvents/reducers/formValidationReducer';
// === APP UTILS AND CONSTANTS === //
import { ALUMNI_BASE_URL } from 'Src/alumniEvents/routes';
import { lookAndAddAutoFillOptions } from 'Src/common/components/customFormItem/helper';
// === COMPONENTS === //
import CustomFormItem from 'Src/common/components/customFormItem';

import './style.scss';
import { PHONE_NUMBER_INPUT_PREFIX } from 'Src/adminFormsX/constants';

class BookingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
  }

  componentWillMount() {
    const { registrationData, history, eventData, setHeroBannerData: setHeroBannerDataL } = this.props;
    if (isEmpty(registrationData)) {
      history.push(ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug));
    }
    setHeroBannerDataL({ title: 'Registration form' });
  }

  componentDidMount() {
    const { formLayout, requestRegistrationFormLayout: requestRegistrationFormLayoutL, eventData } = this.props;
    if (!formLayout.length) {
      requestRegistrationFormLayoutL(eventData.registration_form.id);
    } else {
      this.updateChoices(convertFormLayoutToComponentFields(formLayout));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { formLayout, resetFormValidState: resetFormValidStateL } = this.props;
    if (formLayout.length !== nextProps.formLayout.length) {
      this.updateChoices(convertFormLayoutToComponentFields(nextProps.formLayout));
    }
    if (nextProps.formValidateState === REQUEST_FORM_VALIDATION) {
      resetFormValidStateL();
      this.validateForm();
    }
  }

  updateChoices = async (fields) => {
    const result = fields;
    await lookAndAddAutoFillOptions(result);
    this.setState({ fields: result });
  };

  // Method to validate form and adding form_entry
  validateForm() {
    const {
      form,
      eventData,
      setRegistrationData: setRegistrationDataL,
      setFormValidated: setFormValidatedL,
    } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { registrationData } = this.props;
        let fieldEntries = [];
        // eslint-disable-next-line array-callback-return
        keys(values).map((key) => {
          if (!key.includes(PHONE_NUMBER_INPUT_PREFIX)) {
            const fieldValue = getFormValue(values[key]);
            if (fieldValue) {
              fieldEntries.push({
                value: fieldValue,
                field_id: key,
              });
            }
          }
        });
        fieldEntries = processLocationFieldsArray(fieldEntries);
        registrationData.form_entry = {
          form_id: eventData.registration_form.id,
          field_entries: fieldEntries,
        };
        setRegistrationDataL(registrationData);
        setFormValidatedL();
      }
    });
  }

  render() {
    const { fields } = this.state;
    const { registrationData, eventData, form } = this.props;
    const formEntries = (registrationData.form_entry && registrationData.form_entry.field_entries) || [];
    return (
      <div id="booking-form-container" className="arc-card-box-shadow regular-form">
        {fields.map((field) => (
          <CustomFormItem
            key={field.id}
            form={form}
            formInput={field}
            formRecord={getFormEntryRecord(formEntries, field.id, field.type)}
            metadata={{
              source_type: 'customform',
              source_id: eventData && eventData.registration_form && eventData.registration_form.id,
              access_level: 'protected',
            }}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  formLayout: state.registrationFormLayoutReducer.data || [],
  status: state.eventDataReducer.status,
  formValidateState: state.formValidationReducer.state,
});

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  setHeroBannerData: (data) => dispatch(setHeroBannerData(data)),
  requestRegistrationFormLayout: (id) => dispatch(requestRegistrationFormLayout(id)),
  resetFormValidState: () => dispatch(resetFormValidState()),
  setFormValidated: () => dispatch(setFormValidated()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(BookingForm)));
