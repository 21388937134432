/* eslint-disable */

import React from 'react';
import { array } from 'prop-types';
import { Divider, Timeline } from 'antd';
import { map } from 'lodash';
import AddToCalendar from 'react-add-to-calendar';
import { getFormattedDate } from 'Src/common/utilities/moment_util';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/pro-light-svg-icons';
import { getAgendaItems } from 'Src/alumniEvents/utils/app_moment_utils';
import './agendaItems.scss';

const AgendaItems = ({ agendas, getContactEmail, eventData, registrationData }) => {
  const agendaItems = getAgendaItems(agendas);

  function renderAddToCalendar(agenda, isMainEvent) {
    // eventData.venue_location is a new property on registration Model.
    // It has the venue details after factoring in privacy.
    const editCode = eventData?.edit_key ? `?r=${eventData.edit_key}` : window.location.search;
    const guestKitUrl = `${window.location.origin}/e/${eventData?.slug}/kit/${editCode}&edit_registration=true`;
    const calendarEventObject = {
      title: agenda.name,
      description: `Please check your registration kit for instructions on how to attend the event.
        You can also view your receipt or modify your guests and registration details from here: ${guestKitUrl} \n\nIf you need any assistance, please reach out to ${getContactEmail()}.`,
      startTime: agenda.start_datetime,
      endTime: agenda.end_datetime,
    };
    if (isMainEvent && registrationData.venue_location) {
      calendarEventObject.location = registrationData.venue_location;
    } else if (agenda.venue) {
      calendarEventObject.location = agenda.venue;
    } else {
      calendarEventObject.location = '';
    }
    return (
      <AddToCalendar
        event={{ ...calendarEventObject }}
        buttonTemplate={{ 'calendar-plus-o': 'left' }}
        buttonLabel="Add"
      />
    );
  }

  return (
    <div className="schedule-listing">
      <div className="main-event arc-d-flex arc-flex-vertical-align">
        <div className="main-event-title arc-d-flex arc-flex-vertical-align">
          <FontAwesomeIcon icon={faCalendarPlus} />
          <span className="arc-H150 ml16">Add {eventData.name} to calendar</span>
        </div>
        {renderAddToCalendar(eventData, true)}
      </div>
      <Divider />
      <div className="tab-content-body" id="agenda-timeline">
        <p className="arc-H100 arc-font-weight-bold arc-text-uppercase agenda-list-title mb16">
          Here is your complete itinerary
        </p>
        {map(agendaItems, (agenda) => (
          <div className="agenda-timeline-item mb24" key={getFormattedDate(agenda._d, 'X')}>
            <div className="agenda-timeline-item-header">
              <p className="agenda-item-day arc-support arc-color-B65 arc-font-weight-bold">
                {getFormattedDate(agenda._d, 'ddd')}
              </p>
              <p className=" agenda-item-date arc-H100 arc-color-black">{getFormattedDate(agenda._d, 'MMM D, YYYY')}</p>
            </div>
            <div className="agenda-timeline-item-body">
              <Timeline>
                {map(agenda.data, (item) => (
                  <Timeline.Item key={item.id}>
                    <div className="arc-d-flex arc-flex-space-between-align">
                      <div className="agenda-item">
                        <p className="agenda-timeline-item-time arc-support arc-color-B45 arc-font-weight-bold">
                          {`${getFormattedDate(item.start_datetime, 'hh:mm a')} - ${getFormattedDate(
                            item.end_datetime,
                            'hh:mm a',
                          )}`}
                        </p>
                        <p className=" agenda-timeline-item-content agenda-timeline-item-name arc-p arc-color-block">
                          {item.name}
                        </p>
                        <If condition={item.venue}>
                          <p className="agenda-timeline-item-content arc-support arc-color-B45">{item.venue}</p>
                        </If>
                      </div>
                      {renderAddToCalendar(item, false)}
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

AgendaItems.propTypes = {
  agendas: array,
};

AgendaItems.defaultProps = {
  agendas: [],
};

export default AgendaItems;
