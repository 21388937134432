import React from "react";
import { string } from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { title, subtitle } = this.props
    if (this.state.hasError) {
      // fallback UI
      return (
        <div className="error-boundary-container arc-d-flex arc-flex-v-align-middle mt24 mb24">
          <div className="error-boundary-image arc-d-inline-block mr24">
            <img src={`${window.staticPath}img/error_boundary.png`} alt="Error loading discussions" />
          </div>
          <div className="error-boundary-details arc-d-inline-block">
            <p className="arc-H250">{title}</p>
            <p className="arc-support">{subtitle}</p>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  title: string,
  subtitle: string
};

ErrorBoundary.defaultProps = {
  title: "The discussion feed could not be loaded ",
  subtitle: "You can try refreshing the page. If problem persist, please contact support"
};


export default ErrorBoundary