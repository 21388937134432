/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { string, number, bool, func, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import { find, isEmpty, keys } from 'lodash';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { readableNumber } from '../../../../../common/utilities/data_util';
import RegistrationItemSection from '../index';
import { OPEN_TICKET, SEATED_TICKET, SPONSORED_TICKET } from '../../../../constants';
import { getReqGuestId } from '../../../../utils';
import './style.scss';

function RegistrationTicketSection({
  mandatoryTickets,
  otherTickets,
  guestCount,
  currencyEntity,
  showEdit,
  handleEditAction,
  ticketDetailsData,
  eventData,
  showTaxReceipt,
}) {
  const getTicketDetails = (ticketId, guest) => {
    const reqTicketDetails = ticketDetailsData?.ticketEntries[ticketId.toString()];
    if (!reqTicketDetails && guest) {
      return <span>{guest.first_name}</span>;
    }
    const ticketType = find(eventData.tickets, ({ id }) => id === ticketId).ticket_type;
    if (ticketType === OPEN_TICKET) {
      return (
        reqTicketDetails &&
        keys(reqTicketDetails)
          .filter((choice) => reqTicketDetails[choice])
          .map((choice) => (
            <li key={choice} className="arc-support arc-color-B55">
              {reqTicketDetails[choice]} x{choice}
            </li>
          ))
      );
    }
    if ((ticketType === SEATED_TICKET || ticketType === SPONSORED_TICKET) && guest) {
      if (reqTicketDetails[getReqGuestId(guest?.tmp_id, guest?.id)]) {
        return (
          <span>
            {guest?.first_name} -{reqTicketDetails[getReqGuestId(guest?.tmp_id, guest?.id)]}
          </span>
        );
      }
      return <span>{guest?.first_name}</span>;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return (
    <div id="registration-ticket-section">
      <If condition={mandatoryTickets.length}>
        <RegistrationItemSection title="Tickets required for all guests">
          {mandatoryTickets.map((ticket) => (
            <div className="ticket-item-wrapper" key={ticket.id}>
              <div className="ticket-item" key={ticket.id}>
                <div>
                  <p className="arc-H150 arc-color-B85">{ticket.name}</p>
                  <p className="arc-support arc-color-B55">{`${guestCount} guest${guestCount > 1 ? 's' : ''}`}</p>
                </div>
                <div className="ticket-price">
                  <p className="arc-H150 arc-color-B55">
                    <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                    <span>{readableNumber(guestCount * ticket.price)}</span>
                  </p>
                </div>
              </div>
              <If condition={ticket.benefit && showTaxReceipt}>
                <div className="ticket-tax-receipt">
                  (Each ticket includes benefits worth <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                  {ticket.benefit})
                </div>
              </If>
            </div>
          ))}
        </RegistrationItemSection>
      </If>
      <If condition={otherTickets.length}>
        <RegistrationItemSection
          title={
            mandatoryTickets.length ? (
              <I18nCustomFormatter id="event-other-tickets-label" />
            ) : (
              <I18nCustomFormatter id="event-tickets-label" />
            )
          }>
          {otherTickets.map((ticket) => {
            const ticketId = ticket.ticket.id;
            const { quantity } = ticket;
            return (
              <div className="ticket-item-wrapper">
                <div className="ticket-item" key={ticketId}>
                  <div>
                    <p className="arc-H150 arc-color-B85">{`${quantity} x ${ticket.ticket.name}`}</p>
                    <ul className="ticket-item-guests">
                      <If condition={isEmpty(ticket.guests)}>{getTicketDetails(ticket.ticket.id, null)}</If>
                      {ticket.guests.map((guest, index) => (
                        <li key={`guest-${guest?.id || index}`} className="arc-support arc-color-B55">
                          <FontAwesomeIcon icon={faUser} className="mr8" />
                          {getTicketDetails(ticket.ticket.id, guest)}
                          {/* <span>{guest.first_name}</span> */}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="ticket-price">
                    <p className="arc-H150 arc-color-B55">
                      <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                      <span>{readableNumber(quantity * ticket.ticket.price)}</span>
                    </p>
                    <If condition={showEdit && ticket.ticket.is_active}>
                      <Button
                        aria-label={`Edit ${ticket.ticket.name}`}
                        type="link"
                        className="link-btn arc-focus-outline"
                        onClick={() => handleEditAction(ticketId)}>
                        Edit
                      </Button>
                    </If>
                  </div>
                </div>
                <If condition={ticket.ticket.benefit && showTaxReceipt}>
                  <div className="ticket-tax-receipt">
                    (Each ticket includes benefits worth <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                    {readableNumber(ticket.ticket.benefit)})
                  </div>
                </If>
              </div>
            );
          })}
        </RegistrationItemSection>
      </If>
    </div>
  );
}

RegistrationTicketSection.propTypes = {
  currencyEntity: string.isRequired,
  mandatoryTickets: shape(),
  otherTickets: shape(),
  guestCount: number,
  showEdit: bool,
  handleEditAction: func,
  showTaxReceipt: bool,
};

RegistrationTicketSection.defaultProps = {
  mandatoryTickets: [],
  otherTickets: [],
  guestCount: 0,
  showEdit: false,
  showTaxReceipt: false,
  handleEditAction: () => {},
};

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  ticketDetailsData: state.ticketDetailsDataReducer.data || {},
});

export default connect(mapStateToProps, {})(RegistrationTicketSection);
