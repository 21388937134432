import React, { Component } from 'react';
import { message, Button } from 'antd';
import { isEmpty } from 'lodash';
import { resendConfirmationEmail } from '../../../actions';
import { getProfileByEmail } from '../../../../common/actions';

import ThemeXModal from '../../../../common/components/themeXModal';
import LoginBlock from '../../../../common/components/themeXSignInModal/loginBlock';

import './style.scss';

class ModifyRegistrationEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      isProfileExist: false,
      loading: true,
      processing: false,
    };
  }

  componentDidMount() {
    const { registrant } = this.props;

    getProfileByEmail(registrant.email).then((response) => {
      const profile = response.data.length === 1 ? response.data[0] : {};
      this.setState({
        profile,
        loading: false,
        isProfileExist: !isEmpty(profile),
      });
    });
  }

  sendEditEmail() {
    const { handleClose, eventData, registrant } = this.props;

    this.setState({ processing: true });
    resendConfirmationEmail({
      eventId: eventData.id,
      registrantEmail: registrant.email,
      isEditRegistration: true,
    })
      .then(() => {
        message.success('Email sent successfully');
        handleClose();
      })
      .catch(() => {
        this.setState({ processing: false });
      });
  }

  render() {
    const { loading, isProfileExist, processing, profile } = this.state;
    const { eventData, registrant, handleClose } = this.props;

    return (
      <ThemeXModal
        visible
        width={470}
        title="Previous Registration Found"
        wrapClassName="modify-registration-modal"
        showLoader={loading}
        handleCancel={() => handleClose()}
        Description={
          <p>
            <span className="modify-registration-content-title">
              The email has already been used to register for <b>{eventData.name}</b>
            </span>
            {isProfileExist ? (
              <span>If you would like to make changes to your current registration, you can log in your account</span>
            ) : (
              <span>
                If you would like to make changes to your current registration, we will send an email {registrant.email}{' '}
                with details on how.
              </span>
            )}
          </p>
        }
        Footer={
          <If condition={!isProfileExist}>
            <div className="pb16 arc-text-align-c">
              <Button onClick={() => this.sendEditEmail()} type="primary" loading={processing}>
                Send Email
              </Button>
            </div>
          </If>
        }>
        <If condition={!loading && isProfileExist}>
          <LoginBlock profile={profile} />
        </If>
      </ThemeXModal>
    );
  }
}

export default ModifyRegistrationEmailModal;
