export const FORM_VALID_RESET = 'SIGNAL/FORM_VALID_RESET'
export const REQUEST_FORM_VALIDATION = 'SIGNAL/REQUEST_FORM_VALIDATION'
export const SET_FORM_VALIDATED = 'SIGNAL/SET_FORM_VALIDATED'

const initialState = {
    valid: false,
    state: FORM_VALID_RESET
}

export const resetFormValidState = () => {
    return {
        type: FORM_VALID_RESET
    }
}

export const requestFormValidation = () => {
    return {
        type: REQUEST_FORM_VALIDATION
    }
}

export const setFormValidated = () => {
    return {
        type: SET_FORM_VALIDATED
    }
}

const formValidationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORM_VALID_RESET:
            return {
                ...state,
                valid: false,
                state: FORM_VALID_RESET
            };
        case REQUEST_FORM_VALIDATION:
            return {
                ...state,
                valid: false,
                state: REQUEST_FORM_VALIDATION
            };
        case SET_FORM_VALIDATED:
            return {
                ...state,
                valid: true,
                state: SET_FORM_VALIDATED
            };
        default:
            return state;
    }
}

export default formValidationReducer;