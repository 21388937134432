import React from 'react';
import { object } from 'prop-types';
import { Card, Row, Col } from 'antd';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import LeafletMap from '../../../../../common/components/leafletMap';
import LockedContent from 'Src/common/components/lockedContent';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationCircle, faDirections } from '@fortawesome/pro-light-svg-icons';

const VenueCard = ({ venueData, isContentLocked = false }) => {
  const position = [venueData.latitude, venueData.longitude];

  return (
    <Card
      bordered={false}
      className="event-card arc-card-border-radius arc-card-box-shadow"
      id="event-venue-card"
      title={
        <p className="arc-H200">
          <FontAwesomeIcon icon={faLocationCircle} />
          <span className="ml8 arc-font-weight-medium">
            <I18nCustomFormatter id="event-venue-card-title" />
          </span>
        </p>
      }>
      <Choose>
        <When condition={!isContentLocked}>
          <Row gutter={16}>
            <Col span={12}>
              <div id="event-venue-address-block">
                <p className="arc-p arc-color-black">{`${venueData.display_name}`}</p>
                <a href={`https://maps.google.com/?q=${venueData.display_name}`} target="_blank">
                  <FontAwesomeIcon icon={faDirections} className="mr8" />
                  <span>
                    <I18nCustomFormatter id="event-venue-address-block-label" />
                  </span>
                </a>
              </div>
            </Col>
            <Col span={10} offset={2}>
              <div id="event-map-block">
                <LeafletMap position={position} triggerResize />
              </div>
            </Col>
          </Row>
        </When>
        <Otherwise>
          <div className="p16">
            <LockedContent />
          </div>
        </Otherwise>
      </Choose>
    </Card>
  );
};

VenueCard.propTypes = {
  venueData: object.isRequired,
};

export default VenueCard;
