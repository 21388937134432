import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { bool, number } from 'prop-types';
import { isEmpty } from 'lodash';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';
import initializeCaptchaV2 from 'Src/common/utilities/captcha_util';
// === ACTIONS === //
import { registerGuest } from '../../../actions';
// === REDUX === //
import { setContextData } from '../../../reducers/contextDataReducer';
import { setRegistrationData } from '../../../reducers/registrationDataReducer';
import { requestFormValidation, resetFormValidState } from '../../../reducers/formValidationReducer';

import { readableNumber, getUrlParam } from '../../../../common/utilities/data_util';
import { GUEST_VIEW_URL, BOOKING_FORM_VIEW_URL, CONFIRMATION_VIEW_URL } from '../../../routes';
import { getCartSubTotal } from '../../../utils';

import CartModal from '../cartModal';
import TicketsModal from '../../tickets/TicketsModal';

import './style.scss';

class CartFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCartModal: false,
      isCheckout: false,
      showTicketsModal: false,
      ticket: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFormValid && this.props.requiredFormValidation) {
      this.props.resetFormValidState();
      this.redirectOrRegister();
    }
  }

  setCartModal(value, isCheckout = false, ticketId = null) {
    let ticket = {};
    this.setState({
      showCartModal: value,
      isCheckout,
    });
    if (ticketId) {
      const index = this.props.eventData.tickets.findIndex((item) => item.id == ticketId);
      if (index > -1) {
        ticket = this.props.eventData.tickets[index];
        this.toggleTicketModal(ticket);
      }
    }
  }

  handleFooterProceedAction() {
    if (this.props.requiredFormValidation) {
      this.props.requestFormValidation();
    } else {
      this.redirectOrRegister();
    }
  }

  injectCaptcha(registrationData, callback) {
    if (!registrationData.id && window.captchaEnabled) {
      grecaptcha.ready(() => {
        grecaptcha.execute(googleCaptchaKey, { action: 'submit' }).then((token) => {
          registrationData.captcha_token = token;
          callback(registrationData);
        });
      });
    } else {
      callback(registrationData);
    }
  }

  redirectOrRegister() {
    const { eventData, currentStep, contextData, registrationData } = this.props;
    if (
      ((eventData.is_accompanying_guest_allowed && registrationData.guests.length > 1) || eventData.guest_form) &&
      currentStep == 2
    ) {
      this.props.history.push({
        pathname: GUEST_VIEW_URL.replace(':eventSlug', eventData.slug),
        search: window.location.search,
      });
    } else if (eventData.registration_form && (currentStep == 2 || currentStep == 3)) {
      this.props.history.push({
        pathname: BOOKING_FORM_VIEW_URL.replace(':eventSlug', eventData.slug),
        search: window.location.search,
      });
    } else if (contextData.isTicketedEvent) {
      this.setCartModal(true, true);
    } else {
      this.setState({ processing: true });
      registrationData.rsvp_status = 'yes';

      this.injectCaptcha(registrationData, (updatedRegistrationData) => {
        registerGuest({
          registrationData: updatedRegistrationData,
          eventId: eventData.id,
          hashToken: contextData.registrationHash,
          invitationHash: contextData.invitationHash,
        })
          .then((response) => {
            this.handleAfterRegister(response.data, eventData.slug, !updatedRegistrationData.id);
          })
          .catch((err) => {
            if (err.response.status === 400 && err.response.data.detail === 'Captcha Low Score') {
              initializeCaptchaV2((token) => {
                updatedRegistrationData.captcha_token = token;
                updatedRegistrationData.captcha_v2 = true;

                registerGuest({
                  registrationData: updatedRegistrationData,
                  eventId: eventData.id,
                  hashToken: contextData.registrationHash,
                  invitationHash: contextData.invitationHash,
                })
                  .then((response) => {
                    this.handleAfterRegister(response.data, eventData.slug, !updatedRegistrationData.id);
                  })
                  .catch((err) => {
                    if (err.response?.data?.tickets?.length) {
                      message.error(err.response?.data?.tickets[0]);
                    }
                    this.setState({ processing: false });
                  });
              });
            } else if (err.response?.data?.tickets?.length) {
              message.error(err.response?.data?.tickets[0]);
              this.setState({ processing: false });
            } else if (err.response?.data?.first_name) {
              message.error(`First Name: ${err.response.data.first_name}`);
              this.setState({ processing: false });
            } else if (err.response?.data?.last_name) {
              message.error(`Last Name: ${err.response.data.last_name}`);
              this.setState({ processing: false });
            }
          });
      });
    }
  }

  handleAfterRegister(response, eventSlug, isRegistrationCreated) {
    let registrationHashLink = window.location.search;
    response.guests.map((guest, index) => (guest.tmp_id = index));
    if (isRegistrationCreated) {
      this.props.setContextData({
        registrationHash: response.edit_hash,
      });

      let query = `?r=${response.edit_key}`;
      const addGuestParam = getUrlParam('add_guest');
      const fromAdminParam = getUrlParam('from_admin');

      if (addGuestParam === 'true') {
        query = `${query}&add_guest=true`;
      }
      if (fromAdminParam === 'true') {
        query = `${query}&from_admin=true`;
      }
      registrationHashLink = query;
    }
    this.props.setRegistrationData(response);
    this.props.history.push({
      pathname: CONFIRMATION_VIEW_URL.replace(':eventSlug', eventSlug),
      search: registrationHashLink,
    });
  }

  getPayableAmount(registrationData) {
    const paidAmount = registrationData.paid_amount || 0;
    const cartSubTotal = getCartSubTotal(registrationData.tickets, this.props.eventData.tickets, registrationData.gift);
    const discountValue = registrationData.discount_amount || 0;
    const payable = cartSubTotal - discountValue - paidAmount;
    return payable >= 0 ? payable : 0;
  }

  toggleTicketModal(ticket = {}) {
    this.setState({
      showTicketsModal: !this.state.showTicketsModal,
      ticket,
    });
  }

  render() {
    const { registrationData, contextData } = this.props;
    const { currencyEntity, isTicketedEvent } = contextData;
    const registrationTickets = registrationData.tickets || [];
    const payableAmount = this.getPayableAmount(registrationData);

    return (
      <footer>
        <div id="event-fixed-cart-footer" className="arc-card-box-shadow">
          <If condition={isTicketedEvent}>
            <Button
              type="primary"
              size="large"
              className="arc-btn-subtle arc-focus-outline"
              onClick={() => this.setCartModal(true)}>
              <span>View Cart</span>
              {payableAmount > 0 && (
                <span>
                  <span>&nbsp;(</span>
                  <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                  <span>{`${readableNumber(payableAmount)})`}</span>
                </span>
              )}
            </Button>
          </If>
          <If condition={!isTicketedEvent}>
            <div id="g-recaptcha" className="g-recaptcha" data-size="invisible" />
          </If>
          <If condition={!isTicketedEvent || (isTicketedEvent && !isEmpty(registrationTickets))}>
            <Button
              type="primary"
              className="arc-focus-outline"
              loading={this.state.processing}
              onClick={() => this.handleFooterProceedAction()}
              size="large">
              <I18nCustomFormatter id="event-tickets-checkout-label" />
            </Button>
          </If>
        </div>
        <If condition={this.state.showCartModal}>
          <CartModal
            handleCancel={(ticketId) => this.setCartModal(false, false, ticketId)}
            currencyEntity={currencyEntity}
            isCheckout={this.state.isCheckout}
          />
        </If>
        <If condition={this.state.showTicketsModal}>
          <TicketsModal
            ticket={this.state.ticket}
            currencyEntity={currencyEntity}
            handleCancel={() => this.toggleTicketModal()}
          />
        </If>
      </footer>
    );
  }
}

CartFooter.propTypes = {
  currentStep: number.isRequired,
  requiredFormValidation: bool.isRequired,
};

const mapStateToProps = (state) => ({
  registrationData: state.registrationDataReducer.data || {},
  eventData: state.eventDataReducer.data || {},
  isFormValid: state.formValidationReducer.valid,
  contextData: state.contextDataReducer || {},
});

const mapDispatchToProps = (dispatch) => ({
  setRegistrationData: (data) => dispatch(setRegistrationData(data)),
  requestFormValidation: () => dispatch(requestFormValidation()),
  resetFormValidState: () => dispatch(resetFormValidState()),
  setContextData: (data) => dispatch(setContextData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartFooter));
