import React from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';

import './style.scss';

function NotificationReminderModal({ email, ok, cancel }) {
  return (
    <Modal
      visible
      title=""
      onOk={ok}
      okText="Send"
      closable={false}
      onCancel={cancel}
      maskClosable={false}
      cancelText="Don't send"
      className="notification-reminder-wrapper">
      <div className="content-wrapper arc-d-flex arc-flex-vertical-align-baseline">
        <p>
          <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#FAAD14', fontSize: '16px' }} />
        </p>
        <div className="ml16">
          <p className="arc-H200 arc-color-gray9 arc-font-weight-500">Would you like to send an update to the guest?</p>
          <p className="arc-p arc-color-gray8 mt8">
            This will send an email to the guest ({email}) with details of the registration.
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default NotificationReminderModal;
