import { MANDATORY_TICKET, SEATED_TICKET, REGISTRATION_TYPES } from './../constants';
import { includes } from 'lodash';

export const getMandatoryTickets = (tickets) => {
  return tickets.filter((ticket) => {
    return ticket.ticket_type === MANDATORY_TICKET;
  });
};

export const getSeatedTicketIds = (tickets) => {
  let seatedTickets = tickets.reduce((seated, ticket) => {
    return [
      ...seated,
      ...(ticket.ticket_type === SEATED_TICKET && ticket.number_of_guest_allowed > 1 ? [ticket.id] : []),
    ];
  }, []);
  return seatedTickets;
};

export const getCartData = (allTickets, guestTickets = [], guests, gift = {}) => {
  let mandatoryTicketIds = getMandatoryTickets(allTickets).map((ticket) => {
    return ticket.id;
  });
  let mandatoryTickets = [];
  let otherTickets = [];
  let cartSubTotal = 0;
  guestTickets.map((ticket) => {
    let ticketId = ticket.ticket.id;
    let ticketObject = allTickets.filter((ticket) => {
      return ticket.id === ticketId;
    })[0];
    if (ticketObject) {
      if (mandatoryTicketIds.indexOf(ticketId) > -1) {
        mandatoryTickets.push(ticketObject);
        cartSubTotal += ticketObject.price * guests.length;
      } else {
        otherTickets.push({
          ticket: ticketObject,
          quantity: ticket.quantity,
          guests: getSeatedGuests(ticketId, guests),
        });
        cartSubTotal += ticketObject.price * ticket.quantity;
      }
    }
  });
  if (gift) {
    cartSubTotal += getTotalGiftAmount(gift.splits || []);
  }
  return { mandatoryTickets, otherTickets, cartSubTotal };
};

function getSeatedGuests(ticketId, allGuests) {
  let guests = [];
  allGuests.map((guest) => {
    guest.tickets &&
      guest.tickets.map((item) => {
        if (item.ticket.id === ticketId) {
          guests.push(guest);
        }
      });
  });
  return guests;
}

export const getTotalGiftAmount = (splits = []) => {
  return splits.reduce((sum, split) => sum + parseFloat(split.amount), 0);
};

export const getCartSubTotal = (cartTickets = [], allTickets, gift = {}) => {
  let cartSubTotal = cartTickets.reduce((subTotal, cartTicket) => {
    const ticket = getTicket(allTickets, cartTicket.ticket.id);
    const ticketPrice = (ticket && ticket.price) || 0;
    return (subTotal = subTotal + ticketPrice * cartTicket.quantity);
  }, 0);
  if (gift) {
    cartSubTotal += getTotalGiftAmount(gift.splits);
  }
  return cartSubTotal;
};

export const getTicket = (allTickets, ticketId) => {
  return allTickets.filter((ticket) => {
    return ticket.id === ticketId;
  })[0];
};

export const getFund = (allFunds, fundId) => {
  return allFunds.filter((fund) => {
    return fund.id === fundId;
  })[0];
};

export const getBoughtTicketQuantity = (boughtTickets, ticketId, agendaId) => {
  let ticket = boughtTickets.filter((item) => item.ticket.id === ticketId)[0] || {};
  return ticket.quantity || 0;
};

export const getBoughtTicketQuantities = (boughtTickets, ticketIds, agendaId) => {
  let result = 0;
  for (let ticketId of ticketIds) {
    let ticket = boughtTickets.filter((item) => item.ticket.id === ticketId)[0] || {};
    if (ticket.quantity) {
      result += ticket.quantity;
    }
  }
  return result;
};

export const canGuestDeleteAndGetTickets = (eventTickets, guests, guestIndex) => {
  let seatedTickets = getSeatedTicketIds(eventTickets);
  let guest = guests[guestIndex];
  let isSeatedTicketExists = false;
  let guestTickets = [];
  guest.tickets.map((ticket) => {
    guestTickets.push(ticket.ticket.id);
    if (includes(seatedTickets, ticket.ticket.id)) {
      isSeatedTicketExists = true;
    }
  });
  return { canDelete: !isSeatedTicketExists, guestTickets };
};

export const deleteGuestAndCount = (guestData, guestTickets, guestIndex) => {
  guestData.tickets.map((ticket, index) => {
    const ticketId = ticket.ticket.id;
    if (includes(guestTickets, ticketId)) {
      if (ticket.quantity === 1) {
        guestData.tickets.splice(index, 1);
      } else {
        ticket.quantity -= 1;
      }
    }
  });
  guestData.guests.splice(guestIndex, 1);
  return { data: guestData, count: guestData.guests.length };
};

export const redirectToSocialLogin = (registrationType) => {
  const nextUrl = window.location.pathname;
  if (registrationType === REGISTRATION_TYPES.EMAIL) {
    window.location = `/register?next=${nextUrl}`;
  } else {
    window.location = `/login/${registrationType}?next=${nextUrl}`;
  }
};

export const getFullName = (registrant) => {
  return `${
    registrant.first_name ? `${registrant.first_name}${registrant.last_name ? ` ${registrant.last_name}` : ''}` : ''
  }`;
};

export const getGuestDetails = (guest) => {
  return {
    first_name: guest.first_name,
    last_name: guest.last_name,
    email: guest.email,
    full_name: guest.full_name,
    phone_number: guest.phone_number,
    affiliations: guest.affiliations,
  };
};

export const getInitialDiscount = (registrationData) => {
  const discount = registrationData.discount_code;
  if (discount) {
    return {
      code: discount.code,
      amount: registrationData.discount_amount,
    };
  }
  return {};
};

export const getReqGuestId = (tmp_id, orgnl_id) => {
  if (orgnl_id) {
    return `id__${orgnl_id}`;
  }
  return `tmp_id__${tmp_id}`;
};
