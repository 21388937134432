import React, { Component } from "react";
import { ExportOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Input, List, Modal, Row, Spin } from "antd";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroller";

import { generateUrl, getKeyValue } from "./../../utilities/data_util";
import instance from './../../utilities/axios_util';

import ThemeXProfileDetailItem from "./../../components/themeXProfileDetailItem";
import EmptyDataBlock from "./../../components/emptyDataBlock";

import "./style.scss";

const Search = Input.Search;
let profileApiUrl = "profiles";
let searchInterval = null;

class ProfilesSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      hasMore: true,
      records: [],
      page: 1,
      reset: true,
      query: getKeyValue(this.props, 'query', ''),
      selectedProfiles: getKeyValue(this.props, 'selected', [])
    };
    this.queryProfiles = debounce(this.queryProfiles, 500);
    profileApiUrl = this.props.endpoint || profileApiUrl;
  }

  componentWillMount() {
    if (this.props.visible) {
      this.toggleModal();
    }
    if (this.props.initialLoad) {
      this.fetchProfiles(null);
    }
  }

  componentWillUnmount() {
    clearInterval(searchInterval);
  }

  componentDidMount() {
    searchInterval = window.setInterval(() => {
      let ele = document.getElementById("profile-search");
      if (ele) {
        this.setEventListenerToElement(ele);
        clearInterval(searchInterval);
      }
    }, 300);
  }

  setEventListenerToElement(ele) {
    ele.focus();
    ele.addEventListener("keyup", event => {
      this.queryProfiles(event);
    });
  }

  toggleModal = () => {
    if (this.state.visible && this.props.handleHideModal) {
      this.props.handleHideModal();
    }
    this.setState({ visible: !this.state.visible });
  };

  fetchProfiles = (event, page = this.state.page, query = this.state.query) => {
    if (!this.state.hasMore) {
      return false;
    }
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    let apiUrl = generateUrl({
      url: profileApiUrl,
      page: page,
      query: query,
      filters: this.props.filters || "&active=true&email__isnull=false",
      sorter: "&ordering=-id",
      fields:
        this.props.fields || "&fields=formatted_name,active,email,slug,id,role,profile_url,present_picture,educations__class_year,listed,is_registered"
    });
    instance(apiUrl, "GET")
      .then(response => {
        let records = response.data.results;
        let previousRecords = JSON.parse(JSON.stringify(this.state.records));
        if (this.state.reset || response.data.previous == null) {
          previousRecords = [];
        }
        let updatedRecords = previousRecords.concat(records);
        this.setState({
          loading: false,
          reset: false,
          records: updatedRecords,
          hasMore: response.data.next,
          page: page + 1,
          query: query
        });
      })
      .catch(error => {
        let errorDetail = getKeyValue(
          error["errorResponse"],
          "responseJSON__detail"
        );
        errorDetail = errorDetail ? " Reason: " + errorDetail : "";
        let errorMessage =
          "Could not load profiles. If the issue persists please contact help@almabase.com " +
          errorDetail;
        this.setState({
          loading: false,
          reset: false,
          hasMore: false
        });
      });
  };

  queryProfiles = e => {
    let regex = new RegExp("^[a-zA-Z0-9\b]+$");
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    let query = e.target.value;
    if (regex.test(str) || e.which === 13) {
      this.setState({
        query: query.trim(),
        reset: true,
        loading: true,
        hasMore: true
      });
      this.fetchProfiles(this, 1, query.trim());
    } else {
      return false;
    }
  };

  openProfile = (event, profileUrl) => {
    event.stopPropagation();
    window.open(profileUrl, "_blank");
  };

  toggleItemSelection = record => {
    if (this.props.many) {
      let selectedProfiles = JSON.parse(
        JSON.stringify(this.state.selectedProfiles)
      );
      if (!this.checkIsItemExist(record)) {
        selectedProfiles.push(record);
      } else {
        let index = selectedProfiles.findIndex(profile => profile.id === record.id);
        selectedProfiles.splice(index, 1);
      }
      this.setState({ selectedProfiles: selectedProfiles });
    } else {
      this.toggleModal();
      this.props.handleOkModal(record);
    }
  };

  checkIsItemExist(record) {
    return this.state.selectedProfiles.findIndex(profile => profile.slug === record.slug) > -1;
  }

  handleMultipleSection() {
    this.toggleModal();
    this.props.handleOkModal(this.state.selectedProfiles);
  }

  getProfileCardExtraString(educations, role) {
    if (educations) {
      let classYear = getKeyValue(educations, "0__class_year", "");
      return (`${classYear && `Class of ${classYear}`}${role && ` - ${role}`}`);
    }
    return ''
  }

  render() {
    return (
      <Modal
        title={getKeyValue(this.props, "title", `Select Record ${this.props.many ? "s" : ''}`)}
        visible={this.state.visible}
        onCancel={() => this.toggleModal()}
        footer={null}
        className="profile-selector-modal"
      >
        <Row>
          <Col span={24}>
            <Search
              placeholder="Search by name or email"
              size="large"
              id="profile-search"
              defaultValue={this.state.query}
            />
          </Col>
        </Row>
        <div className="profile-items-list">
          <InfiniteScroll
            initialLoad={false}
            hasMore={this.state.hasMore && !this.state.loading}
            useWindow={false}
            loadMore={this.fetchProfiles}
          >
            <List
              dataSource={this.state.records}
              itemLayout="horizontal"
              loading={this.state.reset && this.state.query ? true : false}
              locale={{
                emptyText: !this.state.loading && (
                  <EmptyDataBlock emptyTitle="No results found" />
                )
              }}
              renderItem={record => (
                <List.Item onClick={() => this.toggleItemSelection(record)}
                  className={this.checkIsItemExist(record) ? "selected" : ""}>
                  <div className="profile-item-media">
                    <ThemeXProfileDetailItem
                      title={getKeyValue(record, "formatted_name", "")}
                      src={getKeyValue(record, "present_picture")}
                      extra={this.getProfileCardExtraString(
                        record.educations,
                        record.role
                      )}
                      isActive={getKeyValue(record, "active")}
                      isRegistered={getKeyValue(record, "is_registered")}
                      isListed={getKeyValue(record, "listed")}
                      shape="circle"
                      size="large"
                    />
                  </div>
                  <Button
                    type="primary"
                    icon={<ExportOutlined />}
                    size="small"
                    onClick={event =>
                      this.openProfile(event, record.profile_url)
                    }
                  >
                    View Profile
                    </Button>
                </List.Item>
              )}
            >
              {this.state.loading &&
                this.state.hasMore && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                )}
            </List>
          </InfiniteScroll>
          <If condition={!this.state.hasMore && !this.state.loading && this.state.records.length > 0}>
            <p className="footer-end-text">No more results to show</p>
          </If>
        </div>
        <If
          condition={this.props.many && this.state.selectedProfiles.length}
        >
          <Row id="profile-modal-footer">
            <Col span={8}>
              <p>
                {this.state.selectedProfiles.length} profile
                {this.state.selectedProfiles.length > 1 && "s"} selected
              </p>
            </Col>
            <Col span={16}>
              <Button
                type="primary"
                onClick={() => this.handleMultipleSection()}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </If>
      </Modal>
    );
  }
}

export default ProfilesSelector;
