import React from 'react';
import { array, string, object, func } from 'prop-types';
import { Card, Video } from 'react-activity-feed';
import { includes } from 'lodash';

const VIDEO_TYPES = ['video.other', 'video'];

function FeedScrappedBlock({
    images,
    type,
    videos,
    title,
    description,
    url,
    handleClose
}) {
    const image = images.length && images[0].image || undefined;
    if (includes(VIDEO_TYPES, type)) {
        return (
            <Video
                og={{
                    videos: videos,
                }}
                handleClose={(e) => handleClose(e)}
            />
        )
    }
    else {
        return (
            <Card
                title={title}
                description={description}
                image={image}
                url={url}
                handleClose={(e) => handleClose(e)}
            />
        )
    }
}

FeedScrappedBlock.propTypes = {
    images: array,
    type: string,
    videos: object,
    title: string,
    description: string,
    url: string,
    handleClose: func.isRequired
}


FeedScrappedBlock.defaultProps = {
    images: [],
    type: null,
    videos: {},
    title: '',
    description: '',
    url: ''
}

export default FeedScrappedBlock;