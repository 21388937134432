import React from 'react';
import { string, func, shape, arrayOf } from 'prop-types';
import { Row, Col, Button } from 'antd';
import { readableNumber } from 'Src/common/utilities/data_util';
import { getBoughtTicketQuantity } from '../../../utils';

import ThemeXModal from '../../../../common/components/themeXModal';

import './style.scss';

function AgendaTicketsModal({ agenda, currencyEntity, selectedTickets, handleCancel, handleTicketSelection }) {
  const { tickets } = agenda;

  return (
    <ThemeXModal
      modalTitle={
        <div>
          <p className="arc-H200 arc-color-B85">There are {tickets.length} ticket options available for</p>
          <p>{`'${agenda.name}'`}</p>
        </div>
      }
      visible
      closable
      width="28rem"
      wrapClassName="agenda-tickets-modal"
      handleCancel={() => handleCancel()}>
      <div>
        {tickets.map((ticket) => {
          const boughtQuantity = getBoughtTicketQuantity(selectedTickets, ticket.id);
          return (
            <Row
              className="agenda-ticket-item"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }}
              key={ticket.id}
              type="flex">
              <Col span={18} className="agenda-ticket-item-detail">
                <If condition={ticket.image}>
                  <img src={ticket.image} className="agenda-ticket-item-image" alt="" />
                </If>
                <div>
                  <p className="arc-H150 arc-color-B85">{ticket.name}</p>
                  <p className="arc-support arc-color-B85">
                    <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                    <span>{readableNumber(ticket.price)}</span>
                  </p>
                </div>
              </Col>
              <Col span={6} className="arc-text-align-r">
                <Button
                  type="primary"
                  className={`${boughtQuantity ? 'ticket-bought-highlight' : 'arc-btn-subtle'} arc-focus-outline`}
                  disabled={!ticket.is_available}
                  onClick={() => handleTicketSelection(ticket.id)}>
                  {boughtQuantity ? 'Edit' : 'Book'}
                </Button>
              </Col>
              <Col className="agenda-ticket-item-detail">
                <p className="agenda-ticket-description arc-support arc-color-B55">{ticket.description}</p>
              </Col>
            </Row>
          );
        })}
      </div>
    </ThemeXModal>
  );
}

AgendaTicketsModal.propTypes = {
  agenda: shape().isRequired,
  currencyEntity: string.isRequired,
  selectedTickets: arrayOf(shape()),
  handleCancel: func.isRequired,
  handleTicketSelection: func.isRequired,
};

AgendaTicketsModal.defaultProps = {
  selectedTickets: [],
};

export default AgendaTicketsModal;
