import React from 'react';
import { object, number, func, bool, string } from 'prop-types';
import { Menu, Dropdown, Row, Col, Avatar, message, Alert } from 'antd';

import { humanizeTimestamp } from './../../../utilities/moment_util';
import instance from './../../../utilities/axios_util';
import { REACTIONS_POST_API_PATH, FEED_POST_API_PATH } from './../../../endpoints';
import { GET_STREAM_REACTION_TYPES, GET_STREAM_REACTION_KIND } from './../../../constants';

import './style.scss';

import { EllipsisOutlined } from '@ant-design/icons';

function ActivityHeader({
    activity,
    moduleName,
    userId,
    objectId,
    isLoggedIn,
    isAdmin,
    hasWritePermission,
    doReactionAddRequest,
    doActivityRemoveRequest,
    showContentObject
}) {

    const { actor, time } = activity;
    const user = actor.data;
    const hasUserFlagged = activity.own_reactions && !!activity.own_reactions.flag;
    const activityTime = humanizeTimestamp(time);

    const activityActionMenu = (
        <Menu>
            <If condition={userId === user.user_id || isAdmin}>
                <Menu.Item key="delete" onClick={() => deletePost()}>
                    Delete
                </Menu.Item>
            </If>
            <If condition={!hasUserFlagged}>
                <Menu.Item key="flag" onClick={() => flagPost()}>
                    Report Abuse
            </Menu.Item>
            </If>
        </Menu>
    )

    function flagPost() {
        let path = REACTIONS_POST_API_PATH.replace('{reaction_on}', GET_STREAM_REACTION_TYPES.POST);
        path = path.replace('{foreign_id}', activity.foreign_id);

        instance.post(
            path,
            { kind: GET_STREAM_REACTION_KIND.FLAG },
            { hideNotification: true }
        ).then(response => {
            message.open({
                content: <Alert
                    message="This content has been reported to the administrators for further action."
                    type="warning"
                    className="feed-message-alert"
                />,
                duration: 1
            });
            doReactionAddRequest(GET_STREAM_REACTION_KIND.FLAG, activity, response.data)
        })
    }

    function deletePost() {
        let path = FEED_POST_API_PATH.replace('{module_name}', moduleName);
        path = path.replace('{object_id}', objectId);
        path = path.concat(activity.foreign_id, '/');
        instance.delete(path, { hideNotification: true }).then(response => {
            doActivityRemoveRequest(activity.id)
        })
    }

    function getContentObject() {
        if (showContentObject) {
            return <span>Posted on  <a href={activity.content_object_url} target="_blank" onClick={(e) => e.stopPropagation()}>{activity.content_object_name}</a></span>
        }
        return null;
    }

    return (
        <Row
            type="flex"
            align="top"
            justify="space-between"
            className="feed-activity-header"
        >
            <Col>
                <div
                    className="feed-activity-user-bar"
                    onClick={() => window.open(user.profile_url, '_blank')}
                >
                    <Avatar src={user.present_picture} />
                    <div className="feed-activity-user-bar-detail">
                        <p
                            className="arc-H150 feed-activity-user-name arc-anchor"
                            tabIndex="0"
                            aria-label={`${user && user.full_name} posted ${activityTime}`}
                            role="heading"
                        >{user && user.full_name}</p>
                        <p className="arc-support arc-color-B55" aria-hidden={true}>{getContentObject()} {activityTime}</p>
                    </div>
                </div>
            </Col>
            <Col>
                <If condition={isLoggedIn && hasWritePermission}>
                    <Dropdown
                        overlay={activityActionMenu}
                        trigger={['click']}
                        placement="bottomRight"
                    >   
                        <EllipsisOutlined rotate={90}/>
                    </Dropdown>
                </If>
            </Col>
        </Row>
    )
}

ActivityHeader.propTypes = {
    userId: number,
    objectId: number.isRequired,
    activity: object.isRequired,
    isLoggedIn: bool.isRequired,
    isAdmin: bool.isRequired,
    hasWritePermission: bool.isRequired,
    moduleName: string.isRequired,
    doReactionAddRequest: func.isRequired,
    doActivityRemoveRequest: func.isRequired,
    showContentObject: bool
}

export default ActivityHeader;