/* eslint-disable complexity */
import React from 'react';
import { connect } from 'react-redux';
import { Button, Tabs, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
// === UTILS AND CONSTANTS === //
import ThemeXContainer from 'Src/common/components/themeXContainer';
import { getFormattedDate } from 'Src/common/utilities/moment_util';
import { getUrlParam } from 'Src/common/utilities/data_util';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
// === APP UTILS AND CONSTANTS === //
import QrCodeGenerator from 'Src/common/components/qrCodeGenerator/index';
import { getCartData } from '../../utils';
import { ALUMNI_BASE_URL, ITINERARY_VIEW_URL } from '../../routes';
// === COMPONENTS === //
import RegistrationTicketSection from '../common/registrationItems/registrationTicketSection';
import RegistrationCartSection from '../common/registrationItems/registrationCartSection';
import RegistrationGiftSection from '../common/registrationItems/registrationGiftSection';
import EventDateBlock from '../common/eventDateBlock';

import './style.scss';

const { TabPane } = Tabs;

function Receipt(props) {
  const authKey = getUrlParam('r') || '';
  const { eventData, registrationData, contextData } = props;
  const eventDetailUrl = ALUMNI_BASE_URL.replace(':eventSlug', eventData.slug);

  if (isEmpty(registrationData) || !registrationData.registration_status) {
    window.open(eventDetailUrl, '_self');
    return false;
  }

  const { currencyEntity } = contextData;
  const { mandatoryTickets, otherTickets } = getCartData(
    eventData.tickets,
    registrationData.tickets,
    registrationData.guests,
  );
  // Only successful transactions
  const transactions = registrationData.transactions.filter((transaction) => transaction.status === 2);

  function getItineraryUrl(guestId) {
    const url = ITINERARY_VIEW_URL.replace(':guestId', guestId);
    return url.replace(':eventSlug', eventData.slug).concat('?r=', authKey);
  }

  return (
    <ThemeXContainer>
      <div id="confirmation-page">
        <div className="confirmation-page-block arc-card-box-shadow">
          <div id="confirmation-page-header">
            <Button
              type="primary"
              className="arc-float-right print-btn"
              icon={<FontAwesomeIcon icon={faPrint} className="mr8" />}
              onClick={() => window.print()}>
              <I18nCustomFormatter id="print-btn-label" />
            </Button>
            <img src={window.customerLogo} alt={`${window.collegeName} logo`} />
            <p className="header-title arc-H200">{eventData.name}</p>
            <p className="arc-p">{getFormattedDate(eventData.start_datetime, 'ddd, MMM D, YYYY, h.mm a')}</p>
          </div>
          <div id="confirmation-event-basic-detail">
            <Row>
              <If condition={eventData.venue && !eventData.venue?.restricted}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="basic-detail-block">
                  <p className="confirmation-page-section-title">
                    <I18nCustomFormatter id="confirmation-section-venue-title" />
                  </p>
                  <p className="arc-p arc-color-B85">{`${eventData.venue.name} - ${eventData.venue.formatted_address}`}</p>
                </Col>
              </If>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="basic-detail-block">
                <p className="confirmation-page-section-title">
                  <I18nCustomFormatter id="confirmation-section-registered guests-title" />
                </p>
                {registrationData.guests.map((guest, index) => (
                  <p key={guest?.id || index} className="arc-p arc-color-B85">{`${index + 1}. ${guest.full_name}`}</p>
                ))}
              </Col>
              <If condition={eventData.start_datetime || eventData.end_datetime}>
                <Col span={24} className="basic-detail-block">
                  <p className="confirmation-page-section-title">
                    <I18nCustomFormatter id="confirmation-section-event-start-at-title" />
                  </p>
                  <EventDateBlock
                    startDateTime={eventData.start_datetime}
                    endDateTime={eventData.end_datetime}
                    timezone={eventData.timezone}
                    shortTimezone={eventData.short_timezone}
                  />
                </Col>
              </If>
            </Row>
          </div>
          <If condition={registrationData.form_entry}>
            <div id="confirmation-event-form">
              <p className="confirmation-page-section-title">
                <I18nCustomFormatter id="confirmation-section-form-title" />
              </p>
              {registrationData.form_entry.field_entries.map((entry) => (
                <div className="confirmation-form-item" key={entry.slug}>
                  <p className="arc-H150 arc-color-B85">{entry.label}</p>
                  <p className="arc-p arc-color-B55">{entry.value || '-'}</p>
                </div>
              ))}
            </div>
          </If>
        </div>
        <If condition={eventData.edit_key && eventData.is_checkin_kiosk_enabled}>
          <div className="confirmation-page-block">
            <QrCodeGenerator
              title="QR Code for Entry"
              supportText="Scan this QR code at the venue for easy entry"
              qrCodeConfig={{
                value: eventData.edit_key,
                size: 170,
              }}
              openedQrCodeConfig={{
                value: eventData.edit_key,
                size: 300,
              }}
            />
          </div>
        </If>
        <If condition={registrationData.tickets.length || transactions.length}>
          <div className="confirmation-page-block arc-card-box-shadow">
            <If condition={registrationData.tickets && registrationData.tickets.length}>
              <p className="confirmation-page-block-title">
                <I18nCustomFormatter id="confirmation-section-tickets-title" />
              </p>
              <If condition={!registrationData.is_receipt_sent && window.isEventsProEnabled}>
                <p className="confirmation-page-block-sub-title">
                  <I18nCustomFormatter id="confirmation-section-tickets-sub-title" />
                </p>
              </If>
              <RegistrationTicketSection
                mandatoryTickets={mandatoryTickets}
                otherTickets={otherTickets}
                guestCount={registrationData.guests.length}
                currencyEntity={currencyEntity}
                showTaxReceipt={registrationData.is_receipt_sent}
              />
              <RegistrationGiftSection gift={registrationData.gift} currencyEntity={currencyEntity} />
              <RegistrationCartSection
                totalBenefits={registrationData.is_receipt_sent && registrationData.total_benefit_amount}
                currencyEntity={currencyEntity}
                cartSubTotal={registrationData.total_amount}
                discountValue={registrationData.total_amount - registrationData.amount_after_discount}
                paidAmount={registrationData.paid_amount}
                payableAmount={registrationData.payable_amount}
                isRefundAlertHidden
              />
            </If>
            <If condition={transactions && transactions.length}>
              <div id="confirmation-transaction-block">
                <p className="confirmation-page-section-title arc-H150">
                  <I18nCustomFormatter id="confirmation-section-transaction-history-title" />
                </p>
                {transactions.map((transaction) => (
                  <div className="transaction-item" key={transaction.id}>
                    <p className="arc-p">{getFormattedDate(transaction.created_at, 'DD MMM, YYYY')}</p>
                    <p className="arc-H150">
                      <span>Paid&nbsp;</span>
                      <span dangerouslySetInnerHTML={{ __html: currencyEntity }} />
                      <span>{transaction.amount}</span>
                      <span>{transaction.mode === 'online' ? ' using card' : ' offline'}</span>
                    </p>
                  </div>
                ))}
              </div>
            </If>
          </div>
        </If>
        <div className="confirmation-page-block arc-card-box-shadow">
          <div id="confirmation-guest-form">
            <p className="confirmation-page-block-title">
              <I18nCustomFormatter id="confirmation-guest-form-title" />
            </p>
            <Tabs>
              {registrationData.guests.map((guest, index) => (
                <TabPane tab={guest.full_name} key={guest?.id || index}>
                  <div className="confirmation-form-item">
                    <p className="arc-H150 arc-color-B85">
                      <I18nCustomFormatter id="confirmation-form-first-name" />
                    </p>
                    <p className="arc-p arc-color-B55">{guest.first_name}</p>
                  </div>
                  <If condition={guest.last_name}>
                    <div className="confirmation-form-item">
                      <p className="arc-H150 arc-color-B85">
                        <I18nCustomFormatter id="confirmation-form-last-name" />
                      </p>
                      <p className="arc-p arc-color-B55">{guest.last_name}</p>
                    </div>
                  </If>
                  <If condition={guest.email}>
                    <div className="confirmation-form-item">
                      <p className="arc-H150 arc-color-B85">
                        <I18nCustomFormatter id="confirmation-form-email" />
                      </p>
                      <p className="arc-p arc-color-B55">{guest.email}</p>
                    </div>
                  </If>
                  <If condition={guest.affiliations}>
                    <div className="confirmation-form-item">
                      <p className="arc-H150 arc-color-B85">
                        <I18nCustomFormatter id="confirmation-form-affiliation" />
                      </p>
                      <p className="arc-p arc-color-B55">
                        {guest.affiliations.map((affiliation) => affiliation.name).join(',')}
                      </p>
                    </div>
                  </If>
                  <If condition={guest.form_entry}>
                    {guest.form_entry.field_entries.map((entry) => (
                      <div className="confirmation-form-item" key={entry.slug}>
                        <p className="arc-H150 arc-color-B85">{entry.label}</p>
                        <p className="arc-p arc-color-B55">{entry.value || '-'}</p>
                      </div>
                    ))}
                  </If>
                  <If condition={eventData.agendas.length}>
                    <div className="confirmation-form-item">
                      <a href={getItineraryUrl(guest.id)} target="_blank" rel="noopener noreferrer">
                        <I18nCustomFormatter id="confirmation-form-item-view-itinerary" />
                      </a>
                    </div>
                  </If>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </ThemeXContainer>
  );
}

const mapStateToProps = (state) => ({
  eventData: state.eventDataReducer.data || {},
  registrationData: state.registrationDataReducer.data || {},
  contextData: state.contextDataReducer || {},
});

export default connect(mapStateToProps, null)(Receipt);
