import React from 'react';
import { string } from 'prop-types';

import './style.scss';

const RegistrationItemSection = ({ title, children }) => {
  return (
    <div className="registration-item-section">
      <h3 className="arc-H100 arc-color-B55 registration-section-title">{title}</h3>
      {children}
    </div>
  );
};

RegistrationItemSection.propTypes = {
  title: string.isRequired,
};

export default RegistrationItemSection;
