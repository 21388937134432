import React from "react";
import { Button, Card } from 'antd';
import { isEmpty } from "lodash";
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter';
import LockedContent from 'Src/common/components/lockedContent';

import './style.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCircle } from "@fortawesome/pro-light-svg-icons";

const ZoomVenueCard = ({ venueData, webinarSettingsData, isContentLocked=false }) => {
    let allNumbers=checkAllValuesIfEmptyOrNot()? webinarSettingsData.response.settings.global_dial_in_numbers : [];

    const getOneTapNumbers=()=>{
        /*
            This Function is used to make the two One tap numbers
            One Tap numbers are of the form:-
            Number without any space,,Webinar Id#
            firstNumber contains the 1st number and the secondNumber contains the 2nd number
        */
        const firstNumber=`${allNumbers[0].number.split(" ").join("")},,${venueData.response.id}#`;
        const secondNumber=`${allNumbers[1].number.split(" ").join("")},,${venueData.response.id}#`;
        return (
            <span>
                <a className="arc-color-B55" href={`tel:${firstNumber}`}>{firstNumber}</a> or <a className="arc-color-B55" href={`tel:${secondNumber}`}>{secondNumber}</a>
            </span>
        );
    }

    const formatNumber=(number)=>{
        /*
            This function is used to format the numbers into 3 3 4 format
        */
        let formatted=`${number.substring(0,6)} ${number.substring(6,9)} ${number.substring(9)}`;
        return formatted;
    }

    const formatWebinarId=(webinarid)=>{
        /*
            This function formats the Webinar id's.As seen from past zoom id's
            it can be seen that the id's are of length 9,10 and 11.
            If id is length 9 then format 3 3 3
            If id is length 10 then format 3 3 4
            If id is length 9 then format 3 4 4
        */
        const id=webinarid.toString();
        switch(id.length){
            case 9: return `${id.substring(0,3)} ${id.substring(3,6)} ${id.substring(6)}`;
            case 10: return `${id.substring(0,3)} ${id.substring(3,6)} ${id.substring(6)}`;
            case 11: return `${id.substring(0,3)} ${id.substring(3,7)} ${id.substring(7)}`;
            default: return id;
        }
    }

    const getDialUpPhoneNumbers=()=>{
        /*
            Extracting all the DialUp numbers and joining them by or
        */
        const numbers= allNumbers.map(
            (nm,i)=>
                <span><a className="arc-color-B55" href={`tel:${formatNumber(nm.number)}`}>{formatNumber(nm.number)}</a>{allNumbers.length - 1 === i ? '' : ' or '}</span>
        )
        return numbers
    }

    function checkAllValuesIfEmptyOrNot() {
        /*
            This function checks all the values for undefined or empty
        */
        return (!isEmpty(webinarSettingsData?.response?.settings?.global_dial_in_numbers) && (webinarSettingsData.response.settings.global_dial_in_numbers.length>1) && !isEmpty(venueData?.response))
    }

    return (
        <Card bordered={false} className="event-card arc-card-border-radius arc-card-box-shadow" id="event-venue-card" title={<p className="arc-H200">
            <span className="ml8 arc-font-weight-medium">
                <FontAwesomeIcon icon={faLocationCircle} className="mr8" />
                <I18nCustomFormatter id="event-zoom-venue-card-title" />
            </span>
        </p>}>
            <Choose>
                <When condition={!isContentLocked && !isEmpty(venueData)}>
                    <div className="zoom-data-wrapper">
                        <Button type="link" className="arc-btn-subtle zoom-connect-link" href={!isEmpty(venueData.response) && venueData.response.join_url} target="_blank">
                            <span>Join meeting via<img className="zoom-logo ml6 mt2" src={`${staticPath}img/events/zoom/zoomlogo.png`}/></span>
                        </Button>                        
                    </div>
                    {(checkAllValuesIfEmptyOrNot()) && (
                        <div className="zoom-data-info arc-p arc-color-B55">
                            <p className="arc-font-weight-bold">Or iPhone one-tap :</p>
                            <p>US: {getOneTapNumbers()}</p>
                            <p className="arc-font-weight-bold mt24" >Or Telephone:</p>
                            <p>Dial(for higher quality, dial a number based on your current location):</p>
                            <p>US: <span>{getDialUpPhoneNumbers()}</span></p>
                            <p className="arc-font-weight-bold mt24">Webinar ID: <span className="arc-font-weight-400">{formatWebinarId(venueData.response.id)}</span></p>
                        </div>
                    )}
                </When>
                <Otherwise>
                    <div className="zoom-data-wrapper">
                        <LockedContent />
                    </div>
                </Otherwise>
            </Choose>

        </Card>
    );
}

export default ZoomVenueCard;
