/* eslint-disable no-void */
import { omit, isEmpty } from 'lodash';
import { getUrlParam } from 'Src/common/utilities/data_util';
import instance from '../../common/utilities/axios_util';
import {
  EVENT_REGISTER_API_PATH,
  DISCOUNT_VALID_API_PATH,
  EVENT_REGISTER_CANCEL_API_PATH,
  REGISTRANT_EMAIL_CONFIRMATION_API_PATH,
  EVENT_GUEST_LIST_API_PATH,
  EVENT_INVITE_API_PATH,
  GIFT_DETAIL_URL,
  GENERATE_PAYMENT_LINK_URL,
  TICKET_ENTRIES_PATH,
} from '../endpoints';

export const checkIsUserAlreadyRegisteredForEvent = (eventId, email) => {
  const endpoint = EVENT_REGISTER_API_PATH.replace('{}', eventId).concat('is_already_registered', '/');
  return instance.post(endpoint, { email });
};

export const validateDiscountCode = (eventId, code) => {
  const endpoint = DISCOUNT_VALID_API_PATH.replace('{}', eventId).concat(code);
  return instance.get(endpoint);
};

// eslint-disable-next-line complexity
export const registerGuest = ({ registrationData, eventId, hashToken, requestMethod, invitationHash }) => {
  let endpoint = EVENT_REGISTER_API_PATH.replace('{}', eventId);
  let method = 'POST';
  let registrationDataCopy = registrationData;
  if (registrationData.id) {
    method = requestMethod || 'PATCH';
    endpoint = endpoint.concat(registrationData.id, '/');
    // Remove matched profile from registration data and make a patch request
    registrationDataCopy = {
      ...registrationDataCopy,
      guests: !isEmpty(registrationData.guests)
        ? registrationData.guests.map((guestData) => omit(guestData, 'matched_profile'))
        : [],
    };
  }

  if (hashToken) {
    instance.defaults.headers.common['VERIFY-TOKEN'] = hashToken;
  }

  // Clearing local storage
  if (Storage !== void 0 && method === 'POST') {
    localStorage.clear();
  }

  const isAdminEditingRegistration = getUrlParam('registered_by_admin');
  if (registrationData.added_by_admin || isAdminEditingRegistration === 'true') {
    endpoint = endpoint.concat('?registered_by_admin=true');
  }

  if (invitationHash && method === 'POST') {
    if (endpoint.includes('?')) {
      endpoint = endpoint.concat(`&i=${invitationHash}`);
    } else {
      endpoint = endpoint.concat(`?i=${invitationHash}`);
    }
  }

  delete registrationData.added_by_admin;

  return instance({
    method,
    url: endpoint,
    data: registrationDataCopy,
    hideNotification: true,
  });
};

export const processPayment = (
  config,
  successCallback,
  failureCallback,
  checkoutCloseCallBack,
  processStartCallBack,
) => {
  if (window.Payment.isPaymentConfigValid(config).isValid) {
    window.Payment.setConfiguration(true);
    window.Payment.handler(config).then((handler) => {
      window.Payment.setCallbacks(successCallback, failureCallback, checkoutCloseCallBack, processStartCallBack);
      window.Payment.makePayment(handler, config);
    });
  }
};

export const cancelRegistration = (eventId, registrationId, hashToken, canceledByAdmin) => {
  let endpoint = EVENT_REGISTER_CANCEL_API_PATH.replace('{}', eventId);
  endpoint = endpoint.replace('{registrationId}', registrationId);
  instance.defaults.headers.common['VERIFY-TOKEN'] = hashToken;
  const isAdminEditingRegistration = getUrlParam('registered_by_admin');
  if (canceledByAdmin || isAdminEditingRegistration === 'true') {
    endpoint = endpoint.concat('?registered_by_admin=true');
  }
  return instance.post(endpoint);
};

export const resendConfirmationEmail = ({
  eventId,
  registrantEmail,
  registrationId = '',
  isCancel = false,
  isEditRegistration = false,
}) => {
  let path = REGISTRANT_EMAIL_CONFIRMATION_API_PATH.replace('{}', eventId);

  if (registrationId && isCancel) {
    path = `${REGISTRANT_EMAIL_CONFIRMATION_API_PATH.replace(
      '{}',
      eventId,
    )}?registration_id=${registrationId}&registration_canceled_by_admin=true`;
  }

  if (isEditRegistration) {
    path = `${REGISTRANT_EMAIL_CONFIRMATION_API_PATH.replace(
      '{}',
      eventId,
    )}?is_edit_registration=${isEditRegistration}`;
  }

  return instance.post(path, { email: registrantEmail });
};

export const getEventGuestList = (eventId, page, searchTerm, orderingValue) => {
  let path = EVENT_GUEST_LIST_API_PATH.replace('{}', eventId);
  path = path.replace('{page}', page);
  path = path.concat('&ordering=', orderingValue);
  if (searchTerm) {
    path = path.concat('&search=', searchTerm);
  }
  return instance.get(path);
};

export const sendInviteEmail = (eventId) => instance.post(EVENT_INVITE_API_PATH.replace('{}', eventId));

export const getGiftSettings = (eventId, giftId) =>
  instance.get(GIFT_DETAIL_URL.replace('<event_id>', eventId).replace('<gift_id>', giftId));

export const generatePaymentLink = (pageId, templateId, email) => {
  const path = GENERATE_PAYMENT_LINK_URL.replace('<page_id>', pageId).replace('<template_id>', templateId);
  return instance.post(path, { email });
};

export const postTicketEntries = (eventId, registrationdId, payload, hashToken) => {
  const path = TICKET_ENTRIES_PATH.replace('<event_id>', eventId).replace('<id>', registrationdId);
  if (hashToken) {
    instance.defaults.headers.common['VERIFY-TOKEN'] = hashToken;
  }
  return instance.patch(path, payload);
};

export const fetchTicketEntries = (eventId, registrationdId) => {
  const path = TICKET_ENTRIES_PATH.replace('<event_id>', eventId).replace('<id>', registrationdId);
  return instance.get(path);
};
