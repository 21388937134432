import React from 'react';
import { Row, Col, Button } from 'antd';
import { func, bool, shape } from 'prop-types';
import I18nCustomFormatter from 'Src/common/components/i18nCustomFormatter/index';

import './style.scss';

function FundItem({ isDonated, fund, handleGiftAddAction }) {
  return (
    <div className="event-gift-item  half-width">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32 }} type="flex" align="middle">
        <Col xs={17} sm={16} md={12} lg={10} xl={10} className="event-gift-item-detail">
          <p className="arc-H150 arc-color-B85">{fund.name}</p>
        </Col>
        <Col xs={7} sm={8} md={12} lg={12} xl={12} className="no-padding">
          <Button
            type="primary"
            className={`${isDonated ? 'gift-donate-highlight' : 'arc-btn-subtle'} arc-focus-outline`}
            onClick={() => handleGiftAddAction()}>
            {isDonated ? (
              <I18nCustomFormatter id="event-edit-gift-label" />
            ) : (
              <I18nCustomFormatter id="event-add-gift-label" />
            )}
          </Button>
        </Col>
        <If condition={fund.description}>
          <Col span={24} className="item-description-block">
            <p className="arc-p arc-color-B45">{fund.description}</p>
          </Col>
        </If>
      </Row>
    </div>
  );
}

FundItem.propTypes = {
  isDonated: bool,
  fund: shape().isRequired,
  handleGiftAddAction: func.isRequired,
};

FundItem.defaultProps = {
  isDonated: false,
};

export default FundItem;
