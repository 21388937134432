// This is modal to modify the registration
import React from 'react';
import { Alert, Row, Col, Popconfirm, Tooltip, message } from 'antd';
import { getUrlParam } from 'Src/common/utilities/data_util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import ThemeXModal from 'Src/common/components/themeXModal';
import { resendConfirmationEmail } from 'Src/alumniEvents/actions';
import { REGISTRATION_STATUSES } from 'Src/alumniEvents/constants';
import NotificationReminderModal from '../notificationReminderModal';

import './style.scss';

function RestrictRegistrationPopup() {
  return (
    <Tooltip title="Your registration cannot be modified since the tax receipt for this event has already been issued">
      <FontAwesomeIcon icon={faInfoCircle} />
    </Tooltip>
  );
}

function EditRegistrationActionModal({
  eventData,
  makePayment,
  isRSVPEvent,
  handleClose,
  registrant,
  editRegistration,
  cancelRegistration,
  registrationStatuses,
  viewRegistrationDetails,
  toggleCancelReminderModal,
  showCancelNotificationModal,
  isAdminEditingGuest,
}) {
  const isRsvpEventEditRegistration =
    isRSVPEvent && registrant.registration_status === REGISTRATION_STATUSES.STATUS_CANCELLED;
  const isUserEditRegistrationRestricted = !isAdminEditingGuest && !eventData.is_edit_registration_open;
  return (
    <ThemeXModal
      visible
      title="Edit Registration"
      Description={<p className="arc-p arc-color-B55">{registrant.email}</p>}
      graphic={`${window.staticPath}img/react-modules/events/edit_reg.jpg`}
      wrapClassName="edit-registration-action-modal"
      handleCancel={() => handleClose()}>
      <div>
        <If condition={getUrlParam('edit_registration') === 'true' || isUserEditRegistrationRestricted}>
          <div className="edit-registration-action-modal-top-alert">
            <If condition={getUrlParam('edit_registration') === 'true'}>
              <Alert
                message="Warning"
                description="This page allows access to your registration. Sharing it with others could lead to undesirable changes to your registration"
                type="warning"
                showIcon
              />
            </If>
            <If condition={isUserEditRegistrationRestricted}>
              <Alert
                description="Please contact the event organiser to make changes or cancel your registration"
                type="warning"
                showIcon
                className="mt8"
              />
            </If>
          </div>
        </If>
        <Row>
          <If condition={!isRSVPEvent || isRsvpEventEditRegistration}>
            <Choose>
              <When condition={registrant.is_receipt_sent && window.isSiteAdmin === false}>
                <Col className="edit-registration-action-modal-list-item cs-dull">
                  <p className="arc-H150">
                    Make Changes <RestrictRegistrationPopup />
                  </p>
                  <p className="arc-p arc-color-B55">Edit guests, tickets, or change your response</p>
                </Col>
              </When>
              <Otherwise>
                <Col
                  className={`edit-registration-action-modal-list-item ${
                    isUserEditRegistrationRestricted ? 'cs-dull' : ''
                  }`}
                  onClick={() => !isUserEditRegistrationRestricted && editRegistration()}>
                  <p className="arc-H150">
                    <Choose>
                      <When condition={isRsvpEventEditRegistration}>Re-register</When>
                      <Otherwise>Make Changes</Otherwise>
                    </Choose>
                  </p>
                  <p className="arc-p arc-color-B55">
                    <Choose>
                      <When condition={isRsvpEventEditRegistration}>Proceed to re-register for the event</When>
                      <When condition={registrant.registration_status === REGISTRATION_STATUSES.STATUS_CANCELLED}>
                        Edit guests, tickets, or modify your previously submitted responses
                      </When>
                      <Otherwise>Edit guests, tickets, or change your response</Otherwise>
                    </Choose>
                  </p>
                </Col>
              </Otherwise>
            </Choose>
          </If>
          <If condition={registrant.registration_status_display !== registrationStatuses.registration_cancelled}>
            <Choose>
              <When condition={registrant.is_receipt_sent && window.isSiteAdmin === false}>
                <Col className="edit-registration-action-modal-list-item cs-dull">
                  <p className="arc-H150">
                    Cancel Registration <RestrictRegistrationPopup />
                  </p>
                  <p className="arc-p arc-color-B55">Cancel your registration and process any refunds if applicable</p>
                </Col>
              </When>
              <Otherwise>
                <Choose>
                  <When condition={isUserEditRegistrationRestricted}>
                    <Col className="edit-registration-action-modal-list-item cs-dull">
                      <p className="arc-H150">Cancel Registration</p>
                      <p className="arc-p arc-color-B55">
                        Cancel your registration and process any refunds if applicable
                      </p>
                    </Col>
                  </When>
                  <Otherwise>
                    <Popconfirm
                      title="Are you sure want to cancel registration?"
                      onConfirm={() => {
                        const isAdminEditingRegistration = getUrlParam('registered_by_admin');
                        if (isAdminEditingRegistration === 'true' && registrant.email && window.isSiteAdmin) {
                          toggleCancelReminderModal(true);
                        } else {
                          cancelRegistration();
                        }
                      }}
                      okText="Yes"
                      cancelText="No">
                      <Col className="edit-registration-action-modal-list-item">
                        <p className="arc-H150">Cancel Registration</p>
                        <p className="arc-p arc-color-B55">
                          Cancel your registration and process any refunds if applicable
                        </p>
                      </Col>
                    </Popconfirm>
                  </Otherwise>
                </Choose>
              </Otherwise>
            </Choose>
          </If>
          <If condition={registrant.registration_status_display === registrationStatuses.registration_successful}>
            <Col className="edit-registration-action-modal-list-item" onClick={() => viewRegistrationDetails()}>
              <p className="arc-H150">View registration details</p>
              <p className="arc-p arc-color-B55">View your guests, tickets and form responses.</p>
            </Col>
          </If>
          <If
            condition={
              (!isUserEditRegistrationRestricted &&
                registrant.registration_status_display === registrationStatuses.pending_payment) ||
              registrant.registration_status_display === registrationStatuses.payment_initiated ||
              registrant.registration_status_display === registrationStatuses.payment_failed
            }>
            <Col
              className={`edit-registration-action-modal-list-item ${
                isUserEditRegistrationRestricted ? 'cs-dull' : ''
              }`}
              onClick={() => !isUserEditRegistrationRestricted && makePayment()}>
              <p className="arc-H150" role="heading" aria-level="2">
                Make a Payment
              </p>
              <p className="arc-p arc-color-B55">Confirm your registration by completing your transaction.</p>
            </Col>
          </If>
        </Row>
      </div>

      <If condition={showCancelNotificationModal}>
        <NotificationReminderModal
          ok={() => {
            cancelRegistration().then(() => {
              resendConfirmationEmail({
                eventId: eventData.id,
                registrantEmail: registrant.email,
                registrationId: registrant.id,
                isCancel: true,
              }).then(() => {
                message.success('Email sent successfully');
                toggleCancelReminderModal(false);
              });
            });
          }}
          cancel={() => {
            cancelRegistration();
            toggleCancelReminderModal(false);
          }}
          email={registrant.email}
        />
      </If>
    </ThemeXModal>
  );
}

export default EditRegistrationActionModal;
