import React from "react";
import "./style.scss";

const EmptyDataBlock = props => {
  return (
    <div className="empty-container">
      <div
        className={
          "empty-graphic-back " +
          (props.emptyGraphicClass && !props.emptySearched
            ? props.emptyGraphicClass
            : "default")
        }
      />
      <p className="empty-title">
        {props.emptyTitle && !props.emptySearched
          ? props.emptyTitle
          : "It's empty"}
      </p>
      <p
        className="empty-text"
        dangerouslySetInnerHTML={{
          __html:
            props.emptyText && !props.emptySearched
              ? props.emptyText
              : "There's nothing to show"
        }}
      />
    </div>
  );
}

export default EmptyDataBlock;
