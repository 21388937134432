import React, { useState } from 'react';
import { object, func, bool } from 'prop-types';
import { Input, Row, Col, Avatar } from 'antd';

import instance from './../../../utilities/axios_util';
import { COMMENT_POST_API_PATH } from './../../../endpoints';
import { GET_STREAM_REACTION_TYPES } from './../../../constants';

import './style.scss';

const MAX_COMMENT_CHARACTERS = 256

function ActivityCommentField({
    user,
    activity,
    isLoggedIn,
    hasWritePermission,
    addFunc,
    handleNonLoggedIn,
    handleNoPermission
}) {

    const [processing, setProcessing] = useState(false);
    const [commentCharacterCount, setCommentCharacterCount] = useState(MAX_COMMENT_CHARACTERS);

    /**
     * Method to post the comment on press enter
     */
    function handleKeyPress(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (!processing) {
                postComment(event.target.value);
            }
        }
    }

    /**
     * Method to update the character count of comment on each key up
     */
    function handleKeyUp(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
        else {
            updateCommentCount(event.target.value);
        }
    }

    /**
     * Method to update the character count of comment when user paste the content,
     * why this is required ? In mobile devices the handle paste event trigger when
     * user will paste the content
     */
    function handlePaste(event) {
        const target = event.target;
        setTimeout(() => {
            updateCommentCount(target.value);
        }, 500);
    }

    function updateCommentCount(comment) {
        const commentValue = comment;
        const commentLength = commentValue ? commentValue.length : 0;
        setCommentCharacterCount(MAX_COMMENT_CHARACTERS - commentLength)
    }

    function postComment(comment) {
        if (comment.length <= MAX_COMMENT_CHARACTERS) {
            if (isLoggedIn && hasWritePermission) {
                setProcessing(true);
                const data = { text: comment }
                let path = COMMENT_POST_API_PATH.replace('{module_name}', GET_STREAM_REACTION_TYPES.POST);
                path = path.replace('{object_id}', activity.foreign_id)
                instance.post(path, data, { hideNotification: true }).then(response => {
                    setProcessing(false);
                    addFunc('comment', activity, response.data);
                }).catch(err => {
                    setProcessing(false);
                })
            }
            else if (!isLoggedIn) {
                handleNonLoggedIn();
            }
            else {
                handleNoPermission();
            }
        }
    }

    return (
        <Row
            type="flex"
            align="top"
            className="feed-comment-wrapper"
        >
            <Col>
                <Avatar src={user.picture} />
            </Col>
            <Col className="feed-comment-field regular-form">
                <Input.TextArea
                    rows={1}
                    autosize={{ minRows: 1 }}
                    placeholder="Write a comment"
                    aria-label="Write a comment"
                    onKeyPress={event => handleKeyPress(event)}
                    onKeyUp={(event) => handleKeyUp(event)}
                    onPaste={(event) => handlePaste(event)}
                    tabIndex="-1"
                />
                <If condition={commentCharacterCount < 100}>
                    <span className="character-limit arc-support">{commentCharacterCount}</span>
                </If>
            </Col>
        </Row>
    )
}

ActivityCommentField.propTypes = {
    user: object,
    activity: object.isRequired,
    isLoggedIn: bool.isRequired,
    hasWritePermission: bool.isRequired,
    addFunc: func.isRequired,
    handleNonLoggedIn: func.isRequired,
    handleNoPermission: func.isRequired
}

ActivityCommentField.defaultProps = {
    user: {}
}

export default ActivityCommentField;