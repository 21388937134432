import React, { useState } from 'react';
import { object, string, bool, func } from 'prop-types';
import { CloseCircleOutlined, LoadingOutlined, StopOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Comment, Avatar, Row, Col, Button, Input, Upload, Spin } from 'antd';
import { includes } from 'lodash';
import instance from './../../../utilities/axios_util';

import { getUrlOpenGraphData, getLinksFromText } from './utils';
import { GET_STREAM_IMAGE_UPLOAD_API } from './../../../endpoints';
import { GET_STREAM_VERBS, FILE_STATUS } from './../../../constants';

import FeedScrappedBlock from './feedScrappedBlock';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-light-svg-icons';

const Dragger = Upload.Dragger;
const MAX_POST_CHARACTERS = 512;
const feedApiKey = window.streamApiKey;
let ogData = [];

function FeedPostForm(props) {
  const { getFieldDecorator } = props.form;
  const feedToken = props.feedToken;
  const [IsPosting, setIsPosting] = useState(false);
  const [isScrapping, setIsScrapping] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [urlsOgData, setUrlsOgData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [postCharacterCount, setPostCharacterCount] = useState(MAX_POST_CHARACTERS);

  const uploadProps = {
    multiple: true,
    accept: '.jpg, .jpeg, .png, .gif',
    fileList: attachments,
    showUploadList: false,
    action: GET_STREAM_IMAGE_UPLOAD_API.replace('{}', feedApiKey),
    headers: { Authorization: feedToken },
    beforeUpload: (file, fileList) => {
      if (props.isLoggedIn) {
        let files = [];
        fileList.map((file) => {
          const item = (({ uid, name, size }) => ({
            uid,
            name,
            size,
            percent: 0,
            status: FILE_STATUS.STARTED,
            dataUrl: readImage(file),
          }))(file);
          files.push(item);
        });
        setAttachments([...attachments, ...files]);
        return true;
      }
      props.handleNonLoggedIn();
      return false;
    },
    onProgress: (step, file) => {
      const index = attachments.findIndex((item) => item.uid === file.uid);
      attachments[index].percent = step.percent;
      attachments[index].status = FILE_STATUS.UPLOADING;
      setAttachments([...attachments]);
    },
    onSuccess: (file, info) => {
      const index = attachments.findIndex((item) => item.uid === info.uid);
      attachments[index].percent = 100;
      attachments[index].url = file.file;
      attachments[index].status = FILE_STATUS.UPLOADED;
      setAttachments([...attachments]);
    },
    onError: (error, response, info) => {
      const index = attachments.findIndex((item) => item.uid === info.uid);
      attachments[index].percent = 100;
      attachments[index].url = null;
      attachments[index].status = FILE_STATUS.FAILED;
      setAttachments([...attachments]);
    },
  };

  /**
   * Method to make a post
   * Post will make either by attachments or text
   */
  function makePost() {
    props.form.validateFields((err, values) => {
      let { post } = values;
      post = (post && post.trim()) || '';
      const files = attachments.filter((item) => item.status === FILE_STATUS.UPLOADED);
      if ((post || files.length) && post.length <= MAX_POST_CHARACTERS) {
        if (props.isLoggedIn) {
          setIsPosting(true);
          const og = urlsOgData.filter((item) => item.url === selectedUrl)[0];
          const data = {
            text: post,
            object: post,
            verb: GET_STREAM_VERBS.POST,
            attachments: {
              images: files.map((item) => item.url),
              files: [],
              og: og ? og.og_data : {},
            },
          };
          instance
            .post(props.feedApiPath, data, { hideNotification: true })
            .then((response) => {
              props.doRefreshFeed();
              setIsPosting(false);
              props.form.resetFields('post');
              setUrlsOgData([]);
              setAttachments([]);
              setPostCharacterCount(MAX_POST_CHARACTERS);
            })
            .catch((err) => {
              setIsPosting(false);
            });
        } else {
          props.handleNonLoggedIn();
        }
      }
    });
  }

  /**
   * Method to handle the keyDown on editor
   * @param {object} event
   * This will prevent to open the antd uploads which is parent of editor
   */
  function handleEditorKeyDown(event) {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  }

  /**
   * Method to update the character count of post on each key up
   */
  function handleKeyUp() {
    const postValue = props.form.getFieldValue('post');
    const postLength = postValue ? postValue.length : 0;
    setPostCharacterCount(MAX_POST_CHARACTERS - postLength);
  }

  /**
   * Method to update the character count of post when user paste the content,
   * why this is required ? In mobile devices the handle paste event trigger when
   * user will paste the content
   */
  function handlePaste() {
    setTimeout(() => {
      const postValue = props.form.getFieldValue('post');
      const postLength = postValue ? postValue.length : 0;
      setPostCharacterCount(MAX_POST_CHARACTERS - postLength);
    }, 500);
  }

  /**
   * Method to handle the post content change
   * @param {string} value
   * This will find the links from text and render Open graph data
   * for each link
   */
  function handleChange(value) {
    const urls = getLinksFromText(value);
    const count = urls.length;
    if (count) {
      let iterator = 0;
      let urlsOgData = [];
      urls.map((url) => {
        const index = ogData.findIndex((item) => item.url === url);
        if (index === -1) {
          setIsScrapping(true);
          getUrlOpenGraphData(url, feedToken, feedApiKey)
            .then((response) => {
              const urlItem = { url, og_data: response.data };
              urlsOgData.push(urlItem);
              ogData.push(urlItem);
              iterator = manageIterator(iterator, count, urlsOgData);
              setIsScrapping(false);
            })
            .catch((err) => {
              setIsScrapping(false);
              iterator = manageIterator(iterator, count, urlsOgData);
            });
        } else {
          urlsOgData.push(ogData[index]);
          iterator = manageIterator(iterator, count, urlsOgData);
        }
      });
    } else {
      setUrlsOgData([]);
    }
  }

  function manageIterator(iterator, count, urlsOgData) {
    iterator += 1;
    if (iterator === count) {
      setUrlsOgData([...urlsOgData]);
    }
    return iterator;
  }

  // TODO: refactor this method
  function getSelectedScrappedUrl() {
    let activeUrl = selectedUrl;
    if (urlsOgData.length) {
      if (activeUrl) {
        const url = urlsOgData.filter((item) => item.url === activeUrl);
        if (url.length) {
          return url;
        } else {
          activeUrl = '';
        }
      }
      if (activeUrl != null) {
        setSelectedUrl(urlsOgData[0].url);
        return [urlsOgData[0]];
      } else {
        return [];
      }
    }
    return urlsOgData;
  }

  function handleClose(e) {
    e.preventDefault();
    setSelectedUrl(null);
  }

  function readImage(file) {
    return URL.createObjectURL(file);
  }

  function removeImage(fileId) {
    const index = attachments.findIndex((attachment) => attachment.uid === fileId);
    if (index > -1) {
      attachments.splice(index, 1);
      setAttachments([...attachments]);
    }
  }

  return (
    <Row className="feed-post-form inline-form">
      <Col span={24} className="feed-post-body">
        <Dragger openFileDialogOnClick={false} {...uploadProps}>
          <Comment
            avatar={<Avatar src={props.user.picture} alt={props.name} icon={<UserOutlined />} aria-hidden={true} />}
            onKeyDown={(event) => handleEditorKeyDown(event)}
            content={
              <Form.Item>
                {getFieldDecorator(
                  'post',
                  {},
                )(
                  <Input.TextArea
                    rows={4}
                    autosize={{ minRows: 4 }}
                    onChange={(event) => handleChange(event.target.value)}
                    onKeyUp={() => handleKeyUp()}
                    onPaste={() => handlePaste()}
                    placeholder="Share an update, ask a question, offer help, or just say hi!"
                    aria-label="Share an update, ask a question, offer help, or just say hi!"
                  />,
                )}
              </Form.Item>
            }
          />
        </Dragger>
        <If condition={postCharacterCount < 100}>
          <span className="character-limit arc-support" tabIndex={0}>
            {postCharacterCount}
          </span>
        </If>
      </Col>
      <If condition={urlsOgData.length || isScrapping}>
        <Col className="feed-post-scrapped">
          <If condition={isScrapping}>
            <div className="feed-scrapping-loader">
              <Spin indicator={<LoadingOutlined spin />} />
              <span className="arc-support arc-color-B20">Loading snippet</span>
            </div>
          </If>
          {getSelectedScrappedUrl().map((item) => {
            return <FeedScrappedBlock {...item.og_data} handleClose={(e) => handleClose(e)} />;
          })}
          <If condition={urlsOgData.length > 1}>
            <Row className="feed-scrapped-tab-wrapper" gutter={16}>
              <p className="arc-p arc-color-B45">Which link would you like to highlight?</p>
              {urlsOgData.map((item) => {
                const itemUrl = item.url;
                return (
                  <Col span={12}>
                    <div
                      className={`feed-scrapped-tab ${itemUrl === selectedUrl ? 'active' : ''}`}
                      onClick={() => setSelectedUrl(itemUrl)}>
                      <p>{item.og_data.title}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </If>
        </Col>
      </If>
      <If condition={attachments.length}>
        <Col className="feed-image-attachments">
          {attachments.map((attachment) => {
            const status = attachment.status;
            return (
              <div className="feed-image-preview-block" key={attachment.uid}>
                <If condition={status === FILE_STATUS.UPLOADED}>
                  <CloseCircleOutlined className="feed-image-remove" onClick={() => removeImage(attachment.uid)} />
                </If>
                <div className="feed-image-thumbnail-wrapper">
                  <div className="feed-image-thumbnail-overlay" />
                  <img src={attachment.dataUrl} />
                </div>
                <If condition={includes([FILE_STATUS.STARTED, FILE_STATUS.UPLOADING], status)}>
                  <Spin indicator={<LoadingOutlined spin />} />
                </If>
                <If condition={status === FILE_STATUS.FAILED}>
                  <StopOutlined className="feed-image-error" />
                </If>
              </div>
            );
          })}
        </Col>
      </If>
      <Col span={24} className="feed-post-footer">
        <Row type="flex" align="middle" justify="space-between">
          <Col className="footer-compose-actions">
            <Upload {...uploadProps}>
              <FontAwesomeIcon icon={faImage} aria-label="Upload images" className="feed-post-footer-icon" />
            </Upload>
          </Col>
          <Col>
            <Button
              className="arc-btn-subtle feed-post-button arc-focus-outline"
              type="primary"
              onClick={() => makePost()}
              loading={IsPosting}
              disabled={attachments.filter((item) => item.percent < 100).length}>
              Post
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

FeedPostForm.propTypes = {
  feedToken: string.isRequired,
  feedApiPath: string.isRequired,
  user: object.isRequired,
  isLoggedIn: bool.isRequired,
  handleNonLoggedIn: func,
};

export default Form.create()(FeedPostForm);
