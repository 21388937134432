import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root';

import '../common/arc.scss';
import './style.scss';

if (document.getElementById('root')) {
  ReactDOM.render(<Root />, document.getElementById('root'));
}
