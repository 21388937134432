export const ALUMNI_BASE_URL = '/e/:eventSlug/';
export const TICKETS_VIEW_URL = `${ALUMNI_BASE_URL}tickets`;
export const GUEST_VIEW_URL = `${ALUMNI_BASE_URL}guests`;
export const BOOKING_FORM_VIEW_URL = `${ALUMNI_BASE_URL}form`;
export const CONFIRMATION_VIEW_URL = `${ALUMNI_BASE_URL}confirmation`;
export const RECEIPT_VIEW_URL = `${ALUMNI_BASE_URL}receipt`;
export const GUEST_KIT_VIEW_URL = `${ALUMNI_BASE_URL}kit`;
export const ITINERARY_VIEW_URL = `${ALUMNI_BASE_URL}itinerary/:guestId`;
export const ADMIN_BASE_URL = '/admin/eventsx/{}/';
export const COMPOSE_EMAIL_URL = '/admin/emails/compose/{}';

export const ROUTES = [
  {
    path: ALUMNI_BASE_URL,
    showFooter: false,
    step: 1,
    requiredFormValidation: false,
  },
  {
    path: TICKETS_VIEW_URL,
    showFooter: true,
    step: 2,
    requiredFormValidation: false,
  },
  {
    path: GUEST_VIEW_URL,
    showFooter: true,
    step: 3,
    requiredFormValidation: true,
  },
  {
    path: BOOKING_FORM_VIEW_URL,
    showFooter: true,
    step: 4,
    requiredFormValidation: true,
  },
  {
    path: CONFIRMATION_VIEW_URL,
    showFooter: false,
    step: 5,
    requiredFormValidation: false,
  },
  {
    path: GUEST_KIT_VIEW_URL,
    showFooter: false,
    step: 6,
    requiredFormValidation: false,
  },
];
